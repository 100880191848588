import { LegFormData } from '@strafos/common'
import { useQuery } from '@tanstack/react-query'
import { api } from '@app/utils/api/api'
import { useSelector } from 'react-redux'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { DisplayTimeTypes } from '@strafos/common'
import { getTimezoneAwareDateFromUTC } from '@app/utils/dateUtils'
import { useActiveUser } from '@app/hooks/useActiveUser'
import { useMemo } from 'react'

interface Props {
  leg: LegFormData
}

const QUERY_KEY = 'aviaPagesCalculations'
const CACHE_STORE_TIME = 30 * 60 * 1000

export function getUniqueLegKey(leg: LegFormData) {
  return `${leg?.aircraft?.id}:${leg?.passengerCount} | ${leg?.departureAirport
    ?.icao_code} - ${leg?.arrivalAirport
    ?.icao_code} | ${leg?.departureDate?.toISOString()}`
}

export const useAviaCalculations = ({ leg }: Props) => {
  const operator = useSelector(selectSelectedOperator)

  const user = useActiveUser()

  const departureDate = useMemo(() => {
    let date = leg.departureDate
    if (
      user?.display_time_type === DisplayTimeTypes.UTC &&
      leg.departureAirport?.timezone
    ) {
      date = getTimezoneAwareDateFromUTC(
        leg.departureDate ?? new Date(),
        leg.departureAirport?.timezone,
        DisplayTimeTypes.AirportLocalTime,
      )
    }
    return date
  }, [user, leg.departureDate, leg.departureAirport])

  const { data, isLoading, error } = useQuery(
    [
      QUERY_KEY,
      leg.departureAirport?.icao_code,
      leg.arrivalAirport?.icao_code,
      departureDate,
      operator?.id,
      leg?.id,
      leg?.aircraft?.id,
      leg?.aircraft?.registration_code,
      leg?.passengerCount,
      getUniqueLegKey(leg),
    ],
    async () =>
      await api.generateAviaPageCalculation({
        leg: {
          id: parseInt(leg.id ?? '0'),
          departureAirport: leg.departureAirport?.icao_code ?? '',
          arrivalAirport: leg.arrivalAirport?.icao_code ?? '',
          passengerCount: leg.passengerCount,
          aircraft: {
            manufacturer: leg.aircraft?.manufacturer ?? '',
            type: leg.aircraft?.type ?? '',
            id: leg.aircraft?.id ?? 0,
          },
          departureDate: departureDate!,
        },
      }),
    {
      cacheTime: CACHE_STORE_TIME,
      refetchOnWindowFocus: false,
    },
  )

  const aviaResponse = data?.data

  return { aviaResponse, isLoading, error }
}
