import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Dialog from "@app/components/atoms/Dialog/Dialog";
import Typography from "@app/components/atoms/Typography/Typography";
import Tabs, { Tab, TabPanel } from "@app/components/atoms/Tabs/Tabs";

import CreateRequestTab, {
  CreateRequestTabProps,
} from "@app/components/organisms/CreateRequestDialog/CreateRequestTab/CreateRequestTab";

import { postCreateOffersFromComputation } from "@app/store/pages/requests/createRequest/createRequest.actions";
import { PartialRequestDto } from "@strafos/common";
import { CreateRequestFormVariant } from "@app/components/organisms/CreateRequestForm/CreateRequestForm";
import { getIsRoundtripFlight } from "@app/utils/requestUtils";

interface CreateRequestDialogProps {
  open: boolean;
  onClose: () => void;
  request?: PartialRequestDto;
}

const CreateRequestDialog = ({
  open,
  onClose,
  request,
}: CreateRequestDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);

  const onCreateOffers: CreateRequestTabProps["onCreateOffers"] = (
    selectedAircraftIds,
  ) => {
    dispatch(
      postCreateOffersFromComputation({
        aircraft_ids: selectedAircraftIds,
        request_id: request?.id,
      }),
    );

    onClose();
  };

  useEffect(() => {
    if (request && getIsRoundtripFlight(request)) {
      setTabIndex(1);
    }
  }, []);

  return (
    <StyledDialog
      classes={{ paper: "Dialog__paper" }}
      open={open}
      onClose={onClose}
      disableEnforceFocus
    >
      <HeaderContainer>
        <Typography variant="dialogHeading">
          {t("organisms.CreateRequestDialog.heading")}
        </Typography>
        <Tabs
          value={tabIndex}
          onChange={(event, tabIndex) => setTabIndex(tabIndex)}
        >
          <StyledTab
            label={t("organisms.CreateRequestDialog.singleOrMultiLeg")}
            data-testid="CreateRequestDialog__single-or-multi-leg-tab"
          />
          <StyledTab label={t("organisms.CreateRequestDialog.roundTrip")} />
        </Tabs>
      </HeaderContainer>
      <Content>
        <StyledTabPanel value={tabIndex} index={0}>
          <CreateRequestTab
            onCreateOffers={onCreateOffers}
            request={request}
            variant={CreateRequestFormVariant.OneWayTrip}
          />
        </StyledTabPanel>
        <StyledTabPanel value={tabIndex} index={1}>
          <CreateRequestTab
            onCreateOffers={onCreateOffers}
            request={request}
            variant={CreateRequestFormVariant.RoundTrip}
          />
        </StyledTabPanel>
      </Content>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  .Dialog__paper {
    padding: 0;
    min-width: 50rem;
    min-height: 30rem;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  margin-bottom: 1rem;
  padding: 2rem 2rem 0 2rem;
`;

const StyledTab = styled(Tab)`
  &.Tab__selected {
    background: ${({ theme }) => theme.palette.common.white};
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 2rem;
`;

export default CreateRequestDialog;
