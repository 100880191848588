import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Person } from '@material-ui/icons'
import {
  AircraftDetailDto,
  CalendarNoteDto,
  ScheduleDetailDto,
} from '@strafos/common'

import { api } from '@app/utils/api/api'
import {
  DisplayAirportIdentifications,
  DisplayTimeTypes,
  LegTypes,
  ScheduleSource,
} from '@strafos/common'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '@app/store/core/userInfo/userInfo.selectors'
import CalendarNote from '@app/components/molecules/ScheduleCalendar/CalendarNote'
import { useNavigate } from '@reach/router'
import { ScheduleCalendarFiltersProps } from '@app/components/molecules/ScheduleCalendar/ScheduleCalendarFilters'

interface ScheduleNoteItem {
  schedule: ScheduleDetailDto[]
  notes: CalendarNoteDto[]
}

interface ScheduleCalendarProps {
  filters: ScheduleCalendarFiltersProps
}

const ScheduleCalendar = ({ filters }: ScheduleCalendarProps) => {
  const userInfo = useSelector(selectUserInfo)
  const navigate = useNavigate()

  const [allAaircraftList, setAllAircraftList] = useState<AircraftDetailDto[]>(
    [],
  )
  const [visibleAircraftList, setVisibleAircraftList] = useState<
    AircraftDetailDto[]
  >([])
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)
  const [dateList, setDateList] = useState<Date[]>([])
  const [scheduleNoteMatrix, setScheduleNoteMatrix] = useState<{
    [key: string]: ScheduleNoteItem
  }>({})

  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const constructKey = (date: Date, aircraftId?: number) =>
    `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}-${
      aircraftId ?? ''
    }`

  const mergeSchedule = (
    schedule: ScheduleDetailDto[],
    reservations: ScheduleDetailDto[],
  ) => {
    const updatedReservations = reservations.map((reservation) => ({
      ...reservation,
      reserved: true,
    }))

    const mergedSchedule = [...schedule, ...updatedReservations]

    mergedSchedule.sort((a, b) => {
      const dateA = new Date(a.departure_date)
      const dateB = new Date(b.departure_date)

      return dateB.getTime() - dateA.getTime()
    })

    return mergedSchedule
  }

  const fetchScheduleAndNotes = async (
    dateFrom: Date,
    dateTo: Date,
    initialLoad = false,
  ) => {
    if (!allAaircraftList.length) {
      return
    }

    const [notesResponse, scheduleResponse, reservationsResponse] =
      await Promise.all([
        api.listCalendarNotes({
          dateFrom: dateFrom,
          dateTo,
        }),
        api.getScheduleList({
          limit: 300,
          departure_date_start: dateFrom,
          departure_date_end: dateTo,
          types: [LegTypes.Occupied, LegTypes.Outage],
          sources: [ScheduleSource.STRAFOS],
        }),
        api.getReservedLegs({
          departure_date_start: dateFrom,
          departure_date_end: dateTo,
          type: LegTypes.Occupied,
          page: 1,
          limit: 1000,
          orderDirection: 'DESC',
          orderBy: 'departure_date',
          display_time_type:
            userInfo?.display_time_type ?? DisplayTimeTypes.UTC,
        }),
      ])

    const mergedSchedule = mergeSchedule(
      scheduleResponse.data.schedule,
      reservationsResponse.data.schedule,
    )

    setScheduleNoteMatrix((prevMatrix) => {
      const noteMatrix = initialLoad ? {} : { ...prevMatrix }
      for (const note of notesResponse.data) {
        note.date = new Date(note.date)
        if (note.aircraftId) {
          const aircraft = allAaircraftList.find(
            (curAircraft) => curAircraft.id === note.aircraftId,
          )
          if (!noteMatrix[constructKey(note.date, aircraft?.id)]) {
            noteMatrix[constructKey(note.date, aircraft?.id)] = {
              schedule: [],
              notes: [note],
            }
          } else {
            noteMatrix[constructKey(note.date, aircraft?.id)].notes.push(note)
          }
        } else {
          if (!noteMatrix[constructKey(note.date)]) {
            noteMatrix[constructKey(note.date)] = { schedule: [], notes: [] }
          }
          noteMatrix[constructKey(note.date)].notes.push(note)
        }
      }

      for (const schedule of mergedSchedule) {
        const aircraft = allAaircraftList.find(
          (curAircraft) => curAircraft.id === schedule.aircraft_id,
        )
        const departureDate = new Date(schedule.departure_date)
        departureDate.setHours(0, 0, 0, 0)
        const arrivalDate = new Date(schedule.arrival_date)
        for (
          let currentDate = new Date(departureDate);
          currentDate.getTime() < arrivalDate.getTime();
          currentDate.setDate(currentDate.getDate() + 1)
        ) {
          if (!noteMatrix[constructKey(currentDate, aircraft?.id)]) {
            noteMatrix[constructKey(currentDate, aircraft?.id)] = {
              schedule: [schedule],
              notes: [],
            }
          } else {
            noteMatrix[
              constructKey(currentDate, aircraft?.id)
            ].schedule.unshift(schedule)
          }

          if (schedule.type !== LegTypes.Outage) {
            break
          }
        }
      }
      return noteMatrix
    })
  }

  useEffect(() => {
    if (filters.aircraftList.length) {
      setAllAircraftList(filters.aircraftList)
      setVisibleAircraftList(filters.selectedAircraftList)
      setSelectedDate(filters.date!)
    }
  }, [filters])

  useEffect(() => {
    if (selectedDate) {
      loadInitialCalendarDates(selectedDate)
    }
  }, [selectedDate])

  const loadMoreDates = async (direction: 'up' | 'down') => {
    const currentDates = [...dateList]
    const newDates = []
    const daysToLoad = 14 // Number of days to load at once

    if (direction === 'up') {
      const firstDate = new Date(currentDates[0])
      for (let i = 1; i <= daysToLoad; i++) {
        newDates.unshift(new Date(firstDate.setDate(firstDate.getDate() - 1)))
      }
      setDateList([...newDates, ...currentDates])

      await fetchScheduleAndNotes(newDates[0], newDates[newDates.length - 1])
      const container = scrollContainerRef.current
      if (container) {
        container.scrollTop = container.clientHeight
      }
    } else if (direction === 'down') {
      const lastDate = new Date(currentDates[currentDates.length - 1])
      for (let i = 1; i <= daysToLoad; i++) {
        newDates.push(new Date(lastDate.setDate(lastDate.getDate() + 1)))
      }
      setDateList([...currentDates, ...newDates])

      await fetchScheduleAndNotes(newDates[0], newDates[newDates.length - 1])
    }
  }

  const loadInitialCalendarDates = (firstDate: Date) => {
    const newDates = []
    const daysToLoad = 14

    let iterationDate = new Date(firstDate)
    for (let i = 1; i <= daysToLoad; i++) {
      newDates.unshift(
        new Date(iterationDate.setDate(iterationDate.getDate() - 1)),
      )
    }

    iterationDate = new Date(firstDate)
    newDates.push(new Date(iterationDate))
    for (let i = 1; i <= daysToLoad; i++) {
      newDates.push(
        new Date(iterationDate.setDate(iterationDate.getDate() + 1)),
      )
    }
    setDateList(newDates)

    const container = scrollContainerRef.current
    if (container) {
      setTimeout(() => {
        container.scrollTop =
          container.scrollHeight / 2 - container.clientHeight / 2
      }, 0)
    }

    fetchScheduleAndNotes(newDates[0], newDates[newDates.length - 1], true)
  }

  const getStyledRowById = (id: string): HTMLElement | null => {
    return document.getElementById(id)
  }

  const handleScroll = async () => {
    const container = scrollContainerRef.current
    if (!container) return

    if (container.scrollTop === 0) {
      const firstRowId = `row-${constructKey(dateList[0])}`

      await loadMoreDates('up')

      requestAnimationFrame(() => {
        const updatedFirstRow = getStyledRowById(firstRowId)
        updatedFirstRow?.scrollIntoView({ behavior: 'auto', block: 'start' })
      })
    } else if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      await loadMoreDates('down')
    }
  }

  useEffect(() => {
    const container = scrollContainerRef.current
    if (!container) return
    container.addEventListener('scroll', handleScroll)
    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [dateList])

  const handleSaveNote = async (
    text: string,
    date: Date,
    aircraft?: AircraftDetailDto,
    note?: CalendarNoteDto,
  ) => {
    const noteKey = aircraft
      ? `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}-${
          aircraft.id
        }`
      : `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    const noteMatrixNew = { ...scheduleNoteMatrix }

    if (!text || text.length === 0) {
      if (note) {
        await api.deleteCalendarNote(note.id)
        noteMatrixNew[noteKey] = {
          ...noteMatrixNew[noteKey],
          notes: noteMatrixNew[noteKey].notes.filter(
            (curNote) => curNote.id !== note.id,
          ),
        }

        setScheduleNoteMatrix(noteMatrixNew)
      }
    } else {
      if (note) {
        await api.updateCalendarNote(note.id, {
          text,
        })

        noteMatrixNew[noteKey] = {
          ...noteMatrixNew[noteKey],
          notes: noteMatrixNew[noteKey].notes.map((curNote) =>
            curNote.id === note.id ? { ...curNote, text } : curNote,
          ),
        }
        setScheduleNoteMatrix(noteMatrixNew)
      } else {
        const newNote = await api.createCalendarNote({
          date,
          aircraftId: aircraft?.id,
          text,
        })

        if (!noteMatrixNew[noteKey]) {
          noteMatrixNew[noteKey] = { schedule: [], notes: [] }
        }

        noteMatrixNew[noteKey].notes.push(newNote.data)
      }

      setScheduleNoteMatrix(noteMatrixNew)
    }
  }

  const handleScheduleClick = async (scheduleItem: ScheduleDetailDto) => {
    if (scheduleItem.offer_id) {
      const offer = await api.getOffer(scheduleItem.offer_id)
      navigate(`/request/${offer.data.request_id}`)
    }
  }

  const getFromToAirportText = (scheduleItem: ScheduleDetailDto) =>
    userInfo?.display_airport_identification ===
    DisplayAirportIdentifications.IATA
      ? `${scheduleItem.departure_airport.iata_code}-${scheduleItem.arrival_airport.iata_code}`
      : `${scheduleItem.departure_airport.icao_code}-${scheduleItem.arrival_airport.icao_code}`

  const getAirportText = (scheduleItem: ScheduleDetailDto) =>
    userInfo?.display_airport_identification ===
    DisplayAirportIdentifications.IATA
      ? `${scheduleItem.departure_airport.iata_code}`
      : `${scheduleItem.departure_airport.icao_code}`

  const getNote = (matrixKey: string, isGeneric = false) => {
    if (
      scheduleNoteMatrix[matrixKey]?.notes &&
      scheduleNoteMatrix[matrixKey]?.notes.length
    ) {
      return scheduleNoteMatrix[matrixKey]?.notes.find(
        (curNote) => curNote.isGeneric === isGeneric,
      )
    }
  }

  const isTodayDate = (date: Date) => {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  return (
    <div
      ref={scrollContainerRef}
      style={{ height: '80vh', width: '100%', overflowY: 'auto' }}
    >
      <StyledGridContainer>
        <StyledRowTop>
          <StyledHeaderCellCorner></StyledHeaderCellCorner>{' '}
          {visibleAircraftList.map((aircraft) => (
            <StyledHeaderCellCol key={aircraft.id}>
              {aircraft.registration_code}
            </StyledHeaderCellCol>
          ))}
        </StyledRowTop>

        {dateList.map((date) => {
          const genericNote = getNote(constructKey(date), true)
          const userDayNote = getNote(constructKey(date))
          const isWeekend = date.getDay() === 0 || date.getDay() === 6
          const isToday = isTodayDate(date)

          return (
            <StyledRow
              key={`row-${constructKey(date)}`}
              id={`row-${constructKey(date)}`}
            >
              <StyledHeaderCellRow isToday={isToday}>
                {`${date.getDate()}.${date.getMonth() + 1}.`}
                <NoteContainer>
                  <CalendarNote
                    isEditable={false}
                    date={date}
                    noteObj={genericNote}
                    onSave={handleSaveNote}
                  ></CalendarNote>
                </NoteContainer>
                <NoteContainer>
                  <CalendarNote
                    isEditable={true}
                    date={date}
                    noteObj={userDayNote}
                    onSave={handleSaveNote}
                  ></CalendarNote>
                </NoteContainer>
              </StyledHeaderCellRow>
              {visibleAircraftList.map((curAircraft) => {
                const note = getNote(constructKey(date, curAircraft.id))
                const dayScheduleList =
                  scheduleNoteMatrix[constructKey(date, curAircraft.id)]
                    ?.schedule || []
                return (
                  <StyledCell
                    key={constructKey(date, curAircraft.id)}
                    isWeekend={isWeekend}
                    isToday={isToday}
                  >
                    <StyledStripesContainer>
                      {dayScheduleList.map((curSchedule) => {
                        const departureDate = new Date(
                          curSchedule.departure_date,
                        )
                        const isOutage = curSchedule.type === LegTypes.Outage
                        const isFirstDay =
                          date.getFullYear() === departureDate.getFullYear() &&
                          date.getMonth() === departureDate.getMonth() &&
                          date.getDate() === departureDate.getDate()

                        return (
                          <StyledStripe
                            reserved={curSchedule.reserved}
                            type={curSchedule.type}
                            key={curSchedule.id}
                            onClick={() => handleScheduleClick(curSchedule)}
                          >
                            <StyledStripeRowTop>
                              {isFirstDay && (
                                <Time>{`${new Date(curSchedule.departure_date)
                                  .getHours()
                                  .toString()
                                  .padStart(2, '0')}:${new Date(
                                  curSchedule.departure_date,
                                )
                                  .getMinutes()
                                  .toString()
                                  .padStart(2, '0')}`}</Time>
                              )}
                              <Details
                                isFirstOutageDay={isFirstDay && isOutage}
                              >
                                {isOutage
                                  ? getAirportText(curSchedule)
                                  : getFromToAirportText(curSchedule)}
                              </Details>
                              {!isOutage && (
                                <PAX>
                                  {curSchedule.passenger_count}
                                  <StyledPAXIcon></StyledPAXIcon>
                                </PAX>
                              )}
                            </StyledStripeRowTop>
                            <StyledStripeRow>
                              <Details>
                                {isOutage
                                  ? curSchedule.label || ''
                                  : curSchedule.offer?.request
                                      ?.contact_person_name || ''}
                              </Details>
                            </StyledStripeRow>
                          </StyledStripe>
                        )
                      })}
                      <NoteContainer>
                        <CalendarNote
                          isEditable={true}
                          date={date}
                          aircraft={curAircraft}
                          noteObj={note}
                          onSave={handleSaveNote}
                        ></CalendarNote>
                      </NoteContainer>
                    </StyledStripesContainer>
                  </StyledCell>
                )
              })}
            </StyledRow>
          )
        })}
      </StyledGridContainer>
    </div>
  )
}

const StyledGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20px;
  border-collapse: collapse;
`

const StyledRow = styled.div`
  display: flex;
`

const StyledRowTop = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
`

const StyledCell = styled.div<{ isWeekend?: boolean; isToday?: boolean }>`
  border: 1px solid #ccc;
  padding: 2px;
  text-align: center;
  flex: 1;
  box-sizing: border-box;
  min-width: 150px;
  max-width: 150px;
  min-height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(props) =>
    props.isToday
      ? 'rgba(231, 102, 41, 0.25)'
      : props.isWeekend
        ? '#f0f0f0'
        : 'transparent'};
`

const StyledHeaderCellCorner = styled(StyledCell)`
  min-height: 40px;
  width: 150px;
  min-width: 150px;
  font-weight: bold;
  background-color: #f0f0f0;
`

const StyledHeaderCellCol = styled(StyledCell)`
  min-height: 40px;
  font-weight: bold;
  align-items: center;
  background-color: #f0f0f0;
`

const StyledHeaderCellRow = styled(StyledCell)`
  font-weight: bold;
  align-items: center;
  background-color: ${(props) =>
    props.isToday ? 'rgba(231, 102, 41, 0.25)' : '#f0f0f0'};
`

const StyledStripesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  flex-grow: 1;
`

const StyledStripe = styled.div<{ reserved?: boolean; type: LegTypes }>`
  background-color: ${(props) =>
    props.reserved
      ? 'rgb(138, 62, 255)'
      : props.type === LegTypes.Outage
        ? 'grey'
        : 'green'};
  color: white;
  text-align: center;
  font-size: 0.7rem;
  border-radius: 3px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  width: 100%;
  cursor: pointer;
`

const StyledStripeRow = styled.div`
  color: white;
  text-align: center;
  font-size: 0.7rem;
  display: flex;
  flex-grow: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  cursor: pointer;
`

const StyledStripeRowTop = styled(StyledStripeRow)`
  width: 100%;
`

const Time = styled.div`
  width: 35px;
  text-align: left;
`

const Details = styled.div<{ isFirstOutageDay?: boolean }>`
  flex-grow: 1;
  text-align: center;
  margin-right: ${(props) => (props.isFirstOutageDay ? '25px' : '0')};
`

const PAX = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  gap: 2px;
`

const StyledPAXIcon = styled(Person)`
  font-size: 0.7rem;
`

const NoteContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 2px;
  padding: 2px;
  border-radius: 5px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: auto;
`

export default ScheduleCalendar
