import React, { ChangeEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FieldError } from "react-hook-form";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import { AirportDetailDto } from "@strafos/common";
import { theme } from "@app/theme";
import { CreateRequestFormVariant } from "@app/components/organisms/CreateRequestForm/CreateRequestForm";

import TextField from "@app/components/atoms/TextField/TextField";
import Tooltip from "@app/components/atoms/Tooltip/Tooltip";
import SingleDatePicker from "@app/components/atoms/SingleDatePicker/SingleDatePicker";
import TimePicker from "@app/components/atoms/TimePicker/TimePicker";

import DateTypeSwitch, {
  DateTypes,
} from "@app/components/atoms/DateTypeSwitch/DateTypeSwitch";

import AirportPicker, {
  AirportPickerVariants,
} from "@app/components/atoms/AirportPicker/AirportPicker";
import { Value } from "@material-ui/lab/useAutocomplete";

interface FieldProps<ValueType = string | number | object> {
  name?: string;
  value: ValueType;
  onChange?: (nextValue: ValueType) => void;
  onBlur?: () => void;
}

export interface LegFieldGroupProps {
  index: number;
  onDeleteClick: () => void;
  isDeleteButtonDisabled?: boolean;
  errors: { [key: string]: FieldError };
  variant: CreateRequestFormVariant;
  fields: {
    departureAirport: FieldProps<
      Omit<AirportDetailDto, "extras"> | null | undefined
    >;
    arrivalAirport: FieldProps<
      Omit<AirportDetailDto, "extras"> | null | undefined
    >;
    date: FieldProps<Date | null>;
    time: FieldProps<Date | null>;
    dateType: FieldProps<DateTypes>;
    returnDate: FieldProps<Date | null>;
    returnTime: FieldProps<Date | null>;
    returnDateType: FieldProps<DateTypes | null>;
    passengerCount: FieldProps<number>;
  };
}

const LegFieldGroup = (props: LegFieldGroupProps): JSX.Element => {
  const {
    fields: {
      departureAirport,
      arrivalAirport,
      date,
      time,
      dateType,
      returnDate,
      returnTime,
      returnDateType,
      passengerCount,
    },
    errors,
    onDeleteClick,
    variant,
  } = props;

  const { t } = useTranslation();

  // @todo Simplify AirportPicker onChange parameters
  const handleAirportChange = (
    value: Value<Omit<AirportDetailDto, "extras">, false, false, true>,
    callback?: (airport: Omit<AirportDetailDto, "extras"> | null) => void,
  ) => {
    if (typeof value === "string") {
      return;
    }

    callback?.(value);
  };

  const handlePassengerCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextPassengerCount =
      event.target.value === "" ? NaN : Number(event.target.value);

    passengerCount.onChange?.(nextPassengerCount);
  };

  return (
    <Container>
      <FieldWithError error={errors.departureAirport}>
        <StyledAirportPicker
          label={t(
            "organisms.CreateRequestForm.LegFieldGroup.departureAirport",
          )}
          variant={AirportPickerVariants.Condensed}
          value={departureAirport.value ?? null}
          onChange={(event, value) =>
            handleAirportChange(value, departureAirport.onChange)
          }
          onBlur={departureAirport.onBlur}
          error={!!errors.departureAirport}
          inputProps={{
            "data-testid": `CreateRequestForm-LegFieldGroup[${props.index}]__departure-airport-input`,
          }}
        />
      </FieldWithError>
      <FieldWithError error={errors.arrivalAirport}>
        <StyledAirportPicker
          label={t("organisms.CreateRequestForm.LegFieldGroup.arrivalAirport")}
          variant={AirportPickerVariants.Condensed}
          value={arrivalAirport.value ?? null}
          onChange={(event, value) =>
            handleAirportChange(value, arrivalAirport.onChange)
          }
          onBlur={arrivalAirport.onBlur}
          error={!!errors.arrivalAirport}
          inputProps={{
            "data-testid": `CreateRequestForm-LegFieldGroup[${props.index}]__arrival-airport-input`,
          }}
        />
      </FieldWithError>
      <div>
        <DateTypeSwitchContainer>
          <DateTypeSwitch value={dateType.value} onChange={dateType.onChange} />
        </DateTypeSwitchContainer>
        <DateAndTimeFields>
          <FieldWithError error={errors.date}>
            <StyledDatePicker
              size="normal"
              TextFieldProps={{
                name: date.name,
                error: !!errors.date,
                inputProps: {
                  "data-testid": `CreateRequestForm-LegFieldGroup[${props.index}]__date-input`,
                },
              }}
              value={date.value}
              onChange={date.onChange}
              disablePast={false}
            />
          </FieldWithError>
          <FieldWithError error={errors.time}>
            <StyledTimePicker
              name={String(time.name)}
              value={time.value}
              onChange={time.onChange}
              onBlur={time.onBlur}
              error={!!errors.time}
              data-testid={`CreateRequestForm-LegFieldGroup[${props.index}]__time-input`}
            />
          </FieldWithError>
        </DateAndTimeFields>
      </div>
      {variant === CreateRequestFormVariant.RoundTrip && (
        <div>
          <DateTypeSwitchContainer>
            <DateTypeSwitch
              value={returnDateType.value}
              onChange={returnDateType.onChange}
              isReturnDate
            />
          </DateTypeSwitchContainer>
          <DateAndTimeFields>
            <FieldWithError error={errors.returnDate}>
              <StyledDatePicker
                size="normal"
                TextFieldProps={{
                  name: returnDate.name,
                  error: !!errors.returnDate,
                }}
                value={returnDate.value}
                onChange={returnDate.onChange}
                disablePast={false}
              />
            </FieldWithError>
            <FieldWithError error={errors.returnTime}>
              <StyledTimePicker
                name={String(returnTime.name)}
                value={returnTime.value}
                onChange={returnTime.onChange}
                onBlur={returnTime.onBlur}
                error={!!errors.returnTime}
              />
            </FieldWithError>
          </DateAndTimeFields>
        </div>
      )}
      <FieldWithError error={errors.passengerCount}>
        <PaxField
          label={t(
            "organisms.CreateRequestForm.LegFieldGroup.passengerCountLabel",
          )}
          type="number"
          name={String(passengerCount.name)}
          value={passengerCount.value}
          onChange={handlePassengerCountChange}
          onBlur={passengerCount.onBlur}
          error={!!errors.passengerCount}
          inputProps={{
            min: 0,
            "data-testid": `CreateRequestForm-LegFieldGroup[${props.index}]__passenger-count-input`,
          }}
        />
      </FieldWithError>
      {variant === CreateRequestFormVariant.OneWayTrip && (
        <IconButton
          disabled={props.isDeleteButtonDisabled}
          onClick={onDeleteClick}
          data-testid={`CreateRequestForm-LegFieldGroup[${props.index}]__delete-button`}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Container>
  );
};

const FieldWithError = ({
  error,
  children,
}: {
  error: FieldError;
  children: ReactElement;
}): JSX.Element => {
  if (!error?.message) {
    return children;
  }

  return (
    <Tooltip backgroundColor={theme.colors.red} title={error.message}>
      <div>{children}</div>
    </Tooltip>
  );
};

const Container = styled.div`
  display: flex;
  padding: 1rem 0;
  align-items: flex-end;
`;

const StyledAirportPicker = styled(AirportPicker)`
  padding: 2px 4px;
  width: 10rem;
  box-sizing: border-box;

  // @todo Use custom class name
  &.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
  }
` as typeof AirportPicker;

const StyledTimePicker = styled(TimePicker)`
  padding: 2px 4px;
  width: 8rem;
`;

const PaxField = styled(TextField)`
  padding: 2px 6px;
  width: 8rem;
`;

const DateTypeSwitchContainer = styled.label`
  padding: 0 4px;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

const DateAndTimeFields = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledDatePicker = styled(SingleDatePicker)`
  padding: 2px 6px;
  width: 9rem;
`;

export default LegFieldGroup;
