import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { produce } from "immer";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";

import Button from "@app/components/atoms/Button/Button";
import Typography from "@app/components/atoms/Typography/Typography";

import TextField, {
  TextFieldPriceFormatter,
} from "@app/components/atoms/TextField/TextField";

import AircraftPicker, {
  AircraftPickerVariants,
} from "@app/components/atoms/AircraftPicker/AircraftPicker";

import { AircraftDetailDto } from "@strafos/common";
import { useUpdateEffect } from "react-use";
import { api } from "@app/utils/api/api";

export type CreateAirportFeeFormData = {
  matcher: string;
  airport_fee: number;
  handling_fee: number;
  departure_fee: number;
  arrival_fee: number;
  aircraft_id: number | null;
};

export type CreateAirportFeeFormProps = {
  title?: string;
  data: CreateAirportFeeFormData;
  onChange: (nextData: CreateAirportFeeFormData) => void | Promise<void>;
  onSubmit: (data: CreateAirportFeeFormData) => void | Promise<void>;
};

const CreateAirportFeeForm = ({
  title,
  data,
  onChange,
  onSubmit,
}: CreateAirportFeeFormProps) => {
  const { t } = useTranslation();

  const [selectedAircraft, setSelectedAircraft] =
    useState<AircraftDetailDto | null>(null);

  useUpdateEffect(() => {
    if (data.aircraft_id === null) {
      setSelectedAircraft(null);
    }
  }, [data.aircraft_id]);

  useEffect(() => {
    if (
      data.aircraft_id === null ||
      selectedAircraft?.id === data.aircraft_id
    ) {
      return;
    }

    api.getAircraft(data.aircraft_id).then((response) => {
      const { data } = response;

      setSelectedAircraft(data);
    });
  }, [data.aircraft_id, selectedAircraft]);

  const isSubmittable = useMemo(() => {
    const feeExists = [
      data.airport_fee,
      data.handling_fee,
      data.departure_fee,
      data.arrival_fee,
    ].some((fee) => fee > 0);

    return data.matcher && feeExists;
  }, [data]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        onSubmit(data);
      }}
    >
      {title && <Typography variant="formTitle">{title}</Typography>}

      <Box display="flex" alignItems="flex-end">
        <Box mr="0.5rem" width="15rem">
          <TextField
            fullWidth
            label={t("CreateAirportFeeForm.matcher_label")}
            placeholder={t("CreateAirportFeeForm.matcher_placeholder")}
            value={data.matcher}
            onChange={(event) =>
              void onChange(
                produce(data, (draft) => {
                  draft.matcher = event.target.value;
                }),
              )
            }
          />
        </Box>

        <Box mr="0.5rem" width="6rem">
          <TextField
            fullWidth
            inputComponent={TextFieldPriceFormatter}
            label={t("CreateAirportFeeForm.airport_fee_label")}
            placeholder={t("CreateAirportFeeForm.airport_fee_placeholder")}
            value={data.airport_fee}
            onBlur={(event) => {
              if (!event.target.value) {
                void onChange(
                  produce(data, (draft) => {
                    draft.airport_fee = 0;
                  }),
                );
              }
            }}
            onChange={(event) =>
              void onChange(
                produce(data, (draft) => {
                  draft.airport_fee = Number(event.target.value) || 0;
                }),
              )
            }
          />
        </Box>

        <Box mr="0.5rem" width="6rem">
          <TextField
            fullWidth
            inputComponent={TextFieldPriceFormatter}
            label={t("CreateAirportFeeForm.handling_fee_label")}
            placeholder={t("CreateAirportFeeForm.handling_fee_placeholder")}
            value={data.handling_fee}
            onBlur={(event) => {
              if (!event.target.value) {
                void onChange(
                  produce(data, (draft) => {
                    draft.handling_fee = 0;
                  }),
                );
              }
            }}
            onChange={(event) =>
              void onChange(
                produce(data, (draft) => {
                  draft.handling_fee = Number(event.target.value) || 0;
                }),
              )
            }
          />
        </Box>

        <Box mr="0.5rem" width="6rem">
          <TextField
            fullWidth
            inputComponent={TextFieldPriceFormatter}
            label={t("CreateAirportFeeForm.departure_fee_label")}
            placeholder={t("CreateAirportFeeForm.departure_fee_placeholder")}
            value={data.departure_fee}
            onBlur={(event) => {
              if (!event.target.value) {
                void onChange(
                  produce(data, (draft) => {
                    draft.departure_fee = 0;
                  }),
                );
              }
            }}
            onChange={(event) =>
              void onChange(
                produce(data, (draft) => {
                  draft.departure_fee = Number(event.target.value) || 0;
                }),
              )
            }
          />
        </Box>

        <Box mr="0.5rem" width="6rem">
          <TextField
            fullWidth
            inputComponent={TextFieldPriceFormatter}
            label={t("CreateAirportFeeForm.arrival_fee_label")}
            placeholder={t("CreateAirportFeeForm.arrival_fee_placeholder")}
            value={data.arrival_fee}
            onBlur={(event) => {
              if (!event.target.value) {
                void onChange(
                  produce(data, (draft) => {
                    draft.arrival_fee = 0;
                  }),
                );
              }
            }}
            onChange={(event) =>
              void onChange(
                produce(data, (draft) => {
                  draft.arrival_fee = Number(event.target.value) || 0;
                }),
              )
            }
          />
        </Box>

        <Box mr="0.5rem" width="15rem">
          <StyledAircraftPicker
            variant={AircraftPickerVariants.Condensed}
            value={selectedAircraft}
            label={t("CreateAirportFeeForm.aircraft_label")}
            placeholder={t("CreateAirportFeeForm.aircraft_placeholder")}
            classes={{
              inputRoot: "AirportPicker__inputRoot",
            }}
            onChange={(event, value) => {
              if (typeof value === "string" || Array.isArray(value)) {
                return;
              }

              setSelectedAircraft(value);

              onChange(
                produce(data, (draft) => {
                  draft.aircraft_id = value?.id ?? null;
                }),
              );
            }}
          />
        </Box>

        <Box>
          <Button size="large" type="submit" disabled={!isSubmittable}>
            {t("CreateAirportFeeForm.submit_button_label")}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

const StyledAircraftPicker = styled(AircraftPicker)`
  & .AirportPicker__inputRoot {
    padding-right: 0 !important;
  }
`;

export default CreateAirportFeeForm;
