import dayjs from "dayjs";

import { LegTypes, DisplayTimeTypes, PartialRequestDto } from "@strafos/common";
import { DateTypes } from "@app/components/atoms/DateTypeSwitch/DateTypeSwitch";
import { getIsRoundtripFlight } from "@app/utils/requestUtils";
import { getCombinedDate, LegComputationRequest } from "@strafos/common";

import {
  getLocalDateIgnoringTimezone,
  getUTCFromTimezoneAwareDate,
} from "@app/utils/dateUtils";

import {
  CreateRequestFormData,
  CreateRequestFormVariant,
} from "@app/components/organisms/CreateRequestForm/CreateRequestForm";

interface TransformDatesToLegComputationRequestPartOptions {
  dateType: DateTypes | null;
  timeDisplay: DisplayTimeTypes;
}

interface TransformFormDataToLegComputationRequestsOptions {
  variant: CreateRequestFormVariant;
  timeDisplay: DisplayTimeTypes;
}

const getLegType = (leg: CreateRequestFormData["legs"][0]) => {
  switch (leg.passengerCount) {
    case 0:
      return LegTypes.Ferry;

    default:
      return LegTypes.Occupied;
  }
};

const getLegDateTime = (leg: LegComputationRequest) => {
  if (leg.departure_date) {
    return getLocalDateIgnoringTimezone(leg.departure_date);
  }

  if (leg.arrival_date) {
    return getLocalDateIgnoringTimezone(leg.arrival_date);
  }

  return null;
};

const transformLegFormDataToLegComputationRequestBase = (
  leg: CreateRequestFormData["legs"][0],
): Pick<
  LegComputationRequest,
  "departure_airport_id" | "arrival_airport_id" | "passenger_count" | "type"
> => {
  return {
    departure_airport_id: leg.departureAirport?.id as number,
    arrival_airport_id: leg.arrivalAirport?.id as number,
    passenger_count: leg.passengerCount,
    type: getLegType(leg),
  };
};

const transformDatesToLegComputationRequestPart = (
  date: Date | null,
  time: Date | null,
  timezone: string | null,
  { dateType, timeDisplay }: TransformDatesToLegComputationRequestPartOptions,
): Pick<LegComputationRequest, "departure_date" | "arrival_date"> => {
  if (!date || !time || !timezone) {
    return {};
  }

  const timezoneAwareDateTime = getUTCFromTimezoneAwareDate(
    date,
    time,
    timezone,
    timeDisplay,
  );

  if (dateType === DateTypes.Arrival) {
    return {
      arrival_date: timezoneAwareDateTime,
    };
  }

  return {
    departure_date: timezoneAwareDateTime,
  };
};

export const transformRequestToFormData = (
  variant: CreateRequestFormVariant,
  request: PartialRequestDto | null,
): CreateRequestFormData => {
  const defaultDateType = DateTypes.Departure;

  if (!request?.input.requests) {
    return {
      legs: [
        {
          departureAirport: null,
          arrivalAirport: null,
          date: null,
          time: null,
          dateType: defaultDateType,
          returnDate: null,
          returnTime: null,
          returnDateType: null,
          passengerCount: 0,
        },
      ],
    };
  }

  if (
    variant === CreateRequestFormVariant.OneWayTrip ||
    !getIsRoundtripFlight(request)
  ) {
    return {
      legs: request.input.requests.map((leg) => ({
        departureAirport: null,
        arrivalAirport: null,
        date: getLegDateTime(leg),
        time: getLegDateTime(leg),
        dateType: leg.departure_date ? DateTypes.Departure : DateTypes.Arrival,
        returnDate: null,
        returnTime: null,
        returnDateType: null,
        passengerCount: leg.passenger_count,
      })),
    };
  }

  const [outwardLeg, returnLeg] = request.input.requests;

  return {
    legs: [
      {
        departureAirport: null,
        arrivalAirport: null,
        date: getLegDateTime(outwardLeg),
        time: getLegDateTime(outwardLeg),
        dateType: outwardLeg.departure_date
          ? DateTypes.Departure
          : DateTypes.Arrival,
        returnDate: getLegDateTime(returnLeg),
        returnTime: getLegDateTime(returnLeg),
        returnDateType: returnLeg.departure_date
          ? DateTypes.Departure
          : DateTypes.Arrival,
        passengerCount: outwardLeg.passenger_count,
      },
    ],
  };
};

export const transformFormDataToLegComputationRequests = (
  legEditorData: CreateRequestFormData,
  { variant, timeDisplay }: TransformFormDataToLegComputationRequestsOptions,
): { requests: LegComputationRequest[] } => {
  const sortedLegs = legEditorData.legs.concat().sort((a, b) => {
    if (!a.date || !a.time || !b.date || !b.time) {
      return 0;
    }

    const aDate = getCombinedDate(new Date(a.date), new Date(a.time));
    const bDate = getCombinedDate(new Date(b.date), new Date(b.time));

    return dayjs(aDate).isBefore(bDate) ? -1 : 1;
  });

  if (variant === CreateRequestFormVariant.RoundTrip) {
    const requests = sortedLegs.flatMap((leg) => {
      const computationRequestBase =
        transformLegFormDataToLegComputationRequestBase(leg);

      const outwardLegTimezone =
        leg.dateType === DateTypes.Departure
          ? leg.departureAirport?.timezone
          : leg.arrivalAirport?.timezone;

      const datesPart = transformDatesToLegComputationRequestPart(
        leg.date,
        leg.time,
        outwardLegTimezone ?? null,
        { dateType: leg.dateType, timeDisplay },
      );

      const returnAirportTimezone =
        leg.returnDateType === DateTypes.Departure
          ? leg.arrivalAirport?.timezone
          : leg.departureAirport?.timezone;

      const returnDatesPart = transformDatesToLegComputationRequestPart(
        leg.returnDate,
        leg.returnTime,
        returnAirportTimezone ?? null,
        { dateType: leg.returnDateType, timeDisplay },
      );

      return [
        {
          ...computationRequestBase,
          ...datesPart,
        },
        {
          departure_airport_id: leg.arrivalAirport?.id as number,
          arrival_airport_id: leg.departureAirport?.id as number,
          passenger_count: leg.passengerCount,
          type: getLegType(leg),
          ...returnDatesPart,
        },
      ];
    });

    return { requests };
  }

  const requests = sortedLegs.map((leg) => {
    const datesPart = transformDatesToLegComputationRequestPart(
      leg.date,
      leg.time,
      (leg.dateType === DateTypes.Departure
        ? leg.departureAirport?.timezone
        : leg.arrivalAirport?.timezone) ?? null,
      { dateType: leg.dateType, timeDisplay },
    );

    return {
      departure_airport_id: leg.departureAirport?.id as number,
      arrival_airport_id: leg.arrivalAirport?.id as number,
      passenger_count: leg.passengerCount,
      type: getLegType(leg),

      ...datesPart,
    };
  });

  return { requests };
};
