import React, { useState } from 'react'
import styled from 'styled-components'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { prop } from '@app/utils/css'

import BaseTooltip, {
  TooltipProps as BaseTooltipProps,
} from '@material-ui/core/Tooltip'

export interface TooltipProps extends BaseTooltipProps {
  backgroundColor?: string
  borderColor?: string
  disabled?: boolean
  fontSize?: number
  disablePortal?: boolean
}

export interface StyledTooltipProps
  extends Omit<TooltipProps, 'backgroundColor' | 'borderColor'> {
  $backgroundColor?: TooltipProps['backgroundColor']
  $borderColor?: TooltipProps['borderColor']
  $isHovered: boolean
  $fontSize: TooltipProps['fontSize']
}

const Tooltip = ({
  children,
  onClose,
  backgroundColor,
  borderColor,
  fontSize = 0.7,
  disabled,
  placement = 'right',
  interactive = false,
  className,
  disablePortal,
  ...props
}: TooltipProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false)

  if (disabled) {
    return children
  }

  return (
    <ClickAwayListener onClickAway={(event) => onClose?.(event)}>
      <div
        className={className}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <StyledTooltip
          $backgroundColor={backgroundColor}
          $borderColor={borderColor}
          $isHovered={isHovered}
          $fontSize={fontSize}
          arrow
          placement={placement}
          onClose={onClose}
          interactive={interactive}
          disableFocusListener={interactive}
          disableHoverListener={interactive}
          disableTouchListener={interactive}
          classes={{
            popper: 'Tooltip__popper',
            tooltip: 'Tooltip__tooltip',
            arrow: 'Tooltip__arrow',
          }}
          PopperProps={{
            disablePortal: disablePortal !== undefined ? disablePortal : true,
          }}
          {...props}
        >
          {children}
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  )
}

const StyledTooltip = styled(BaseTooltip)<StyledTooltipProps>`
  & + .Tooltip__popper {
    display: inline;
    z-index: ${({ $isHovered }) => ($isHovered ? '1501' : '1500')};
  }

  & + .Tooltip__popper .Tooltip__tooltip {
    background-color: ${prop('$backgroundColor')};
    color: ${prop('color')};

    border: ${({ $borderColor }) =>
      $borderColor && `1px solid ${$borderColor}`};

    font-size: ${prop('$fontSize')}rem;
  }

  & + .Tooltip__popper .Tooltip__arrow {
    color: ${prop('$backgroundColor')};

    &:before {
      border: ${({ $borderColor }) =>
        $borderColor && `1px solid ${$borderColor};`};
    }
  }
`

export default Tooltip
