import { getDisplayOffer, OfferDto, PartialRequestDto } from "@strafos/common";

export const getIsRoundtripFlight = (request: PartialRequestDto) => {
  if (request.input.requests.length !== 2) {
    return false;
  }

  const [outwardLeg, returnLeg] = request.input.requests;

  return (
    outwardLeg.arrival_airport_id === returnLeg.departure_airport_id &&
    outwardLeg.departure_airport_id === returnLeg.arrival_airport_id
  );
};

export const getOpenOfferId = (offers: OfferDto[]): number | null => {
  const displayOffer = getDisplayOffer(offers);

  return displayOffer.id;
};
