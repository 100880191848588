import React, { useCallback } from "react";
import { AirportDetailDto, AviaCalculationsResponseDto } from "@strafos/common";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@material-ui/core";
import styled from "styled-components";
import Typography from "@app/components/atoms/Typography/Typography";
import { useTranslation } from "react-i18next";
import { getUniqueErrors } from "@app/components/organisms/LegEditorForm/LegFlightRecalculate/avia.utils";
import {
  CalculationType,
  RadioValue,
} from "@app/components/organisms/LegEditorForm/LegFlightRecalculate/avia.types";
import { Loader } from "@app/components/organisms/LegEditorForm/components/Loader";

interface Props {
  airports: AirportDetailDto[];
  handleChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    value: CalculationType,
  ) => void;
  value: RadioValue | null;
  techStop: string;
  calculationType: CalculationType;
  aviaResponse: AviaCalculationsResponseDto;
  disabled?: boolean;
}

export const TechStopsSelector = (props: Props) => {
  const {
    airports,
    handleChange,
    value,
    techStop,
    calculationType,
    disabled,
    aviaResponse,
  } = props;

  const { t } = useTranslation();

  const techStopErrorPresent = useCallback(() => {
    return airports.length > 0;
  }, [aviaResponse, airports]);

  return (
    <TechStopSelectorWrapper>
      {!value && !disabled && <Loader />}
      {!disabled ? (
        <>
          {!!aviaResponse.errors && aviaResponse.errors.length > 0 && (
            <CalculationErrorWrapper>
              <ErrorHeader>
                {t("organisms.AviaPagesMenu.calculationError")}
              </ErrorHeader>
              {getUniqueErrors(aviaResponse.errors).map((err, index) => (
                <CalculationsError key={index}>{err.message}</CalculationsError>
              ))}
            </CalculationErrorWrapper>
          )}
          {techStopErrorPresent() ? (
            <>
              {!!value && value.order === 2 ? (
                <ErrorTypography>
                  {t(
                    "organisms.AviaPagesMenu.techStopSelector.tooManyTechstops",
                  )}
                </ErrorTypography>
              ) : (
                <>
                  <StyledTypography>
                    {t(
                      "organisms.AviaPagesMenu.techStopSelector.suggestedTechstops",
                    )}
                  </StyledTypography>
                  <Select
                    value={techStop}
                    onChange={(e) => handleChange(e, calculationType)}
                  >
                    {airports.map((airport) => (
                      <MenuItem
                        key={airport.icao_code}
                        value={airport.icao_code}
                      >
                        {`${airport.icao_code}, ${airport.iata_code} (${airport.name}, ${airport.city})`}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </>
          ) : (
            <StyledTypography>
              {t("organisms.AviaPagesMenu.techStopSelector.noCalculation")}
            </StyledTypography>
          )}
        </>
      ) : (
        <StyledTypography>
          {t("organisms.AviaPagesMenu.techStopSelector.noCalculation")}
        </StyledTypography>
      )}
    </TechStopSelectorWrapper>
  );
};

const TechStopSelectorWrapper = styled.div`
  padding: 1rem;
  width: 100%;
`;
const StyledTypography = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #525252;
  margin-top: 1rem;
`;

const ErrorTypography = styled(Typography)`
  color: red;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const ErrorHeader = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #525252;
`;

const CalculationsError = styled(Typography)`
  color: red;
  font-weight: 600;
`;

const CalculationErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
