import { lighten } from '@material-ui/core/styles'

import { LegTypes, OfferStatuses } from '@strafos/common'
import { Theme } from '@app/theme'

export const getOfferColor = (theme: Theme, offerStatus: OfferStatuses) => {
  switch (offerStatus) {
    case OfferStatuses.Booked:
    case OfferStatuses.BookedCancelled:
      return theme.colors.green

    case OfferStatuses.Cancelled:
      return theme.colors.grey

    case OfferStatuses.Declined:
      return theme.colors.brown

    case OfferStatuses.Rejected:
    case OfferStatuses.Storno:
      return theme.colors.red

    case OfferStatuses.Draft:
      return theme.colors.lightBlue

    case OfferStatuses.Quoted:
      return theme.colors.purple

    case OfferStatuses.Unhandled:
    case OfferStatuses.New:
    default:
      return theme.colors.orange
  }
}

export const getLegColor = (
  theme: Theme,
  legType: LegTypes,
  offerStatus: OfferStatuses,
) => {
  if (legType === LegTypes.Removed) {
    return theme.colors.red
  }

  if (legType === LegTypes.Outage) {
    return theme.colors.grey
  }

  const offerColor = getOfferColor(theme, offerStatus)

  return [LegTypes.Occupied, LegTypes.Ferry].includes(legType)
    ? offerColor
    : lighten(offerColor, 0.6)
}
