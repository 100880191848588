import React, { useEffect, useRef, useState, UIEvent } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { navigate, useLocation } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";

import Typography from "@app/components/atoms/Typography/Typography";
import Logo from "@app/components/atoms/Logo/Logo";
import UserPanel from "@app/components/organisms/UserPanel/UserPanel";
import { IMenuItem } from "@app/components/organisms/GeneralLayout/GeneralLayout";

import SideMenuItem, {
  SideMenuItemVariants,
} from "@app/components/organisms/GeneralLayout/SideMenuItem";

import { useActiveOperator } from "@app/hooks/useActiveOperator";
import { api } from "@app/utils/api/api";
import { theme } from "@app/theme";
import { logoutAction } from "@app/store/core/auth/auth.actions";
import { setSelectedOperatorAction } from "@app/store/core/userOperators/userOperators.actions";
import { selectUserOperators } from "@app/store/core/userOperators/userOperators.selectors";

import {
  Routes,
  UpdateOperatorSpecificUserDataDto,
  OperatorDto,
} from "@strafos/common";

import {
  patchMyUserAction,
  putMyPasswordAction,
} from "@app/store/core/userInfo/userInfo.actions";

import {
  selectIsPatchMyUserLoading,
  selectIsPutMyPasswordLoading,
  selectUserInfo,
} from "@app/store/core/userInfo/userInfo.selectors";
import { LocalStorageKeys } from "@app/constants";

export interface SideMenuProps {
  primaryMenuItems: IMenuItem[];
  secondaryMenuItems: IMenuItem[];
  onItemClick: (itemId: string) => Promise<void> | void;
  className?: string;
}

const SideMenu = ({
  primaryMenuItems,
  secondaryMenuItems,
  onItemClick,
  className,
}: SideMenuProps): JSX.Element => {
  const { t } = useTranslation();

  const location = useLocation();
  const dispatch = useDispatch();
  const primaryMenuContainerRef = useRef<HTMLDivElement | null>(null);

  const [isUserPanelVisible, setIsUserPanelVisible] = useState(false);
  const [isScrollableToTop, setIsScrollableToTop] = useState(false);
  const [isScrollableToBottom, setIsScrollableToBottom] = useState(false);

  const userInfo = useSelector(selectUserInfo);
  const operators = useSelector(selectUserOperators);
  const isPatchUserLoading = useSelector(selectIsPatchMyUserLoading);
  const isPutMyPasswordLoading = useSelector(selectIsPutMyPasswordLoading);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const activeOperator = useActiveOperator();

  // @todo Abstract into 'useActiveUser' hook
  const { data: userDataAxiosResponse } = useQuery(["user"], api.getMyUser, {
    refetchOnWindowFocus: false,
  });

  const operatorSpecificUserDataMutation = useMutation(
    (data: UpdateOperatorSpecificUserDataDto) => {
      return api.updateOperatorSpecificUserData(activeOperator.id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
      },
    },
  );

  useEffect(() => {
    const container = primaryMenuContainerRef.current;

    if (container && container.scrollHeight > container.clientHeight) {
      setIsScrollableToBottom(true);
    }
  }, []);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (!(event.target instanceof HTMLDivElement)) {
      return;
    }

    const scrollTop = event.target.scrollTop;
    const scrollBottom =
      event.target.scrollHeight - event.target.clientHeight - scrollTop;

    setIsScrollableToTop(scrollTop > 0);
    setIsScrollableToBottom(scrollBottom > 0);
  };

  const handleOperatorSwitch = async (operator: OperatorDto) => {
    const { data } = await api.switchOperator(operator.id);
    localStorage.setItem(LocalStorageKeys.AuthToken, data.access_token);
    dispatch(setSelectedOperatorAction(operator));

    // @todo Make routing great again
    const targetRoute =
      location.pathname === Routes.Index ? Routes.Reports : Routes.Index;

    navigate(targetRoute);
  };

  return (
    <StyledBox
      className={className}
      width="6.875rem"
      display="flex"
      flexDirection="column"
      position="fixed"
      height="100vh"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        my={4}
        color={theme.palette.common.white}
      >
        <Link to="/">
          <StyledLogo width="60px" />
        </Link>
      </Box>
      <OverflowContainer>
        <ShadowTop aria-hidden="true" $isVisible={isScrollableToTop} />
        <ShadowBottom aria-hidden="true" $isVisible={isScrollableToBottom} />
        <PrimaryMenuContainer
          ref={primaryMenuContainerRef}
          onScroll={handleScroll}
        >
          {primaryMenuItems.map((item) => (
            <SideMenuItem
              key={item.id}
              item={item}
              onClick={onItemClick}
              active={item.href === location.pathname}
              data-testid={item.testId}
              alertIcon={item.alertIcon}
            />
          ))}
        </PrimaryMenuContainer>
      </OverflowContainer>
      <SecondaryMenuContainer>
        {secondaryMenuItems.map((item) => (
          <SideMenuItem
            key={item.id}
            item={item}
            onClick={onItemClick}
            active={item.href === location.pathname}
            variant={SideMenuItemVariants.Small}
          />
        ))}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={2}
          mb={2}
        >
          {userInfo && operators && isUserPanelVisible && (
            <StyledUserPanel
              user={userInfo}
              operators={operators}
              activeOperator={activeOperator}
              onLogout={() => dispatch(logoutAction())}
              isUserUpdateLoading={isPatchUserLoading}
              isPasswordUpdateLoading={isPutMyPasswordLoading}
              onOperatorChange={(operator) => handleOperatorSwitch(operator)}
              onProfileChange={(partialUser) =>
                dispatch(patchMyUserAction(partialUser))
              }
              onPasswordChange={(updatePasswordDto) =>
                new Promise((resolve, reject) => {
                  dispatch(putMyPasswordAction(updatePasswordDto))
                    .then(resolve)
                    .catch(reject);
                })
              }
              onClose={() => setIsUserPanelVisible(false)}
              onSettingsChange={(partialUser) =>
                dispatch(patchMyUserAction(partialUser))
              }
              userData={userDataAxiosResponse?.data ?? null}
              onIntegrationsSettingsUpdate={(nextIntegrationsSettings) => {
                operatorSpecificUserDataMutation.mutate(
                  {
                    avinode_username: nextIntegrationsSettings.avinodeUsername,
                  },
                  {
                    onSuccess: () => {
                      enqueueSnackbar(
                        t("snackbar.userIntegrationsSettingsUpdateSuccess"),
                        { variant: "success" },
                      );
                    },

                    onError: () => {
                      enqueueSnackbar(
                        t("snackbar.userIntegrationsSettingsUpdateFailure"),
                        { variant: "error" },
                      );
                    },
                  },
                );
              }}
            />
          )}
          <StyledAvatar
            data-testid="SideMenu__user-avatar"
            onClick={(event) => {
              event.stopPropagation();

              setIsUserPanelVisible((currentValue) => !currentValue);
            }}
          >
            {userInfo?.first_name.charAt(0).toUpperCase()}
            {userInfo?.last_name?.charAt(0).toUpperCase()}
          </StyledAvatar>
        </Box>
      </SecondaryMenuContainer>
      <AppVersionTypography>
        {t("general.appVersionText", {
          version: process.env.BUSINESS_APP_VERSION,
        })}
      </AppVersionTypography>
    </StyledBox>
  );
};

// TODO CSS

const StyledLogo = styled(Logo)`
  color: ${({ theme }) => theme.palette.common.white};
`;
const StyledBox = styled(Box)`
  background-color: #00396f;
  background: linear-gradient(180deg, #375585 0%, #654874 100%);
`;

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

const StyledUserPanel = styled(UserPanel)`
  width: auto;
  position: fixed;
  left: 6.9rem;
  bottom: 0;
  background: ${({ theme }) => theme.palette.common.white};
`;

const OverflowContainer = styled.div`
  overflow: hidden;
  position: relative;
  flex: 1 1 auto;
`;

const PrimaryMenuContainer = styled.div`
  overflow: auto;
  height: 100%;
  box-shadow: inset 0 -4px 8px 0 rgba(101, 72, 116, 1);

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
`;

const Shadow = styled.div<{ $isVisible: boolean }>`
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.25s ease-out;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
`;

const ShadowTop = styled(Shadow)`
  box-shadow: 0 1rem 1rem -1rem #fff inset;
`;

const ShadowBottom = styled(Shadow)`
  box-shadow: 0 -1rem 1rem -1rem #fff inset;
`;

const SecondaryMenuContainer = styled.div`
  flex: 1 0 auto;
`;

const AppVersionTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.625rem;
  text-align: center;
  padding-bottom: 0.5rem;
`;

export default SideMenu;
