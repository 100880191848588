import { CellProps } from "react-table";
import { PartialRequestDto } from "@strafos/common";
import { useDateAndTimeFormats } from "@app/hooks/useDateAndTimeFormats";
import NewBadge from "@app/components/atoms/Badge/NewBadge";
import UnhandledBadge from "@app/components/atoms/Badge/UnhandledBadge";
import DraftBadge from "@app/components/atoms/Badge/DraftBadge";
import QuotedBadge from "@app/components/atoms/Badge/QuotedBadge";
import CancelledBadge from "@app/components/atoms/Badge/CancelledBadge";
import DeclinedBadge from "@app/components/atoms/Badge/DeclinedBadge";
import RejectedBadge from "@app/components/atoms/Badge/RejectedBadge";
import BookedBadge from "@app/components/atoms/Badge/BookedBadge";
import StornoBadge from "@app/components/atoms/Badge/StornoBadge";
import ProcessingBadge from "@app/components/atoms/Badge/ProcessingBadge";
import BookedCancelledBadge from "@app/components/atoms/Badge/BookedCancelledBadge";
import Box from "@material-ui/core/Box";
import Typography from "@app/components/atoms/Typography/Typography";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import ReservedBadge from "@app/components/atoms/Badge/ReservedBadge";

export const StatusCell = ({ cell }: CellProps<PartialRequestDto, unknown>) => {
  const { dateTimeFormat } = useDateAndTimeFormats();

  const badges: Record<PartialRequestDto["status"], JSX.Element> = {
    new: <NewBadge />,
    unhandled: <UnhandledBadge />,
    draft: <DraftBadge />,
    quoted: <QuotedBadge />,
    cancelled: <CancelledBadge />,
    declined: <DeclinedBadge />,
    rejected: <RejectedBadge />,
    booked: <BookedBadge />,
    storno: <StornoBadge />,
    processing: <ProcessingBadge />,
    "booked-cancelled": <BookedCancelledBadge />,
  };

  const isReserved = useMemo(
    () => cell.row.original.offers.some((o) => !!o.reserved),
    [cell],
  );

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
    >
      {isReserved ? <ReservedBadge /> : badges[cell.row.original.status]}
      <Box pl={1} pt={1}>
        <Typography variant="subtitle">
          {dayjs(cell.row.original.created_at).format(dateTimeFormat)}
        </Typography>
      </Box>
    </Box>
  );
};
