import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Logo from "@app/components/atoms/Logo/Logo";
import Button from "@app/components/atoms/Button/Button";
import EmptyListMessage from "@app/components/atoms/EmptyListMessage/EmptyListMessage";

import CreateRequestForm, {
  CreateRequestFormVariant,
} from "@app/components/organisms/CreateRequestForm/CreateRequestForm";

import AvailableAircraftDataGrid, {
  AvailableAircraftDateGridItem,
} from "@app/components/molecules/AvailableAircraftDataGrid/AvailableAircraftDataGrid";

import { DataGridProps } from "@app/components/atoms/DataGrid/DataGrid";
import { getErrorMessage } from "@app/utils/errorHandling";
import {
  ComputationTypes,
  LegComputationRequest,
  PartialRequestDto,
} from "@strafos/common";
import { selectUserInfo } from "@app/store/core/userInfo/userInfo.selectors";

import {
  resetCreateRequestStateAction,
  triggerComputationPolling,
} from "@app/store/pages/requests/createRequest/createRequest.actions";

import {
  selectCreateRequestData,
  selectCreateRequestError,
  selectCreateRequestIsPolling,
} from "@app/store/pages/requests/createRequest/createRequest.selectors";

export interface CreateRequestTabProps {
  onCreateOffers: (selectedAircraftIds: number[]) => void | Promise<void>;
  request?: PartialRequestDto;
  variant: CreateRequestFormVariant;
}

const CreateRequestTab = ({
  onCreateOffers,
  request,
  variant,
}: CreateRequestTabProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const submitRef = useRef<() => void>();
  const [selectedAircraftIds, setSelectedAircraftIds] = useState<number[]>([]);

  const userInfo = useSelector(selectUserInfo);

  const computation = useSelector(selectCreateRequestData);
  const computationError = useSelector(selectCreateRequestError);
  const isComputationPolling = useSelector(selectCreateRequestIsPolling);

  const isErrorVisible = !!computationError;
  const isLoaderVisible = !isErrorVisible && isComputationPolling;
  const isContentVisible = !!computation?.data?.length && !isErrorVisible;

  const isCreateRequestButtonVisible = isContentVisible;

  const shouldShowEmptyMessage =
    !isLoaderVisible && Number(computation?.data?.length) === 0;

  const availableAircraftData = useMemo(
    () =>
      computation?.data?.map((result) => {
        return {
          ...result,
          id: result.aircraft_id,
        };
      }) ?? [],
    [computation],
  );

  const errorMessage = useMemo(
    () =>
      getErrorMessage(computationError, {
        400: t(
          "organisms.CreateRequestDialog.CreateRequestTab.invalidCompanyError",
        ),
        default: t(
          "organisms.CreateRequestDialog.CreateRequestTab.defaultError",
        ),
      }),
    [computationError],
  );

  const handleAircraftSelectionChange: DataGridProps<AvailableAircraftDateGridItem>["onSelectionChange"] =
    (selectedRows) => {
      setSelectedAircraftIds(selectedRows?.map((row) => row.original.id));
    };

  const handleSubmitAircraftSearch = (data: {
    requests: LegComputationRequest[];
  }) => {
    dispatch(resetCreateRequestStateAction());

    dispatch(
      triggerComputationPolling({
        type: ComputationTypes.Offer,
        ...data,
      }),
    );
  };

  useEffect(() => {
    dispatch(resetCreateRequestStateAction());
  }, []);

  return (
    <Container>
      <CreateRequestForm
        onSubmit={handleSubmitAircraftSearch}
        submitRef={submitRef}
        request={request}
        variant={variant}
        timeDisplay={userInfo?.display_time_type ?? undefined}
      />
      <Content>
        {isLoaderVisible && (
          <LoadingSpinner loading={isComputationPolling}>
            <StyledLogo />
          </LoadingSpinner>
        )}
        {isErrorVisible && (
          <StyledEmptyListMessage title={errorMessage ?? undefined} />
        )}
        {shouldShowEmptyMessage && (
          <StyledEmptyListMessage
            title={t(
              "organisms.CreateRequestDialog.CreateRequestTab.noResults",
            )}
          />
        )}
        {isContentVisible && (
          <AvailableAircraftDataGrid
            data={availableAircraftData}
            onSelectionChange={handleAircraftSelectionChange}
          />
        )}
      </Content>
      <ButtonsContainer>
        <Button
          data-testid="SingleOrMultiLegTab__search-aircraft-button"
          loading={isComputationPolling}
          onClick={() => submitRef?.current?.()}
        >
          {t("organisms.CreateRequestDialog.CreateRequestTab.searchAircraft")}
        </Button>
        {isCreateRequestButtonVisible && (
          <Button
            data-testid="SingleOrMultiLegTab__create-request-button"
            disabled={!selectedAircraftIds.length}
            onClick={() => onCreateOffers(selectedAircraftIds)}
          >
            {t("organisms.CreateRequestDialog.CreateRequestTab.createRequest")}
          </Button>
        )}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledLogo = styled(Logo)`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 3rem;
  height: 3rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-right: 0.5rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

const Content = styled.div`
  padding: 1rem 0;
`;

const StyledEmptyListMessage = styled(EmptyListMessage)`
  margin: 0 auto;
`;

export default CreateRequestTab;
