import React from 'react'
import styled from 'styled-components'

import Typography, {
  TypographyProps,
} from '@app/components/atoms/Typography/Typography'

import { useSelector } from 'react-redux'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { getFormattedPrice } from '@strafos/common'

type Types = 'default' | 'profit' | 'loss' | 'disabled'

type Sizes = 'small' | 'normal'

interface PriceProps extends TypographyProps {
  value: number
  format?: string
  type?: Types
  size?: Sizes
}

interface StyledPriceProps extends TypographyProps {
  $type: Types
  $size: Sizes
}

const Price = ({
  value,
  className,
  size = 'normal',
  type = 'default',
}: PriceProps): JSX.Element => {
  const selectedOperator = useSelector(selectSelectedOperator)

  return (
    <StyledTypography className={className} $type={type} $size={size}>
      {getFormattedPrice(value, selectedOperator?.currency?.symbol || '')}
    </StyledTypography>
  )
}

const StyledTypography = styled(Typography)<StyledPriceProps>`
  color: ${({ $type, theme }) => {
    switch ($type) {
      case 'profit':
        return theme.palette.success.main

      case 'loss':
        return theme.palette.error.main

      case 'disabled':
        return theme.palette.grey[400]

      default:
        return null
    }
  }};

  font-size: ${({ $size }) => {
    switch ($size) {
      case 'small':
        return '0.875rem'

      default:
        return '1rem'
    }
  }};
`

export default Price
