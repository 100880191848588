import { useSelector } from "react-redux";
import { selectSelectedOperator } from "@app/store/core/userOperators/userOperators.selectors";
import { useQuery } from "@tanstack/react-query";
import { api } from "@app/utils/api/api";
import { useMemo } from "react";
import { ChatMessageDto } from "@strafos/common";

export const REFETCH_INTERVAL = 30_000;

export const QUERY_KEY = "newMessages";

interface UnreadMessages {
  data: ChatMessageDto[];
  queryKey: unknown[];
}

export default function useNewMessages() {
  const operator = useSelector(selectSelectedOperator);

  const { data } = useQuery(
    [QUERY_KEY, operator?.id],
    () => api.getNewMessages(REFETCH_INTERVAL / 1000),
    {
      refetchInterval: REFETCH_INTERVAL,
      enabled: !!operator?.id,
    },
  );

  return useMemo((): UnreadMessages => {
    return {
      data: data?.data || [],
      queryKey: [QUERY_KEY],
    };
  }, [data?.data]);
}
