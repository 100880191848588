import { LegFormData } from '@strafos/common'
import { useAviaCalculations } from '@app/hooks/useAviaCalculations'
import React from 'react'
import { CalculatedFlightsRadio } from '@app/components/organisms/LegEditorForm/LegFlightRecalculate/CalculatedFlightsRadio'
import styled from 'styled-components'
import { mapTechStopsToCalculation } from '@app/components/organisms/LegEditorForm/LegFlightRecalculate/avia.utils'
import { RadioValue } from '@app/components/organisms/LegEditorForm/LegFlightRecalculate/avia.types'
import { Loader } from '@app/components/organisms/LegEditorForm/components/Loader'

interface Props {
  leg: LegFormData
  value: RadioValue
  handleFlightTimeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: RadioValue,
  ) => void
  setValue: (
    value: ((prevState: RadioValue[]) => RadioValue[]) | RadioValue[],
  ) => void
}

export const AviaPagesCalculations = (props: Props) => {
  const { leg, value, handleFlightTimeChange, setValue } = props
  const { aviaResponse, isLoading, error } = useAviaCalculations({
    leg,
  })

  if (!isLoading && error) {
    return null
  }

  return (
    <CalculationResults>
      {isLoading || !aviaResponse ? (
        <Loader />
      ) : (
        <CalculatedFlightsRadio
          handleChange={handleFlightTimeChange}
          value={value}
          setValue={setValue}
          data={aviaResponse}
          leg={leg}
          techStopCalculations={mapTechStopsToCalculation(aviaResponse)}
        />
      )}
    </CalculationResults>
  )
}

const CalculationResults = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
