import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { AircraftDetailDto, CalendarNoteDto } from '@strafos/common'

interface CalendarNoteProps {
  isEditable: boolean
  date: Date
  aircraft?: AircraftDetailDto
  noteObj: CalendarNoteDto | undefined
  onSave: (
    text: string,
    date: Date,
    aircraft?: AircraftDetailDto,
    note?: CalendarNoteDto,
  ) => void
}

const CalendarNote = React.memo(
  ({ isEditable, date, aircraft, noteObj, onSave }: CalendarNoteProps) => {
    const [note, setNote] = useState(noteObj?.text || '')

    useEffect(() => {
      setNote(noteObj?.text || '')
    }, [noteObj?.text])

    const handleBlur = () => {
      if (isEditable) {
        onSave(note, date, aircraft, noteObj)
      }
    }

    return (
      <NoteContainer>
        <NoteTextArea
          disabled={!isEditable}
          value={note}
          title={note}
          hasText={!!note}
          onChange={(e) => setNote(e.target.value)}
          onBlur={handleBlur}
          placeholder={isEditable ? 'Add note...' : ''}
          rows={2}
        />
      </NoteContainer>
    )
  },
)

const NoteContainer = styled.div`
  margin-top: 4px;
`

const NoteTextArea = styled.textarea<{ hasText: boolean }>`
  font-size: 0.8rem;
  color: ${(props) => (props.disabled ? 'blue' : '#333')};
  width: 100%;
  padding: 4px;
  border: 0px;
  border-radius: 4px;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  font-weight: ${(props) => (props.hasText ? 'bold' : 'normal')};

  &::placeholder {
    color: #ccc;
    font-style: italic;
  }

  &:focus {
    outline: none;
    border-color: #888;
  }
`

export default CalendarNote
