import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

const useChangeAircraftValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object().shape({
    aircraft: yup
      .object()
      .typeError(t('organisms.AirportFeeForm.departureAirportRequired'))
      .required(t('organisms.AirportFeeForm.departureAirportRequired')),
  })
}

export default useChangeAircraftValidationSchema
