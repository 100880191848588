import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import Dialog from "@app/components/atoms/Dialog/Dialog";
import Typography from "@app/components/atoms/Typography/Typography";
import Button from "@app/components/atoms/Button/Button";
import MarketplaceExtensionForm from "@app/components/organisms/MarketplaceExtensionForm/MarketplaceExtensionForm";

import { PatchMarketplaceExtensionDto } from "@app/utils/api/types";

import {
  getPrecedingScheduleItem,
  getSucceedingScheduleItem,
  ScheduleDetailDto,
} from "@strafos/common";
dayjs.extend(utc);

interface MarketplaceExtensionDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: PatchMarketplaceExtensionDto) => void;
  scheduleItem: ScheduleDetailDto;
  aircraftSchedule?: ScheduleDetailDto[];
  isLoading?: boolean;
}

const MarketplaceExtensionDialog = ({
  open,
  onClose,
  onSubmit,
  scheduleItem,
  isLoading,
  aircraftSchedule = [],
}: MarketplaceExtensionDialogProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<() => void>();

  const fromBoundary = useMemo(() => {
    if (!scheduleItem.aircraft) {
      throw new Error("Aircraft is missing in schedule item data");
    }

    const precedingItem = getPrecedingScheduleItem(
      scheduleItem,
      aircraftSchedule,
    );

    return precedingItem?.arrival_date;
  }, [aircraftSchedule, scheduleItem]);

  const toBoundary = useMemo(() => {
    if (!scheduleItem.aircraft) {
      throw new Error("Aircraft is missing in schedule item data");
    }

    const succeedingItem = getSucceedingScheduleItem(
      scheduleItem,
      aircraftSchedule,
    );

    return succeedingItem?.departure_date;
  }, [aircraftSchedule, scheduleItem]);

  return (
    <StyledDialog
      classes={{ paper: "Dialog__paper" }}
      open={open}
      onClose={onClose}
      disableEnforceFocus
    >
      <HeaderContainer>
        <Typography variant="dialogHeading">
          {t("organisms.MarketplaceExtensionDialog.heading")}
        </Typography>
      </HeaderContainer>
      <Content>
        <MarketplaceExtensionForm
          onSubmit={onSubmit}
          scheduleItem={scheduleItem}
          submitRef={submitRef}
          fromLeftBoundary={fromBoundary}
          toRightBoundary={toBoundary}
        />
        <ButtonsContainer>
          <Button onClick={() => submitRef?.current?.()} loading={isLoading}>
            {t("organisms.MarketplaceExtensionDialog.save")}
          </Button>
        </ButtonsContainer>
      </Content>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  .Dialog__paper {
    padding: 0;
    min-width: 50rem;
    min-height: 30rem;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  margin-bottom: 1rem;
  padding: 2rem 2rem 0 2rem;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default MarketplaceExtensionDialog;
