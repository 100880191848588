import {
  LegFormData,
  AviaCalculationsResponseDto,
  AirportDetailDto,
} from "@strafos/common";
import { useTranslation } from "react-i18next";
import { CalculatedLegDetail } from "@app/components/organisms/LegEditorForm/components/CalculatedLegDetail";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Typography from "@app/components/atoms/Typography/Typography";
import FormControl from "@material-ui/core/FormControl";
import { Radio, RadioGroup } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TechStopsSelector } from "@app/components/organisms/LegEditorForm/LegFlightRecalculate/TechStopsSelector";
import { getTechStopAirports } from "@app/components/organisms/LegEditorForm/LegFlightRecalculate/avia.utils";
import {
  CalculationTechStop,
  CalculationType,
  RadioValue,
} from "@app/components/organisms/LegEditorForm/LegFlightRecalculate/avia.types";

interface Props {
  value: RadioValue;
  setValue: (
    value: ((prevState: RadioValue[]) => RadioValue[]) | RadioValue[],
  ) => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: RadioValue,
  ) => void;
  data: AviaCalculationsResponseDto;
  leg: LegFormData;
  techStopCalculations: CalculationType[];
}

export const CalculatedFlightsRadio = (props: Props) => {
  const { handleChange, data, leg, techStopCalculations, value, setValue } =
    props;
  const disabledRadio: boolean = techStopCalculations.length > 1;

  const { t } = useTranslation();
  const [airports, setAirports] = useState<AirportDetailDto[]>([]);
  const [techStop, setTechStop] = useState<CalculationTechStop>({
    airwayWeatherImpacted: techStopCalculations.includes(
      "airwayWeatherImpacted",
    )
      ? (data?.techStops?.[0] ?? "")
      : null,
    airway: techStopCalculations.includes("airway")
      ? disabledRadio
        ? null
        : (data?.techStops?.[0] ?? "")
      : null,
    greatCircle: techStopCalculations.includes("greatCircle")
      ? (data?.techStops?.[0] ?? "")
      : null,
  });

  useEffect(() => {
    if (!!data.techStops && data.techStops.length > 0) {
      mapTechStopAirports();
    }
  }, [data?.techStops]);

  const handleSelectChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    type: CalculationType,
  ) => {
    setTechStop((prevState) => ({
      ...prevState,
      [type]: event.target.value as string,
    }));
    setValue((prevState) => {
      return prevState.map((prevValue) =>
        prevValue.order === value.order
          ? {
              type: "techStop",
              value: event.target.value as string,
              order: value.order,
            }
          : prevValue,
      );
    });
  };

  const mapTechStopAirports = async () => {
    const techStopAirports = await getTechStopAirports(data?.techStops ?? []);
    setAirports(techStopAirports);
  };

  const hasErrors = (data.errors?.length || 0) > 0;

  const disabledRadios: Array<keyof CalculationTechStop> = Object.entries(
    techStop,
  ).reduce(
    (acc, [key, val]) => {
      if (value.order > 1 || (hasErrors && !val)) {
        acc.push(key as keyof CalculationTechStop);
      }
      return acc;
    },
    [] as Array<keyof CalculationTechStop>,
  );

  return (
    <StyledFormControl>
      <RadioGroup
        defaultValue={value.value ?? ""}
        value={value.value}
        name="choose-flight"
        onChange={(e, val) =>
          handleChange(e, { type: "leg", value: val, order: value.order })
        }
      >
        {data.flightTime.airwayWeatherImpacted ? (
          <StyledFormControlLabel
            label={
              <CalculatedLegDetail
                header={t("organisms.AviaPagesMenu.airwayWeatherImpacted")}
                leg={leg}
                type="airwayWeatherImpacted"
                calculations={data}
              />
            }
            value="airwayWeatherImpacted"
            control={<Radio />}
          />
        ) : (
          <>
            {techStopCalculations.includes("airwayWeatherImpacted") ? (
              <StyledFormControlLabel
                control={<Radio />}
                value={techStop["airwayWeatherImpacted"]}
                disabled={disabledRadios.includes("airwayWeatherImpacted")}
                label={
                  <TechStopsSelector
                    airports={airports}
                    handleChange={handleSelectChange}
                    value={disabledRadio ? null : value}
                    techStop={techStop["airwayWeatherImpacted"] ?? ""}
                    calculationType="airwayWeatherImpacted"
                    disabled={disabledRadios.includes("airwayWeatherImpacted")}
                    aviaResponse={data}
                  />
                }
              />
            ) : (
              <StyledFormControlLabel
                control={<Radio disabled />}
                label={
                  <NoDataWrapper>
                    <NoRouteAndWeatherHeader>
                      {t("organisms.AviaPagesMenu.noAirwayWindImpacted")}
                    </NoRouteAndWeatherHeader>
                  </NoDataWrapper>
                }
              />
            )}
          </>
        )}
        {techStopCalculations.includes("airway") ? (
          <StyledFormControlLabel
            control={<Radio />}
            value={techStop["airway"]}
            disabled={disabledRadios.includes("airway")}
            label={
              <TechStopsSelector
                airports={airports}
                handleChange={handleSelectChange}
                value={disabledRadio ? null : value}
                techStop={techStop["airway"] ?? ""}
                calculationType="airway"
                disabled={disabledRadios.includes("airway")}
                aviaResponse={data}
              />
            }
          />
        ) : (
          <StyledFormControlLabel
            label={
              <CalculatedLegDetail
                header={t("organisms.AviaPagesMenu.airway")}
                leg={leg}
                type="airway"
                calculations={data}
              />
            }
            value="airway"
            control={<Radio />}
          />
        )}
        {/* Hidden because of the SC-260 ticket: https://inventi-dev.atlassian.net/browse/SC-260 */}
        {/*{techStopCalculations.includes('greatCircle') ? (*/}
        {/*  <StyledFormControlLabel*/}
        {/*    control={<Radio style={disabledRadio ? { display: 'none' } : {}} />}*/}
        {/*    value={techStop['greatCircle']}*/}
        {/*    checked={value.value === techStop['greatCircle']}*/}
        {/*    disabled={disabledRadios.includes('greatCircle')}*/}
        {/*    label={*/}
        {/*      <TechStopsSelector*/}
        {/*        airports={airports}*/}
        {/*        handleChange={handleSelectChange}*/}
        {/*        value={value}*/}
        {/*        techStop={techStop['greatCircle'] ?? ''}*/}
        {/*        calculationType="greatCircle"*/}
        {/*        aviaResponse={data}*/}
        {/*        disabled={disabledRadios.includes('greatCircle')}*/}
        {/*      />*/}
        {/*    }*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <StyledFormControlLabel*/}
        {/*    label={*/}
        {/*      <CalculatedLegDetail*/}
        {/*        header={t('organisms.AviaPagesMenu.greatCircle')}*/}
        {/*        leg={leg}*/}
        {/*        type="greatCircle"*/}
        {/*        calculations={data}*/}
        {/*      />*/}
        {/*    }*/}
        {/*    value="greatCircle"*/}
        {/*    control={<Radio />}*/}
        {/*  />*/}
        {/*)}*/}
      </RadioGroup>
    </StyledFormControl>
  );
};

const NoDataWrapper = styled.div`
  padding: 1rem;
`;

const NoRouteAndWeatherHeader = styled(Typography)`
  color: #525252;
  font-size: 1rem;
  font-weight: 700;
  margin: 1.5rem 0;
  border: solid 2px 0;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  height: 10rem;
  width: 100%;
`;
const StyledFormControl = styled(FormControl)`
  align-items: center;
`;
