import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { BaseLegDetailDto, ScheduleDetailDto } from "@strafos/common";

dayjs.extend(utc);

export const getSwimlaneScheduleItemData = (
  scheduleItem: BaseLegDetailDto | ScheduleDetailDto,
) => {
  const departureDate = dayjs.utc(scheduleItem.departure_date);

  const arrivalDate = dayjs.utc(scheduleItem.arrival_date);

  const scheduleItemStart = dayjs.utc(
    scheduleItem.marketplace_departure_date ?? scheduleItem.departure_date,
  );

  const scheduleItemEnd = dayjs.utc(
    scheduleItem.marketplace_arrival_date ?? scheduleItem.arrival_date,
  );

  const marketplaceExtensionWidthPercentage =
    100 -
    (arrivalDate.diff(departureDate) /
      scheduleItemEnd.diff(scheduleItemStart)) *
      100;

  const departureDateDiffPercentage =
    (Math.abs(scheduleItemEnd.diff(arrivalDate)) /
      scheduleItemEnd.diff(scheduleItemStart)) *
    100;

  const marketplaceExtensionStartPercentage = departureDateDiffPercentage
    ? 100 - departureDateDiffPercentage
    : 0;

  return {
    scheduleItemStart,
    scheduleItemEnd,
    marketplaceExtensionWidthPercentage,
    marketplaceExtensionStartPercentage,
  };
};
