/**
 * @todo Context types
 */

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { getUTCFromTimezoneAwareDate } from "@app/utils/dateUtils";

import {
  DisplayTimeTypes,
  LegTypes,
  castLegJunctureToDates,
  getScheduleWithRemovedLegsOmitted,
  getLegTurnaroundInMinutes,
  getDoesDateClashWithLeg,
  BaseLegDetailDto,
  ScheduleDetailDto,
} from "@strafos/common";
import { getPrecedingLegValues } from "@app/components/organisms/LegEditorForm/LegEditorForm.utils";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

const getTestIsTurnaroundMet: (
  timeDisplay: DisplayTimeTypes,
) => yup.TestFunction<Date | null | undefined> =
  (timeDisplay) => (value, context) => {
    const precedingLegValues = getPrecedingLegValues(context);

    if (
      !precedingLegValues?.arrivalDate ||
      !precedingLegValues?.arrivalTime ||
      !precedingLegValues?.arrivalAirport
    ) {
      return true;
    }

    if (
      !context.parent.departureTime ||
      !context.parent.departureDate ||
      !context.parent.departureAirport
    ) {
      return true;
    }

    const precedingArrivalDateTime = getUTCFromTimezoneAwareDate(
      precedingLegValues.arrivalDate,
      precedingLegValues.arrivalTime,
      precedingLegValues.arrivalAirport.timezone,
      timeDisplay,
    );

    const departureDateTime = getUTCFromTimezoneAwareDate(
      context.parent.departureDate,
      context.parent.departureTime,
      context.parent.departureAirport.timezone,
      timeDisplay,
    );

    const turnaroundInMinutes = getLegTurnaroundInMinutes(
      context.parent.type,
      context.parent.aircraft,
    );

    return dayjs
      .utc(departureDateTime)
      .subtract(turnaroundInMinutes, "minutes")
      .isSameOrAfter(precedingArrivalDateTime);
  };

const getTestIsScheduleTurnaroundMetForDeparture: (
  schedule: ScheduleDetailDto[],
  removedLegs: BaseLegDetailDto[],
  timeDisplay: DisplayTimeTypes,
) => yup.TestFunction<Date | null | undefined> =
  (schedule, removedLegs, timeDisplay) => (value, context) => {
    if (
      !context.parent.departureTime ||
      !context.parent.departureDate ||
      !context.parent.departureAirport
    ) {
      return true;
    }

    const departureDateTime = getUTCFromTimezoneAwareDate(
      context.parent.departureDate,
      context.parent.departureTime,
      context.parent.departureAirport.timezone,
      timeDisplay,
    );

    const turnaroundInMinutes = getLegTurnaroundInMinutes(
      context.parent.type,
      context.parent.aircraft,
    );

    const departureMinusTurnaround = dayjs
      .utc(departureDateTime)
      .subtract(turnaroundInMinutes, "minutes")
      .toDate();

    const validationSchedule = getScheduleWithRemovedLegsOmitted(
      schedule,
      removedLegs,
    );

    return validationSchedule.every((scheduleItem) => {
      return !getDoesDateClashWithLeg(
        departureMinusTurnaround,
        castLegJunctureToDates(scheduleItem),
      );
    });
  };

const getTestIsScheduleTurnaroundMetForArrival: (
  schedule: ScheduleDetailDto[],
  removedLegs: BaseLegDetailDto[],
  timeDisplay: DisplayTimeTypes,
) => yup.TestFunction<Date | null | undefined> =
  (schedule, removedLegs, timeDisplay) => (value, context) => {
    if (
      !context.parent.arrivalTime ||
      !context.parent.arrivalDate ||
      !context.parent.arrivalAirport
    ) {
      return true;
    }

    const arrivalDateTime = getUTCFromTimezoneAwareDate(
      context.parent.arrivalDate,
      context.parent.arrivalTime,
      context.parent.arrivalAirport.timezone,
      timeDisplay,
    );

    const turnaroundInMinutes = getLegTurnaroundInMinutes(
      context.parent.type,
      context.parent.aircraft,
    );

    const arrivalPlusTurnaround = dayjs
      .utc(arrivalDateTime)
      .add(turnaroundInMinutes, "minutes")
      .toDate();

    const validationSchedule = getScheduleWithRemovedLegsOmitted(
      schedule,
      removedLegs,
    );

    return validationSchedule.every((scheduleItem) => {
      return !getDoesDateClashWithLeg(
        arrivalPlusTurnaround,
        castLegJunctureToDates(scheduleItem),
      );
    });
  };

const useLegEditorWarningsSchema = ({
  schedule,
  removedLegs,
  timeDisplay,
}: {
  schedule: ScheduleDetailDto[];
  removedLegs: BaseLegDetailDto[];
  timeDisplay: DisplayTimeTypes;
}) => {
  const { t } = useTranslation();

  return yup.object().shape({
    legs: yup
      .array()
      .transform((legs: BaseLegDetailDto[]) =>
        legs.filter((leg) => leg.type !== LegTypes.Removed),
      )
      .of(
        yup
          .object()
          .shape({
            departureDate: yup
              .date()
              .optional()
              .nullable()
              .test(
                "turnaroundNotMet",
                t("organisms.LegEditorForm.turnaroundNotMet"),
                getTestIsTurnaroundMet(timeDisplay),
              )
              .test(
                "scheduleTurnaroundNotMet",
                t("organisms.LegEditorForm.scheduleTurnaroundNotMet"),
                getTestIsScheduleTurnaroundMetForDeparture(
                  schedule,
                  removedLegs,
                  timeDisplay,
                ),
              ),
            departureTime: yup
              .date()
              .optional()
              .nullable()
              .test(
                "turnaroundNotMet",
                t("organisms.LegEditorForm.turnaroundNotMet"),
                getTestIsTurnaroundMet(timeDisplay),
              )
              .test(
                "scheduleTurnaroundNotMet",
                t("organisms.LegEditorForm.scheduleTurnaroundNotMet"),
                getTestIsScheduleTurnaroundMetForDeparture(
                  schedule,
                  removedLegs,
                  timeDisplay,
                ),
              ),
            arrivalDate: yup
              .date()
              .optional()
              .nullable()
              .test(
                "turnaroundNotMet",
                t("organisms.LegEditorForm.turnaroundNotMet"),
                getTestIsTurnaroundMet(timeDisplay),
              )
              .test(
                "scheduleTurnaroundNotMet",
                t("organisms.LegEditorForm.scheduleTurnaroundNotMet"),
                getTestIsScheduleTurnaroundMetForArrival(
                  schedule,
                  removedLegs,
                  timeDisplay,
                ),
              ),
            arrivalTime: yup
              .date()
              .optional()
              .nullable()
              .test(
                "turnaroundNotMet",
                t("organisms.LegEditorForm.turnaroundNotMet"),
                getTestIsTurnaroundMet(timeDisplay),
              )
              .test(
                "scheduleTurnaroundNotMet",
                t("organisms.LegEditorForm.scheduleTurnaroundNotMet"),
                getTestIsScheduleTurnaroundMetForArrival(
                  schedule,
                  removedLegs,
                  timeDisplay,
                ),
              ),
          })
          .required(),
      )
      .required(),
  });
};

export default useLegEditorWarningsSchema;
