import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Button from "@app/components/atoms/Button/Button";

import AirportPicker, {
  AirportPickerVariants,
} from "@app/components/atoms/AirportPicker/AirportPicker";

import { AirportDetailDto } from "@strafos/common";

interface SplitLegFormProps {
  onSubmit: (airport: AirportDetailDto) => void;
  className?: string;
}

const SplitLegForm = ({
  onSubmit,
  className,
}: SplitLegFormProps): JSX.Element => {
  const { t } = useTranslation();
  const [airport, setAirport] = useState<AirportDetailDto | null>(null);

  const handleAirportChange = (
    event: ChangeEvent<object>,
    value: string | AirportDetailDto | (string | AirportDetailDto)[] | null,
  ) => {
    const isValueOfAirportType = (
      value: string | AirportDetailDto | (string | AirportDetailDto)[] | null,
    ): value is AirportDetailDto => !!(value as AirportDetailDto)?.id;

    if (!isValueOfAirportType(value)) {
      return;
    }

    setAirport(value);
  };

  const handleSubmit = () => {
    onSubmit(airport as AirportDetailDto);
  };

  return (
    <Container className={className}>
      <AirportPickerContainer>
        <AirportPicker
          variant={AirportPickerVariants.Condensed}
          onChange={handleAirportChange}
          value={airport}
          inputProps={{
            "data-testid": "SplitLegForm__airport-input",
          }}
        />
      </AirportPickerContainer>
      <Button
        inverted
        disabled={!airport}
        onClick={handleSubmit}
        data-testid="SplitLegForm__submit-button"
      >
        {t("molecules.SplitLegForm.submit")}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  min-height: 5rem;
  min-width: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const AirportPickerContainer = styled.div`
  min-width: 10rem;
`;

export default SplitLegForm;
