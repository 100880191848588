import React, { useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Dialog from "@app/components/atoms/Dialog/Dialog";
import OutageForm from "@app/components/organisms/OutageForm/OutageForm";
import Typography from "@app/components/atoms/Typography/Typography";
import Button from "@app/components/atoms/Button/Button";

import { CreateOutageDto, ScheduleDetailDto } from "@strafos/common";

interface OutageDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: CreateOutageDto) => void;
  scheduleItem?: ScheduleDetailDto;
  isLoading?: boolean;
}

const OutageDialog = ({
  open,
  onClose,
  onSubmit,
  scheduleItem,
  isLoading,
}: OutageDialogProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<() => void>();

  return (
    <StyledDialog
      classes={{ paper: "Dialog__paper" }}
      open={open}
      onClose={onClose}
      disableEnforceFocus
    >
      <HeaderContainer>
        <Typography variant="dialogHeading">
          {t("organisms.OutageDialog.heading")}
        </Typography>
      </HeaderContainer>
      <Content>
        <OutageForm
          onSubmit={onSubmit}
          scheduleItem={scheduleItem}
          submitRef={submitRef}
        />
        <ButtonsContainer>
          <Button loading={isLoading} onClick={() => submitRef?.current?.()}>
            {t("organisms.OutageDialog.save")}
          </Button>
        </ButtonsContainer>
      </Content>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  .Dialog__paper {
    padding: 0;
    min-width: 50rem;
    min-height: 30rem;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  margin-bottom: 1rem;
  padding: 2rem 2rem 0 2rem;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default OutageDialog;
