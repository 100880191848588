import React, { MutableRefObject, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import Button from "@app/components/atoms/Button/Button";
import ErrorTooltip from "@app/components/atoms/Tooltip/ErrorTooltip";
import TextField from "@app/components/atoms/TextField/TextField";
import SingleDatePicker from "@app/components/atoms/SingleDatePicker/SingleDatePicker";
import TimePicker from "@app/components/atoms/TimePicker/TimePicker";

import AircraftPicker, {
  AircraftPickerVariants,
} from "@app/components/atoms/AircraftPicker/AircraftPicker";

import { useYupValidationResolver } from "@app/hooks/useYupValidationResolver";
import { PatchMarketplaceExtensionDto } from "@app/utils/api/types";
import useMarketplaceExtensionValidationSchema from "@app/components/organisms/MarketplaceExtensionForm/useMarketplaceExtensionValidationSchema";

import {
  getCombinedDate,
  getDurationBetweenDates,
  ScheduleDetailDto,
} from "@strafos/common";

import {
  transformMarketplaceExtensionFormDataToPatchMarketplaceExtensionDto,
  transformScheduleItemToMarketplaceExtensionFormData,
} from "@app/components/organisms/MarketplaceExtensionForm/MarketplaceExtensionForm.transformer";

dayjs.extend(utc);

export interface MarketplaceExtensionFormData {
  fromDate: Date;
  fromTime: Date;
  toDate: Date;
  toTime: Date;
}

interface MarketplaceExtensionFormProps {
  onSubmit: (values: PatchMarketplaceExtensionDto) => void;
  scheduleItem: ScheduleDetailDto;
  submitRef?: MutableRefObject<
    (() => void) | (() => Promise<void>) | undefined
  >;
  fromLeftBoundary: string | Date | undefined;
  toRightBoundary: string | Date | undefined;
}

const MarketplaceExtensionForm = ({
  onSubmit,
  scheduleItem,
  submitRef,
  fromLeftBoundary,
  toRightBoundary,
}: MarketplaceExtensionFormProps) => {
  const { t } = useTranslation();
  const validationSchema = useMarketplaceExtensionValidationSchema({
    fromRightBoundary: scheduleItem.departure_date,
    toLeftBoundary: scheduleItem.arrival_date,
    fromLeftBoundary,
    toRightBoundary,
  });

  const isFromDateDisabled = dayjs
    .utc(fromLeftBoundary)
    .isSame(scheduleItem.departure_date);

  const isToDateDisabled = dayjs
    .utc(toRightBoundary)
    .isSame(scheduleItem.arrival_date);

  const validationResolver = useYupValidationResolver(validationSchema);

  const defaultValues =
    transformScheduleItemToMarketplaceExtensionFormData(scheduleItem);

  const { handleSubmit, control, formState, watch } =
    useForm<MarketplaceExtensionFormData>({
      resolver: validationResolver,
      defaultValues,
    });

  const { fromDate, fromTime, toDate, toTime } = watch();

  const duration = useMemo(() => {
    const defaultDuration = "-";

    if (!fromDate || !fromTime || !toDate || !toTime) {
      return defaultDuration;
    }

    const from = getCombinedDate(fromDate, fromTime);
    const to = getCombinedDate(toDate, toTime);

    const nextDuration = getDurationBetweenDates(from, to);

    if (!nextDuration) {
      return defaultDuration;
    }

    return t("organisms.MarketplaceExtensionForm.durationFormat", {
      days: nextDuration.days,
      hours: nextDuration.hours,
      minutes: nextDuration.minutes,
    });
  }, [fromDate, fromTime, toDate, toTime]);

  const handleSubmitClick = (values: MarketplaceExtensionFormData) => {
    const transformedValues =
      transformMarketplaceExtensionFormDataToPatchMarketplaceExtensionDto(
        values,
      );

    onSubmit(transformedValues);
  };

  useEffect(() => {
    if (submitRef) {
      submitRef.current = handleSubmit(handleSubmitClick);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(handleSubmitClick)}>
      <Fields>
        <StyledAircraftPicker
          label={t("organisms.MarketplaceExtensionForm.aircraft")}
          variant={AircraftPickerVariants.Condensed}
          value={scheduleItem.aircraft ?? null}
          disabled
        />
        <DatesSection>
          <Controller<MarketplaceExtensionFormData, "fromDate">
            name="fromDate"
            control={control}
            render={({ field: { value, name, onChange } }) => {
              return (
                <ErrorTooltip title={formState.errors.fromDate?.message}>
                  <DatePickerContainer>
                    <SingleDatePicker
                      size="normal"
                      value={value ?? null}
                      onChange={onChange}
                      disabled={isFromDateDisabled}
                      placeholder={t(
                        "organisms.MarketplaceExtensionForm.fromDate",
                      )}
                      TextFieldProps={{
                        name,
                        error: Boolean(formState.errors.fromDate),
                        label: t("organisms.MarketplaceExtensionForm.fromDate"),
                        disabled: isFromDateDisabled,
                      }}
                    />
                  </DatePickerContainer>
                </ErrorTooltip>
              );
            }}
          />
          <Controller<MarketplaceExtensionFormData, "fromTime">
            name="fromTime"
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => {
              return (
                <ErrorTooltip title={formState.errors.fromTime?.message}>
                  <DatePickerContainer>
                    <TimePicker
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(formState.errors.fromTime)}
                      disabled={isFromDateDisabled}
                    />
                  </DatePickerContainer>
                </ErrorTooltip>
              );
            }}
          />
          <Controller<MarketplaceExtensionFormData, "toDate">
            name="toDate"
            control={control}
            render={({ field: { value, name, onChange } }) => {
              return (
                <ErrorTooltip title={formState.errors.toDate?.message}>
                  <DatePickerContainer>
                    <SingleDatePicker
                      size="normal"
                      value={value ?? null}
                      onChange={onChange}
                      placeholder={t(
                        "organisms.MarketplaceExtensionForm.toDate",
                      )}
                      disabled={isToDateDisabled}
                      TextFieldProps={{
                        name,
                        error: Boolean(formState.errors.toDate),
                        label: t("organisms.MarketplaceExtensionForm.toDate"),
                        disabled: isToDateDisabled,
                      }}
                    />
                  </DatePickerContainer>
                </ErrorTooltip>
              );
            }}
          />
          <Controller<MarketplaceExtensionFormData, "toTime">
            name="toTime"
            control={control}
            render={({ field: { value, name, onChange, onBlur } }) => {
              return (
                <ErrorTooltip title={formState.errors.toTime?.message}>
                  <DatePickerContainer>
                    <TimePicker
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(formState.errors.toTime)}
                      disabled={isToDateDisabled}
                    />
                  </DatePickerContainer>
                </ErrorTooltip>
              );
            }}
          />
          <DurationField
            size="normal"
            label={t("organisms.MarketplaceExtensionForm.durationLabel")}
            value={duration}
            disabled
          />
        </DatesSection>
      </Fields>
      {!submitRef && (
        <ButtonsContainer>
          <Button type="submit">
            {t("organisms.MarketplaceExtensionForm.save")}
          </Button>
        </ButtonsContainer>
      )}
    </form>
  );
};

const DatesSection = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-end;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const DatePickerContainer = styled.div`
  width: 8rem;
`;

const DurationField = styled(TextField)`
  width: 5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Fields = styled.div`
  display: flex;
`;

const StyledAircraftPicker = styled(AircraftPicker)`
  width: 8rem;
`;

export default MarketplaceExtensionForm;
