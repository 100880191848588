import React, { useEffect, useRef, useState } from "react";
import { navigate, Router, useLocation } from "@reach/router";
import Loadable from "react-loadable";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import LoadingContainer from "@app/components/molecules/LoadingContainer/LoadingContainer";

import { RequestPageVariants } from "@app/components/pages/Requests/Requests";
import { getMyUserInfoAction } from "@app/store/core/userInfo/userInfo.actions";
import { getUserOperatorsAction } from "@app/store/core/userOperators/userOperators.actions";
import {
  selectSelectedOperator,
  selectUserOperators,
} from "@app/store/core/userOperators/userOperators.selectors";
import {
  Actions,
  BusinessEnvironments,
  ChatMessageDto,
  Routes,
} from "@strafos/common";
import { useCanUser } from "@app/hooks/useCanUser";
import { selectUserInfo } from "@app/store/core/userInfo/userInfo.selectors";
import { LocalStorageKeys } from "@app/constants";

import NotFound from "@app/components/pages/NotFound/NotFound";
import { useQuery } from "@tanstack/react-query";
import { api } from "@app/utils/api/api";
import { getAirportNotesSuccessAction } from "@app/store/api/airportNotes/airportNotes.actions";
import useNewMessages from "@app/hooks/useNewMessages";
import { useSnackbar } from "notistack";
import { TripCellDate } from "@app/components/molecules/RequestsGridView/Cells/TripInfoCell";
import styled from "styled-components";
import OfferDocuments from "@app/components/molecules/OfferDocuments/OfferDocuments";

const GATSBY_BUSINESS_ENV = process.env.GATSBY_BUSINESS_ENV;

const Requests = Loadable({
  loader: () => import("@app/components/pages/Requests/Requests"),
  loading: LoadingContainer,
});

const Request = Loadable({
  loader: () => import("@app/components/pages/Request/Request"),
  loading: LoadingContainer,
});

const Login = Loadable({
  loader: () => import("@app/components/pages/Login/Login"),
  loading: LoadingContainer,
});

const LostPassword = Loadable({
  loader: () => import("@app/components/pages/LostPassword/LostPassword"),
  loading: LoadingContainer,
});

const MyTeam = Loadable({
  loader: () => import("@app/components/pages/MyTeam/MyTeam"),
  loading: LoadingContainer,
});

const Fleet = Loadable({
  loader: () => import("@app/components/pages/Fleet/Fleet"),
  loading: LoadingContainer,
});

const AircraftDetail = Loadable({
  loader: () => import("@app/components/pages/AircraftDetail/AircraftDetail"),
  loading: LoadingContainer,
});

const CreateAircraft = Loadable({
  loader: () => import("@app/components/pages/CreateAircraft/CreateAircraft"),
  loading: LoadingContainer,
});

const Airports = Loadable({
  loader: () => import("@app/components/pages/Airports/Airports"),
  loading: LoadingContainer,
});

const AirportDetail = Loadable({
  loader: () => import("@app/components/pages/AirportDetail/AirportDetail"),
  loading: LoadingContainer,
});

const Reports = Loadable({
  loader: () => import("@app/components/pages/Reports/Reports"),
  loading: LoadingContainer,
});

const Schedule = Loadable({
  loader: () => import("@app/components/pages/Schedule/Schedule"),
  loading: () => LoadingContainer,
});

const Settings = Loadable({
  loader: () => import("@app/components/pages/Settings/Settings"),
  loading: LoadingContainer,
});

const Clients = Loadable({
  loader: () => import("@app/components/pages/Clients/Clients"),
  loading: LoadingContainer,
});

const ClientDetail = Loadable({
  loader: () => import("@app/components/pages/ClientDetail/ClientDetail"),
  loading: LoadingContainer,
});

const PrintOffer = Loadable({
  loader: () => import("@app/components/pages/Print/Print"),
  loading: LoadingContainer,
});

const ResetPassword = Loadable({
  loader: () =>
    import(
      "@app/components/pages/PasswordChangeRequested/PasswordChangeRequested"
    ),
  loading: LoadingContainer,
});

const NewPassword = Loadable({
  loader: () => import("@app/components/pages/NewPassword/NewPassword"),
  loading: LoadingContainer,
});

const UserRegistration = Loadable({
  loader: () =>
    import("@app/components/pages/UserRegistration/UserRegistration"),
  loading: LoadingContainer,
});

const LeonCallback = Loadable({
  loader: () => import("@app/components/pages/Callback/LeonCallback"),
  loading: LoadingContainer,
});

const IndexPage = (): JSX.Element => {
  const authToken = localStorage.getItem(LocalStorageKeys.AuthToken);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const canUser = useCanUser();
  const operator = useSelector(selectSelectedOperator);

  const pathnameBlacklist = [
    Routes.Login,
    Routes.LostPassword,
    Routes.PasswordReset,
    Routes.NewPassword,
    Routes.UserRegistration,
  ];

  const isBlacklistedPage = pathnameBlacklist.includes(
    location.pathname as Routes,
  );

  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isTokenVerifying, setIsTokenVerifying] = useState(!isBlacklistedPage);

  useEffect(() => {
    if (!isBlacklistedPage && !authToken) {
      navigate(Routes.Login, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isBlacklistedPage || !authToken) {
      return;
    }

    api
      .validateToken({ token: authToken })
      .then(() => {
        setIsTokenValid(true);
        setIsTokenVerifying(false);
        dispatch(getMyUserInfoAction());
        dispatch(getUserOperatorsAction());
      })
      .catch(() => {
        setIsTokenVerifying(false);
        navigate(Routes.Login, { replace: true });
      });
  }, []);

  const { data: airportNotesData } = useQuery(
    ["airport-notes", operator?.id],
    () => api.getAirportNotes(),
    {
      enabled: canUser(Actions.GetAirport) && !!operator?.id,
    },
  );

  useEffect(() => {
    dispatch(getAirportNotesSuccessAction(airportNotesData?.data || []));
  }, [airportNotesData]);

  useEffect(() => {
    if (!isTokenValid && isBlacklistedPage) setIsTokenVerifying(false);
  }, [isTokenValid, isBlacklistedPage]);

  const userInfo = useSelector(selectUserInfo);

  // @todo Export to hook
  const operators = useSelector(selectUserOperators);

  const canUserDisplayAircraft = canUser(Actions.GetAircraft);
  const canUserDisplayClient = canUser(Actions.GetClient);
  const canUserDisplayOperator = canUser(Actions.GetOperator);
  const canUserDisplayCompany = canUser(Actions.GetCompany);
  const canUserDisplayUsers = canUser(Actions.GetUser);
  const canUserDisplayRequests = canUser(Actions.GetRequest);
  const canUserDisplayOffers = canUser(Actions.GetOffer);
  const canUserDisplaySchedule = canUser(Actions.GetSchedule);

  const canUserDisplayAirports = userInfo?.user_roles && true;

  // show snackBar on new messages from avinode
  const { enqueueSnackbar } = useSnackbar();
  const newMsgs = useNewMessages();
  const seenMsgs = useRef<ChatMessageDto["id"][]>([]);

  useEffect(() => {
    newMsgs?.data.forEach((msg) => {
      if (seenMsgs.current.includes(msg.id) || !msg.external_id) {
        return;
      }

      seenMsgs.current.push(msg.id);
      enqueueSnackbar(
        <div>
          <StyledFirstLine>
            <StyledFirstLineCell>
              {msg.request?.trip_id || "-"}
              {", "}
            </StyledFirstLineCell>
            <StyledTripCellDate tripInfo={msg.request?.trip_info || null} />
            <StyledFirstLineCell>
              {", "}
              {msg.offer_aircraft_registration || "-"}
            </StyledFirstLineCell>
          </StyledFirstLine>
          <StyledChatMsg>
            {msg.content.slice(0, 80)}
            ...
          </StyledChatMsg>
        </div>,
        {
          variant: "info",
          autoHideDuration: 8_000,
        },
      );
    });
  }, [newMsgs?.data]);

  if (!isBlacklistedPage && !operators && !isTokenValid) {
    return <LoadingContainer />;
  }

  if (isTokenVerifying && !isTokenValid) {
    return <LoadingContainer />;
  }

  return (
    <>
      {/* @todo Can we move this to Airport detail page ??? */}
      <Helmet>
        <title>{t("general.pageTitle")}</title>

        <link
          rel="stylesheet"
          href={withPrefix("https://unpkg.com/leaflet@1.7.1/dist/leaflet.css")}
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />

        {GATSBY_BUSINESS_ENV === BusinessEnvironments.Production && (
          <script src="/js/hotjar.js"></script>
        )}
      </Helmet>
      <OfferDocuments />
      <Router>
        {/* @todo Why does Redirect throw ??? */}

        <Reports path={Routes.Index} />
        <Reports path={Routes.Reports} />

        {canUserDisplayRequests && canUserDisplayOffers && (
          <Requests
            variant={RequestPageVariants.Requests}
            path={Routes.Requests}
          />
        )}

        {canUserDisplayRequests && canUserDisplayOffers && (
          <Request path={Routes.Request} />
        )}

        {canUserDisplayRequests && canUserDisplayOffers && (
          <Requests
            variant={RequestPageVariants.Bookings}
            path={Routes.Bookings}
          />
        )}

        {canUserDisplayUsers && <MyTeam path={Routes.MyTeam} />}

        {canUserDisplayAircraft && <Fleet path={Routes.Fleet} />}

        {canUserDisplayAircraft && (
          <CreateAircraft path={Routes.CreateAircraft} />
        )}

        {canUserDisplaySchedule && <Schedule path={Routes.Schedule} />}

        {canUserDisplayAircraft && (
          <AircraftDetail path={Routes.AircraftDetail} />
        )}

        {canUserDisplayAirports && <Airports path={Routes.Airports} />}

        {canUserDisplayAirports && (
          <AirportDetail path={Routes.AirportDetail} />
        )}

        {(canUserDisplayCompany || canUserDisplayOperator) && (
          <Settings path={Routes.Settings} />
        )}

        {canUserDisplayClient && <Clients path={Routes.Clients} />}

        {canUserDisplayClient && <ClientDetail path={Routes.ClientDetail} />}

        <Login path={Routes.Login} />
        <LostPassword path={Routes.LostPassword} />
        <ResetPassword path={Routes.PasswordReset} />
        <NewPassword path={Routes.NewPassword} />

        <PrintOffer path={Routes.Print} />
        <UserRegistration path={Routes.UserRegistration} />

        {<LeonCallback path={Routes.CallbackLeonSuccess} />}

        <NotFound default />
      </Router>
    </>
  );
};
const StyledChatMsg = styled.div`
  margin-top: 0.3rem;
  max-width: 40vw;
  overflow: hidden;
`;

const StyledFirstLineCell = styled.div`
  margin-right: 0.3rem;
`;

const StyledTripCellDate = styled(TripCellDate)`
  color: white;
`;

const StyledFirstLine = styled.div`
  display: flex;
`;

export default IndexPage;
