import { RequestNoteDto } from "@strafos/common";
import React from "react";
import styled from "styled-components";
import RequestNoteAvatar from "@app/components/organisms/RequestNotes/Avatar";
import dayjs from "dayjs";
import { useDateAndTimeFormats } from "@app/hooks/useDateAndTimeFormats";
import Typography from "@app/components/atoms/Typography/Typography";

interface Props {
  note: RequestNoteDto;
  className?: string;
}

export default function RequestNote({ note, className }: Props) {
  const { dateTimeFormat } = useDateAndTimeFormats();

  return (
    <Container className={className}>
      <Header>
        <StyledRequestNoteAvatar author={note.author} />
        <DateText variant="smallDarkText">
          {dayjs(note.created_at).format(dateTimeFormat)}
        </DateText>
      </Header>
      <Typography variant="secondary">{note.text}</Typography>
    </Container>
  );
}
const Container = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;

const DateText = styled(Typography)`
  flex: 1 1 auto;
`;

const StyledRequestNoteAvatar = styled(RequestNoteAvatar)`
  margin-right: 1rem;
`;
