import { useSelector } from 'react-redux'
import { DisplayTimeTypes, ScheduleSource } from '@strafos/common'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { api } from '@app/utils/api/api'
import {
  selectScheduleAircraft,
  selectScheduleData,
  selectScheduleFilters,
} from '@app/store/pages/schedule/scheduleList/schedule.selectors'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useActiveUser } from '@app/hooks/useActiveUser'

export function useGetReservationLegs({
  from,
  to,
  registrationCode,
}: {
  from?: string
  to?: string
  registrationCode?: string
}) {
  const filters = useSelector(selectScheduleFilters)
  const selectedOperator = useSelector(selectSelectedOperator)
  const availableAircraft = useSelector(selectScheduleAircraft)
  const activeUser = useActiveUser()

  const toDate = useMemo(
    () =>
      to
        ? new Date(to)
        : filters.departure_date_end
        ? new Date(filters.departure_date_end)
        : undefined,
    [to, filters.departure_date_end],
  )

  const fromDate = useMemo(
    () =>
      from
        ? new Date(from)
        : filters.arrival_date_start
        ? new Date(filters.arrival_date_start)
        : undefined,
    [from, filters.arrival_date_start],
  )

  return useQuery({
    queryKey: [
      'schedule',
      'reservation',
      'legs',
      ...(availableAircraft?.map((a) => a.id) || []),
      selectedOperator?.id,
      toDate,
      fromDate,
      filters.type,
      filters.trip_id,
      activeUser?.display_time_type,
    ],
    queryFn: () => {
      return api.getReservedLegs({
        aircraft: registrationCode,
        page: 1,
        display_time_type:
          activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
        limit: 200,
        orderBy: 'created_at',
        orderDirection: 'ASC',
        arrival_date_start: fromDate,
        departure_date_end: toDate,
        type: filters.type,
        trip_id: filters.trip_id,
      })
    },
    enabled:
      !!registrationCode ||
      (!!availableAircraft && availableAircraft?.length > 0),
  })
}

export function useGetScheduleWithReservationLegs() {
  const data = useSelector(selectScheduleData)

  const { data: reservationData } = useGetReservationLegs({})

  return useMemo(
    () => [
      ...(data || []),
      ...(reservationData?.data?.schedule?.map((s) => ({
        ...s,
        reserved: true,
        leg_id: s.id,
        source: ScheduleSource.STRAFOS,
      })) || []),
    ],
    [data, reservationData],
  )
}
