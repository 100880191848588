import { LegFormData } from '@strafos/common'
import { Query, QueryKey } from '@tanstack/react-query'
import { api } from '@app/utils/api/api'
import {
  CalculationType,
  DistanceType,
  RadioValue,
} from '@app/components/organisms/LegEditorForm/LegFlightRecalculate/avia.types'
import { getUniqueLegKey } from '@app/hooks/useAviaCalculations'
import {
  DisplayTimeTypes,
  kmsToNauticalMiles,
  AviaCalculationError,
  AviaCalculationsResponseDto,
  AviaFlightTimeResponse,
} from '@strafos/common'

import {
  getArrivalDate,
  getDepartureDate,
} from '@app/components/organisms/LegEditorForm/LegEditorForm.utils'

export function getUniqueErrors(
  errors: AviaCalculationError[],
): AviaCalculationError[] {
  const uniqueErrors: Record<number, AviaCalculationError> = {}

  errors.forEach((error) => {
    uniqueErrors[error.code] = error
  })

  return Object.values(uniqueErrors)
}

export const createAirwayFromQuery = async (
  leg: LegFormData,
  keys: Query[],
  chosenValue: RadioValue,
  timeDisplay: DisplayTimeTypes,
) => {
  const keyString = getUniqueLegKey(leg)

  const correctValues = keys.filter(
    (query) => query.queryKey.includes(keyString),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as Query<any, any, any, QueryKey>[]

  const legCalculation =
    correctValues?.map((query) => query.state.data)[0].data ??
    ({} as AviaCalculationsResponseDto)

  const newAirway = await saveAirway(chosenValue, legCalculation)
  const { flightTime } = legCalculation

  const finalDeparture = getDepartureDate(
    leg.departureDate ?? new Date(),
    leg.departureTime ?? new Date(),
    leg.departureAirport?.timezone ?? '',
    timeDisplay,
  )

  const finalArrival = getArrivalDate(
    leg.departureDate ?? new Date(),
    leg.departureTime ?? new Date(),
    leg.departureAirport?.timezone ?? '',
    leg.arrivalAirport?.timezone ?? '',
    timeDisplay,
    flightTime[chosenValue.value],
  )

  return {
    ...leg,
    extras: {
      ...leg.extras,
      duration_in_minutes: flightTime[chosenValue.value],
      distance_in_nautical_miles: kmsToNauticalMiles(
        legCalculation.distance[
          chosenValue.value !== 'airwayWeatherImpacted'
            ? (chosenValue.value as DistanceType)
            : ('airway' as DistanceType)
        ] ?? 0,
      ),
    },
    departureDate: finalDeparture,
    departureTime: finalDeparture,
    arrivalDate: finalArrival,
    arrivalTime: finalArrival,
    airwayId: newAirway.id,
  }
}

export const saveAirway = async (
  chosenLeg: RadioValue,
  calculation: AviaCalculationsResponseDto,
  legId?: number | null,
) => {
  const distance = kmsToNauticalMiles(
    calculation.distance[
      chosenLeg.value !== 'airwayWeatherImpacted'
        ? (chosenLeg.value as DistanceType)
        : ('airway' as DistanceType)
    ] ?? 0,
  )

  const body = {
    flight_time: calculation?.flightTime[chosenLeg.value as CalculationType],
    departure_time: calculation?.flightTime.departureLocal,
    arrival_time:
      chosenLeg.value === 'airwayWeatherImpacted'
        ? calculation.flightTime.arrivalLocalAirwayWeatherImpacted
        : chosenLeg.value === 'airway'
        ? calculation?.flightTime.arrivalLocalAirway
        : calculation?.flightTime.arrivalLocalGreatCircle,
    airway_path:
      chosenLeg.value === 'greatCircle'
        ? calculation.route.greatCircle
        : calculation.route.airway,
    distance: distance,

    leg_id: legId ?? null,
  }

  return await api.saveAirway(body).then((res) => res.data)
}

export function isCalculationType(value: string): value is CalculationType {
  return ['airway', 'greatCircle', 'airwayWeatherImpacted'].includes(value)
}

export const mapTechStopsToCalculation = (
  calculation: AviaCalculationsResponseDto,
): CalculationType[] => {
  if (!calculation.errors) {
    return []
  }

  const techStopErrors = calculation.errors.filter(
    (err) => err.code === 1102 && err,
  )
  const {
    flightTime: { airway, airwayWeatherImpacted, greatCircle },
  } = calculation
  const needsTechStop: CalculationType[] = []

  if (!airway) needsTechStop.push('airway')

  if (!greatCircle) needsTechStop.push('greatCircle')

  if (!airwayWeatherImpacted && needsTechStop.length < techStopErrors.length)
    needsTechStop.push('airwayWeatherImpacted')

  return needsTechStop
}

export const getTechStopAirports = async (techStops: string[]) => {
  const techStopsQuery = techStops.join(',')
  return await api
    .getTechStopAirports({
      tech_stops: techStopsQuery,
    })
    .then((res) => res.data)
}

export const applyLegCalculations = async (
  value: RadioValue,
  leg: LegFormData,
  aviaResponse: AviaCalculationsResponseDto,
  dateFormat: DisplayTimeTypes,
) => {
  const selectedFlightTime = [
    'airwayWeatherImpacted',
    'airway',
    'greatCircle',
  ].includes(value.value)
    ? (aviaResponse.flightTime[
        value.value as keyof AviaFlightTimeResponse
      ] as number)
    : 0

  const airway = await saveAirway(
    value,
    aviaResponse,
    !!leg?.id ? parseInt(leg.id) : null,
  )

  const departureDate = getDepartureDate(
    leg.departureDate ?? new Date(),
    leg.departureTime ?? new Date(),
    leg.departureAirport?.timezone ?? '',
    dateFormat,
  )

  const arrivalDate = getArrivalDate(
    leg.departureDate ?? new Date(),
    leg.departureTime ?? new Date(),
    leg.departureAirport?.timezone ?? '',
    leg.arrivalAirport?.timezone ?? '',
    dateFormat,
    selectedFlightTime,
  )

  return {
    ...leg,
    extras: {
      ...leg.extras,
      duration_in_minutes: selectedFlightTime,
      distance_in_nautical_miles: kmsToNauticalMiles(
        aviaResponse.distance[
          value.value !== 'airwayWeatherImpacted'
            ? (value.value as DistanceType)
            : ('airway' as DistanceType)
        ] ?? 0,
      ),
    },
    departureDate,
    departureTime: departureDate,
    arrivalDate,
    arrivalTime: arrivalDate,
    distance:
      aviaResponse.distance[
        value.value === 'greatCircle' ? 'greatCircle' : 'airway'
      ],
    airwayId: airway.id,
  }
}

export const getSubmitButtonText = (values: RadioValue[]) => {
  if (values.length === 1 && values[0].type === 'techStop')
    return 'organisms.AviaPagesMenu.recalculateFlightTime'

  return 'organisms.AviaPagesMenu.saveCalculatedTime'
}

export const getInitialValue = (
  aviaResponse: AviaCalculationsResponseDto,
  order?: number,
): RadioValue[] => {
  if (aviaResponse.flightTime.airwayWeatherImpacted)
    return [
      {
        type: 'leg',
        value: 'airwayWeatherImpacted',
        order: order ?? 1,
      },
    ] as RadioValue[]
  if (aviaResponse.flightTime.airway)
    return [{ type: 'leg', value: 'airway', order: order ?? 1 }] as RadioValue[]
  if (aviaResponse.flightTime.greatCircle)
    return [
      { type: 'leg', value: 'greatCircle', order: order ?? 1 },
    ] as RadioValue[]
  return [
    {
      type: 'techStop',
      value: aviaResponse.techStops?.[0] ?? '',
      order: order ?? 1,
    },
  ] as RadioValue[]
}

export const isSubmitDisabled = (values: RadioValue[]) => {
  return (
    values.length === 0 ||
    values[0].value === '' ||
    (values.length === 2 && values[1].type === 'techStop')
  )
}
