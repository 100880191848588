import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import CustomerSelectorInitialScreen from "@app/components/organisms/CustomerSelector/CustomerSelectorInitialState/CustomerSelectorInitialState";
import CustomerSelectorSelectedState from "@app/components/organisms/CustomerSelector/CustomerSelectorSelectedState/CustomerSelectorSelectedState";
import CreateClientDialog from "@app/components/organisms/CreateClientDialog/CreateClientDialog";
import CreateContactPersonDialog from "@app/components/organisms/CreateContactPersonDialog/CreateContactPersonDialog";

import { api } from "@app/utils/api/api";

import { useCanUser } from "@app/hooks/useCanUser";

import SelectCustomerForm, {
  SelectCustomerFormOutputData,
} from "@app/components/organisms/CustomerSelector/CustomerSelectorCreationState/CustomerSelectorCreationState";

import {
  Actions,
  CreateClientDto,
  CreateContactPersonDto,
  ClientDetailDto,
} from "@strafos/common";

interface CustomerSelectorProps {
  customerId?: number | null;
  contactPersonId?: number | null;
  onSubmit: (params: { customerId: number; contactPersonId: number }) => void;
  onCreateNewClient: (client: CreateClientDto) => void;
  isLoading: boolean;
  isPostClientLoading: boolean;
  isPostContactPersonLoading: boolean;
  className?: string;
  disabled?: boolean;
  onCreateNewContactPerson: (
    client: Omit<CreateContactPersonDto, "client_id">,
  ) => void;
}

enum SelectorState {
  Initial = "Initial",
  Loading = "Loading",
  Selection = "Selection",
  Creation = "Creation",
  Complete = "Complete",
}

const SEARCH_REQUEST_DEBOUNCE_TIME = 500;

const CustomerSelector = ({
  customerId,
  contactPersonId,
  onSubmit,
  onCreateNewClient,
  onCreateNewContactPerson,
  isLoading,
  isPostClientLoading,
  isPostContactPersonLoading,
  className,
  disabled,
}: CustomerSelectorProps): JSX.Element => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();

  const canUserCreateClient = canUser(Actions.CreateClient);

  const [customerSearch, setCustomerSearch] = useState("");
  const [customers, setCustomers] = useState<ClientDetailDto[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<ClientDetailDto>();
  const [isNewClientDialogOpen, setIsNewClientDialogOpen] = useState(false);

  const selectedContactPerson = useMemo(() => {
    if (!selectedCustomer) {
      return;
    }

    return selectedCustomer.contact_persons.find(
      (contactPerson) => contactPerson.id === contactPersonId,
    );
  }, [
    contactPersonId,
    selectedCustomer?.id,
    selectedCustomer?.contact_persons?.length,
  ]);

  const calculatedSelectorState = useMemo(() => {
    const isAnyRequestInProgress =
      isLoading ||
      (customerId && customerId !== selectedCustomer?.id) ||
      (contactPersonId && contactPersonId !== selectedContactPerson?.id);

    if (isAnyRequestInProgress) {
      return SelectorState.Loading;
    }

    if (
      customerId === selectedCustomer?.id &&
      contactPersonId === selectedContactPerson?.id
    ) {
      return SelectorState.Complete;
    }

    return SelectorState.Initial;
  }, [
    isLoading,
    customerId,
    contactPersonId,
    selectedContactPerson?.id,
    selectedCustomer?.id,
  ]);

  const [selectorState, setSelectorState] = useState<SelectorState>(
    calculatedSelectorState,
  );

  const defaultFormValues = useMemo(() => {
    if (!selectedCustomer) {
      return;
    }

    if (!selectedContactPerson) {
      return {
        customer: {
          id: selectedCustomer.id,
          label: selectedCustomer.company_name,
        },
      };
    }

    return {
      customer: {
        id: selectedCustomer.id,
        label: selectedCustomer.company_name,
      },
      contactPerson: {
        id: selectedContactPerson?.id,
        label: selectedContactPerson?.name,
      },
    };
  }, [selectedCustomer, customerId]);

  const onSelectionFormSubmit = (values: SelectCustomerFormOutputData) => {
    onSubmit(values);
  };

  const onSelectionFormClose = () => {
    setSelectorState(calculatedSelectorState);
  };

  const onCustomerTextInputChange = (searchString: string) => {
    setCustomerSearch(searchString);
  };

  const searchForClients = (searchString?: string) => {
    api
      .listClients({
        q: searchString,
      })
      .then(({ data }) => {
        setCustomers(data.data);
      })
      .catch(() => {
        enqueueSnackbar(t("organisms.CustomerSelector.defaultErrorMessage"), {
          variant: "error",
        });
      });
  };

  useDebounce(
    () => {
      searchForClients(customerSearch);
    },
    SEARCH_REQUEST_DEBOUNCE_TIME,
    [customerSearch],
  );

  useEffect(() => {
    setSelectorState(calculatedSelectorState);
  }, [calculatedSelectorState]);

  useEffect(() => {
    const locallySelectedCustomer = customers?.find(
      (customer) => customer.id === customerId,
    );

    if (locallySelectedCustomer) {
      return setSelectedCustomer(locallySelectedCustomer);
    }

    if (customerId) {
      api
        .getClient(customerId)
        .then(({ data }) => {
          setSelectedCustomer(data);
          setCustomers((customers) => [...(customers || []), data]);
        })
        .catch(() => {
          enqueueSnackbar(t("organisms.CustomerSelector.defaultErrorMessage"), {
            variant: "error",
          });
        });
    }
  }, [customerId]);

  useEffect(() => {
    const isContactPersonSelected =
      selectedContactPerson?.id === contactPersonId;

    if (customerId && contactPersonId && !isContactPersonSelected) {
      api
        .getClient(customerId)
        .then(({ data }) => {
          setSelectedCustomer(data);
        })
        .catch(() => {
          enqueueSnackbar(t("organisms.CustomerSelector.defaultErrorMessage"), {
            variant: "error",
          });
        });
    }
  }, [contactPersonId]);

  switch (selectorState) {
    case SelectorState.Loading:
      return <StyledLoadingSpinner loading size={40} />;

    case SelectorState.Selection:
      return (
        <SelectCustomerForm
          className={className}
          customers={customers}
          onSubmit={onSelectionFormSubmit}
          onCloseClick={onSelectionFormClose}
          onCustomerTextInputChange={onCustomerTextInputChange}
          defaultValues={defaultFormValues}
          disabled={disabled}
        />
      );

    case SelectorState.Complete:
      return (
        <CustomerSelectorSelectedState
          className={className}
          customer={selectedCustomer}
          contactPerson={selectedContactPerson}
          disabled={disabled}
          onChangeCustomerClick={() =>
            setSelectorState(SelectorState.Selection)
          }
        />
      );

    case SelectorState.Initial:
    default:
      return (
        <>
          <CustomerSelectorInitialScreen
            className={className}
            disabled={disabled}
            onCreateNewClick={
              canUserCreateClient
                ? () => setIsNewClientDialogOpen(true)
                : undefined
            }
            onSelectExistingClick={() =>
              setSelectorState(SelectorState.Selection)
            }
          />
          <CreateClientDialog
            isOpen={isNewClientDialogOpen && !selectedCustomer}
            onClose={() => setIsNewClientDialogOpen(false)}
            onSubmit={onCreateNewClient}
            isLoading={isPostClientLoading}
          />
          <CreateContactPersonDialog
            isOpen={isNewClientDialogOpen && !!customerId}
            onClose={() => setIsNewClientDialogOpen(false)}
            onSubmit={onCreateNewContactPerson}
            isLoading={isPostContactPersonLoading}
          />
        </>
      );
  }
};

const StyledLoadingSpinner = styled(LoadingSpinner)`
  align-self: center;
  margin-top: 2rem;
`;

export default CustomerSelector;
