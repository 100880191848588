import { CellProps } from "react-table";
import { PartialRequestDto } from "@strafos/common";
import Box from "@material-ui/core/Box";
import Typography from "@app/components/atoms/Typography/Typography";
import Price from "@app/components/atoms/Price/Price";
import React from "react";
import { Profit } from "@app/components/molecules/RequestsGridView/Cells";

export const SummaryCell = ({
  cell,
}: CellProps<PartialRequestDto, undefined>) => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography variant="content">
        {cell.row.original.aircraft_code}
      </Typography>
      <Box display="flex" alignItems="center">
        <Profit
          type={cell.row.original.total_profit >= 0 ? "profit" : "loss"}
          size="small"
          value={cell.row.original.total_profit}
        />
        <Price size="small" value={cell.row.original.final_price} />
      </Box>
    </Box>
  );
};
