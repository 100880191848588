import Button from '@app/components/atoms/Button/Button'
import styled from 'styled-components'
import Dialog from '@app/components/atoms/Dialog/Dialog'
import React from 'react'
import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'
import { useTranslation } from 'react-i18next'
import { Controller, FieldError, useForm } from 'react-hook-form'
import AircraftPicker, {
  AircraftPickerVariants,
} from '@app/components/atoms/AircraftPicker/AircraftPicker'
import { AircraftDetailDto } from '@strafos/common/dist/dtos'
import useChangeAircraftValidationSchema from '@app/store/ui/changeAircraftDialog/useChangeAircraftValidationSchema'
import { useYupValidationResolver } from '@app/hooks/useYupValidationResolver'
import Typography from '@material-ui/core/Typography'

export type ChangeAircraftDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: (aircraft: AircraftDetailDto) => void
}

export interface ChangeAircraftFormData {
  aircraft: AircraftDetailDto | null
}

const defaultValues = {
  aircraft: null,
}

const ChangeAircraftDialog = ({
  open,
  onClose,
  onSubmit,
}: ChangeAircraftDialogProps) => {
  const { t } = useTranslation()
  const validationSchema = useChangeAircraftValidationSchema()
  const validationResolver = useYupValidationResolver(validationSchema)

  const { control, formState, handleSubmit } = useForm<ChangeAircraftFormData>({
    resolver: validationResolver,
    defaultValues,
  })

  const { errors } = formState

  return (
    <StyledDialog
      classes={{ paper: 'Dialog__paper' }}
      open={open}
      onClose={onClose}
      disableEnforceFocus
    >
      <StyledForm
        noValidate
        onSubmit={handleSubmit((values) => onSubmit(values.aircraft!))}
      >
        <Typography>Select aircraft to move bookings to</Typography>
        <InputFields>
          <Controller<ChangeAircraftFormData, 'aircraft'>
            name="aircraft"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <ErrorTooltip title={(errors.aircraft as FieldError)?.message}>
                  <StyledAircraftPicker
                    variant={AircraftPickerVariants.Condensed}
                    value={value ?? null}
                    onChange={(event, value) => onChange(value)}
                    onBlur={onBlur}
                    error={!!errors.aircraft}
                    placeholder={t(
                      'organisms.AirportFeeForm.aircraftPlaceholder',
                    )}
                    label={t('organisms.AirportFeeForm.aircraft')}
                    fallbackKey={'base_airport'}
                  />
                </ErrorTooltip>
              )
            }}
          />
        </InputFields>
        <SubmitButton
          disabled={false}
          size="normal"
          type="submit"
          data-testid="CopyAircraftDialog__submit-button"
        >
          {t('organisms.CopyAircraftDialog.submit')}
        </SubmitButton>
      </StyledForm>
    </StyledDialog>
  )
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`

const InputFields = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 1rem;
`

const StyledDialog = styled(Dialog)`
  .Dialog__paper {
    padding: 0;
    min-width: 50rem;
    min-height: 20rem;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
`

const SubmitButton = styled(Button)`
  width: fit-content;
  align-self: end;
  margin-top: 1rem;
`

const StyledAircraftPicker = styled(AircraftPicker)`
  width: 8rem;
  box-sizing: border-box;
  margin-right: 0.5rem;
  height: 10rem;

  // @todo Use custom class name
  &.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
  }
`

export default ChangeAircraftDialog
