import React from 'react'
import styled from 'styled-components'
import { Controller, useForm, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'
import TextField from '@app/components/atoms/TextField/TextField'
import Button from '@app/components/atoms/Button/Button'
import CountryPicker from '@app/components/atoms/CountryPicker/CountryPicker'

import useClientFormValidationSchema from '@app/components/organisms/ClientForm/useClientFormValidationSchema'
import { useYupValidationResolver } from '@app/hooks/useYupValidationResolver'

import {
  transformClientToFormData,
  transformFormDataToPartialClientDetailDto,
} from '@app/components/organisms/ClientForm/ClientForm.transformer'

import { ClientDetailDto, CreateClientDto, CountryDto } from '@strafos/common'
import { MenuItem, Select } from '@material-ui/core'

import { colors } from '@app/components/organisms/ClientForm/types'

export interface ClientFormData {
  companyName: string
  street: string
  city: string
  zipCode: string
  country: CountryDto | null
  color: string | null
  note: string | null
}

interface ClientFormProps {
  onSubmit: (data: Omit<CreateClientDto, 'cancellation_fee_text'>) => void
  client?: ClientDetailDto
  isLoading?: boolean
  className?: string
  disabled?: boolean
}

const ClientForm = ({
  client,
  onSubmit,
  isLoading,
  className,
  disabled,
}: ClientFormProps) => {
  const { t } = useTranslation()

  const validationSchema = useClientFormValidationSchema()

  const validationResolver = useYupValidationResolver(validationSchema)

  const defaultValues = transformClientToFormData(client ?? null)

  const { handleSubmit, control, formState } = useForm<ClientFormData>({
    resolver: validationResolver,
    defaultValues,
  })

  const onSubmitClick = (data: ClientFormData) => {
    onSubmit(transformFormDataToPartialClientDetailDto(data))
  }

  return (
    <form onSubmit={handleSubmit(onSubmitClick)} className={className}>
      <Controller<ClientFormData, 'companyName'>
        name="companyName"
        control={control}
        render={({ field: { value, name, onChange, onBlur } }) => {
          return (
            <ErrorTooltip title={formState.errors.companyName?.message}>
              <StyledTextField
                size="normal"
                label={t('organisms.ClientForm.companyNameLabel')}
                placeholder={t('organisms.ClientForm.companyNamePlaceholder')}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(formState.errors.companyName)}
                disabled={disabled}
                inputProps={{
                  'data-testid': 'ClientForm__companyName-input',
                }}
                fullWidth
              />
            </ErrorTooltip>
          )
        }}
      />
      <Controller<ClientFormData, 'street'>
        name="street"
        control={control}
        render={({ field: { value, name, onChange, onBlur } }) => {
          return (
            <ErrorTooltip title={formState.errors.street?.message}>
              <StyledTextField
                size="normal"
                label={t('organisms.ClientForm.streetLabel')}
                placeholder={t('organisms.ClientForm.streetPlaceholder')}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(formState.errors.street)}
                disabled={disabled}
                inputProps={{
                  'data-testid': 'ClientForm__street-input',
                }}
                fullWidth
              />
            </ErrorTooltip>
          )
        }}
      />
      <Controller<ClientFormData, 'city'>
        name="city"
        control={control}
        render={({ field: { value, name, onChange, onBlur } }) => {
          return (
            <ErrorTooltip title={formState.errors.city?.message}>
              <StyledTextField
                size="normal"
                label={t('organisms.ClientForm.cityLabel')}
                placeholder={t('organisms.ClientForm.cityPlaceholder')}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(formState.errors.city)}
                disabled={disabled}
                inputProps={{
                  'data-testid': 'ClientForm__city-input',
                }}
                fullWidth
              />
            </ErrorTooltip>
          )
        }}
      />
      <Controller<ClientFormData, 'zipCode'>
        name="zipCode"
        control={control}
        render={({ field: { value, name, onChange, onBlur } }) => {
          return (
            <ErrorTooltip title={formState.errors.zipCode?.message}>
              <StyledTextField
                size="normal"
                label={t('organisms.ClientForm.zipCodeLabel')}
                placeholder={t('organisms.ClientForm.zipCodePlaceholder')}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(formState.errors.zipCode)}
                disabled={disabled}
                inputProps={{
                  'data-testid': 'ClientForm__zipCode-input',
                }}
                fullWidth
              />
            </ErrorTooltip>
          )
        }}
      />
      <Controller<ClientFormData, 'country'>
        name="country"
        control={control}
        render={({ field: { value, name, onChange, onBlur } }) => {
          return (
            <ErrorTooltip
              title={(formState.errors.country as FieldError)?.message}
            >
              <StyledCountryPicker
                size="normal"
                label={t('organisms.ClientForm.countryLabel')}
                name={name}
                value={value}
                onChange={(event, nextValue) => onChange(nextValue)}
                onBlur={onBlur}
                error={Boolean(formState.errors.country)}
                placeholder={t('organisms.ClientForm.countryPlaceholder')}
                disabled={disabled}
                inputProps={{
                  'data-testid': 'ClientForm__country-input',
                }}
                fullWidth
              />
            </ErrorTooltip>
          )
        }}
      />
      <Controller<ClientFormData, 'note'>
        name="note"
        control={control}
        render={({ field: { value, name, onChange, onBlur } }) => {
          return (
            <ErrorTooltip
              title={(formState.errors.country as FieldError)?.message}
            >
              <StyledTextField
                size="normal"
                label={t('organisms.ClientForm.note')}
                placeholder={t('organisms.ClientForm.notePlaceholder')}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                multiline
                rows={3}
                onBlur={onBlur}
                error={Boolean(formState.errors.note)}
                disabled={disabled}
                inputProps={{
                  'data-testid': 'ClientForm__note-input',
                }}
                fullWidth
              />
            </ErrorTooltip>
          )
        }}
      />
      <Controller<ClientFormData, 'color'>
        name="color"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <ErrorTooltip
              title={(formState.errors.country as FieldError)?.message}
            >
              <StyledColorPicker
                variant="outlined"
                margin="dense"
                label={t('organisms.ClientForm.color')}
                value={value}
                onChange={onChange}
                renderValue={(selected) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        backgroundColor: selected as string,
                        width: 24,
                        height: 18,
                        borderRadius: '20%',
                        marginRight: 8,
                      }}
                    />
                  </div>
                )}
              >
                {colors.map((color) => (
                  <MenuItem key={color.value} value={color.value}>
                    <div
                      style={{
                        backgroundColor: color.value,
                        width: 24,
                        height: 18,
                        borderRadius: '20%',
                        border: '1px solid black',
                        marginRight: 8,
                      }}
                    />
                  </MenuItem>
                ))}
              </StyledColorPicker>
            </ErrorTooltip>
          )
        }}
      />
      <Button
        type="submit"
        disabled={!formState.isDirty || disabled}
        loading={isLoading}
      >
        {t('organisms.ClientForm.submit')}
      </Button>
    </form>
  )
}

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  display: inline-block;
  max-width: 15rem;
`

const StyledCountryPicker = styled(CountryPicker)`
  margin-bottom: 1rem;
  display: inline-block;
  max-width: 15rem;
`

const StyledColorPicker = styled(Select)`
  margin-bottom: 1rem;
`

export default ClientForm
