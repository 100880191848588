import { LegDetailDto } from "@strafos/common";
import { OfferStatuses } from "@strafos/common";
import { getLegColor } from "@app/utils/stylingUtils";
import { theme } from "@app/theme";
import { LatLngTuple } from "leaflet";
import { LegData } from "@app/components/organisms/RequestMap/shared";

export function getMapLegColor(
  leg: LegDetailDto | undefined,
  offerStatus: OfferStatuses,
  defaultColor: string = "#DDDDDD",
) {
  if (leg) {
    return getLegColor(theme, leg.type, offerStatus);
  }
  return defaultColor;
}

export const SELECTORS = {
  FLIGHT_LINE: "straf-main-flight-path",
  TEXT_LAYER: "straf-main-text-layer",
};

export const DATA_LEG_ID_ATTR = "data-lid";

export const getSelectorForLine = (line: number) =>
  `${SELECTORS.FLIGHT_LINE}-${line}`;
export const getSelectorForText = (line: number) =>
  `${SELECTORS.TEXT_LAYER}-${line}`;

export const reorderFromTo = (from: LatLngTuple, to: LatLngTuple) =>
  to[1] < from[1] ? [to, from] : [from, to];

export const getLegDirection = (
  from: LatLngTuple,
  to: LatLngTuple,
): LegData["direction"] => (to[1] < from[1] ? "left" : "right");

export const getZoom = (distances: number[]) => {
  const longestDistance = Math.max(...distances);

  if (longestDistance < 300) return 7;
  if (longestDistance < 550) return 6;
  if (longestDistance < 1100) return 5;
  if (longestDistance < 2200) return 4;
  if (longestDistance < 5000) return 3;
  if (longestDistance < 10000) return 2;
  return 1;
};
