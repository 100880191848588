import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { ScheduleItemVariants } from "@app/components/molecules/Schedule/ScheduleItem";

import {
  OfferStatuses,
  BaseLegDetailDto,
  OfferProfitAndPrice,
  ScheduleDetailDto,
} from "@strafos/common";
import { SwimlaneSchedule } from "@app/components/molecules/Schedule/SwimlaneSchedule";

dayjs.extend(utc);

interface SwimlaneProps {
  schedule: BaseLegDetailDto[] | ScheduleDetailDto[];
  viewStart: Date;
  viewEnd: Date;
  breakpoints: Date[];
  variant: ScheduleItemVariants;
  finalPriceAndTotalProfit: OfferProfitAndPrice[];
  offerStatus?: OfferStatuses;
  onConvertToMarketingLeg?: (
    id: number,
    dates: { departureDate?: Date; arrivalDate?: Date },
  ) => void;
  onEditMarketingLeg?: (id: number) => void;
  onRemoveMarketingLeg?: (id: number) => void;
  onEditOutage?: (id: number) => void;
  onRemoveOutage?: (id: number) => void;
  onMarkLegAsRemoved?: (id: number) => void;
  onNavigateToRequest?: (id: number) => void;
  className?: string;
  divisionType?: DivisionType;
}

const Swimlane = ({
  viewStart,
  viewEnd,
  breakpoints,
  divisionType = DivisionType.soft,
  className,
  ...props
}: SwimlaneProps) => {
  const theme = useTheme();

  const viewStartDayJs = dayjs.utc(viewStart);
  const viewEndDayJs = dayjs.utc(viewEnd);

  const visibleDurationInMilliseconds = viewEndDayJs.diff(dayjs.utc(viewStart));

  const dayBreakpoints = useMemo(() => {
    const dayBreakpoints = [];
    let currentBreakpoint = dayjs.utc(viewStart).startOf("day");
    dayBreakpoints.push(currentBreakpoint.toDate());
    while (currentBreakpoint.isBefore(dayjs.utc(viewEnd))) {
      currentBreakpoint = currentBreakpoint.add(1, "day");
      dayBreakpoints.push(currentBreakpoint.toDate());
    }

    return dayBreakpoints;
  }, [viewStart, viewEnd]);

  const dayWidth =
    (dayjs.utc(dayBreakpoints[1]).diff(viewStartDayJs) /
      visibleDurationInMilliseconds) *
      100 -
    (dayjs.utc(dayBreakpoints[0]).diff(viewStartDayJs) /
      visibleDurationInMilliseconds) *
      100;

  const isWeekend = (date: Date) => {
    return dayjs(date).day() === 0 || dayjs(date).day() === 6;
  };

  const containerBackground = useMemo(
    () =>
      dayjs.utc(breakpoints[0]).day() === 6 &&
      dayjs.utc(breakpoints[0]).hour() === 0
        ? "#fff"
        : isWeekend(breakpoints[0]) ||
            (dayjs.utc(breakpoints[0]).day() === 0 &&
              dayjs.utc(breakpoints[0]).hour() === 0) ||
            (dayjs.utc(breakpoints[0]).day() === 1 &&
              dayjs.utc(breakpoints[0]).hour() === 0)
          ? "#eee"
          : "#fff",
    [breakpoints[0]],
  );

  return (
    <Container
      className={className}
      $divisionType={divisionType}
      $background={containerBackground}
    >
      {dayBreakpoints.map((bp) => {
        const leftPositionPercentage =
          (dayjs.utc(bp).diff(viewStartDayJs) / visibleDurationInMilliseconds) *
          100;

        if (leftPositionPercentage < 0) return null;

        return (
          <DayVerticalLine
            $width={`${dayWidth}%`}
            $background={isWeekend(bp) ? "#eee" : "#fff"}
            key={bp.getTime()}
            $left={`${leftPositionPercentage}%`}
            $color={theme.palette.grey[200]}
          />
        );
      })}
      {breakpoints.map((breakpoint) => {
        const leftPositionPercentage =
          (dayjs.utc(breakpoint).diff(viewStartDayJs) /
            visibleDurationInMilliseconds) *
          100;

        return (
          <VerticalLine
            key={breakpoint.getTime()}
            $left={`${leftPositionPercentage}%`}
            style={{
              display: !!dayBreakpoints.find((bp) =>
                dayjs.utc(bp).isSame(breakpoint),
              )
                ? "none"
                : "block",
            }}
          />
        );
      })}

      <SwimlaneSchedule
        viewStartDayJs={viewStartDayJs}
        viewEndDayJs={viewEndDayJs}
        viewStart={viewStart}
        viewEnd={viewEnd}
        visibleDurationInMilliseconds={visibleDurationInMilliseconds}
        {...props}
      />
    </Container>
  );
};

export enum DivisionType {
  soft = "soft",
  hard = "hard",
}

const Container = styled.div<{
  $divisionType: DivisionType;
  $background?: string;
}>`
  position: relative;
  width: 100%;
  border-bottom: 1px solid
    ${({ theme, $divisionType }) =>
      theme.palette.grey[$divisionType === DivisionType.soft ? 100 : 200]};
  background-color: ${({ $background }) =>
    $background ? $background : "transparent"};
`;

const VerticalLine = styled.div<{
  $left: string;
  $color?: string;
}>`
  position: absolute;
  height: 100%;
  border-left: 1px solid
    ${({ theme, $color }) => ($color ? $color : theme.palette.grey[100])};
  left: ${({ $left }) => $left};
`;

const DayVerticalLine = styled(VerticalLine)<{
  $width: string;
  $background: string;
}>`
  background-color: ${({ $background }) => $background};
  width: ${({ $width }) => $width};
`;

export default Swimlane;
