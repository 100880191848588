import React, { useRef, useState } from "react";
import styled from "styled-components";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";

import Select from "@app/components/atoms/Select/Select";
import Button from "@app/components/atoms/Button/Button";
import UserProfileForm from "@app/components/organisms/UserPanel/UserProfileForm/UserProfileForm";
import PasswordChangeForm from "@app/components/organisms/UserPanel/PasswordChangeForm/PasswordChangeForm";
import { TextFieldInputLabel } from "@app/components/atoms/TextField/TextField";
import Typography from "@app/components/atoms/Typography/Typography";
import UserSettingsForm from "@app/components/organisms/UserPanel/UserSettingsForm/UserSettingsForm";
import { ClickAwayListener } from "@material-ui/core";

import IntegrationsTab, {
  IntegrationSettings,
  IntegrationsTabProps,
} from "@app/components/organisms/UserPanel/IntegrationsTab/IntegrationsTab";

import Tabs, {
  CLASS_NAME_TAB_SELECTED,
  Tab,
  TabPanel,
} from "@app/components/atoms/Tabs/Tabs";

import { getUserRoleForOperator, UserDto, OperatorDto } from "@strafos/common";
import { useTranslation } from "react-i18next";

import {
  UpdatePasswordDto,
  UpdateUserDisplaySettingsDto,
  UpdateUserDto,
} from "@app/utils/api/types";
import { createPortal } from "react-dom";

interface UserProfilePanelProps {
  user: UserDto;
  operators: OperatorDto[];
  activeOperator: OperatorDto;
  onLogout: () => void;
  onOperatorChange: (nextOperator: OperatorDto) => void;
  onProfileChange: (partialUser: UpdateUserDto) => void;
  isUserUpdateLoading: boolean;
  isPasswordUpdateLoading: boolean;
  onPasswordChange: (updatePasswordDto: UpdatePasswordDto) => Promise<void>;
  onSettingsChange: (updateSettingsDto: UpdateUserDisplaySettingsDto) => void;
  onClose: () => void;
  className?: string;

  userData: UserDto | null; // @todo Make non nullable
  onIntegrationsSettingsUpdate: IntegrationsTabProps["onIntegrationSettingsUpdate"];
}

const UserPanel = ({
  user,
  operators,
  activeOperator,
  onLogout,
  onOperatorChange,
  onProfileChange,
  isUserUpdateLoading,
  isPasswordUpdateLoading,
  onPasswordChange,
  onSettingsChange,
  className,
  onClose,

  userData,
  onIntegrationsSettingsUpdate,

  ...props
}: UserProfilePanelProps): JSX.Element => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // @todo create useActiveUser hook using React.createContext
  const activeUserRole = userData
    ? getUserRoleForOperator(userData.user_roles, activeOperator.id)
    : null;

  const integrationsSettings: IntegrationSettings = {
    avinodeUsername: activeUserRole?.avinode_username ?? "",
  };

  return createPortal(
    <ClickAwayListener onClickAway={onClose}>
      <StyledGrid
        ref={containerRef}
        container
        wrap="nowrap"
        className={className}
      >
        <SidebarBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box width={1}>
            <Box display="flex" flexWrap="nowrap" mb={1.4}>
              <Box>
                <StyledAvatar>
                  {user.first_name.charAt(0)}
                  {user.last_name.charAt(0)}
                </StyledAvatar>
                <Box my={2} />
                <Box display="flex" flexDirection="column">
                  <Typography variant="userTitle">{user.first_name}</Typography>
                  <Typography variant="userTitle">{user.last_name}</Typography>
                </Box>
              </Box>
              <StyledTabs
                orientation="vertical"
                value={value}
                onChange={(event, nextValue) => {
                  setValue(nextValue);
                }}
                {...props}
              >
                <StyledTab
                  vertical
                  label={t("organisms.UserPanel.profileTabTitle")}
                />
                <StyledTab
                  vertical
                  label={t("organisms.UserPanel.settingsTabTitle")}
                />
                <StyledTab
                  vertical
                  label={t("organisms.UserPanel.passwordTabTitle")}
                />
                <StyledTab
                  vertical
                  label={t("organisms.UserPanel.integrationsTabTitle")}
                />
              </StyledTabs>
            </Box>
            <StyledSelect<OperatorDto, false, false, false>
              label={t("organisms.UserPanel.operatorLabel")}
              value={activeOperator}
              options={operators}
              getOptionLabel={(option) => option.name}
              disabled={operators.length === 1}
              onChange={(event, operator) => {
                event.stopPropagation();
                operator && onOperatorChange(operator);
              }}
            />
          </Box>
          <StyledButton inverted onClick={onLogout}>
            {t("organisms.UserPanel.logout")}
          </StyledButton>
        </SidebarBox>
        <Box display="flex" flex={1}>
          <Box mx={2} width={1}>
            <StyledTabPanel value={value} index={0}>
              <UserProfileForm
                user={user}
                onSubmit={onProfileChange}
                isLoading={isUserUpdateLoading}
              />
            </StyledTabPanel>
            <StyledTabPanel value={value} index={1}>
              <UserSettingsForm
                user={user}
                onSubmit={onSettingsChange}
                isLoading={isUserUpdateLoading}
              />
            </StyledTabPanel>
            <StyledTabPanel value={value} index={2}>
              <PasswordChangeForm
                onSubmit={onPasswordChange}
                isLoading={isPasswordUpdateLoading}
              />
            </StyledTabPanel>
            <StyledTabPanel value={value} index={3}>
              <IntegrationsTab
                integrationSettings={integrationsSettings}
                onIntegrationSettingsUpdate={onIntegrationsSettingsUpdate}
              />
            </StyledTabPanel>
          </Box>
        </Box>
        <CloseIconContainer>
          <StyledCloseIcon fontSize="medium" onClick={onClose} />
        </CloseIconContainer>
      </StyledGrid>
    </ClickAwayListener>,
    document.body,
  );
};

const StyledGrid = styled(Grid)`
  position: relative;

  max-width: 60rem;
  min-height: 30rem;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  background: ${({ theme }) => theme.palette.common.white};
  z-index: 2;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 0;
`;
const StyledCloseIcon = styled(CloseIcon)`
  margin-top: 1rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

const SidebarBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.whiteBlue};
  padding-top: 2rem;
  padding-left: 3rem;
  width: 20rem;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 2.5rem;
  width: 8rem;
  height: 8rem;
  margin-bottom: 2rem;
`;

const StyledSelect = styled(Select)`
  width: 14rem;

  & ${TextFieldInputLabel} {
    color: ${({ theme }) => theme.palette.grey[600]};
  }
` as typeof Select;

const StyledButton = styled(Button)`
  margin-bottom: 2rem;
  width: 5rem;
`;
const StyledTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.colors.whiteBlue};
  width: 116px;
`;

const StyledTab = styled(Tab)`
  border: 1px solid ${({ theme }) => theme.colors.blueBorder};
  font-size: 0.875rem;

  &.${CLASS_NAME_TAB_SELECTED} {
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: #fff;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
  padding-left: 3.75rem;
  width: 30rem;
  height: 100%;
`;

export default UserPanel;
