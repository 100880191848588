import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import { OfferDto } from "@strafos/common";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import styled from "styled-components";
import Inside from "@app/components/organisms/RequestMap/Inside";
import { ROOT_SELECTOR } from "@app/components/organisms/RequestMap/shared";
import { useMapFlightPoints } from "@app/hooks/useMapFlightPoints";
import { Loader } from "@app/components/organisms/LegEditorForm/components/Loader";

interface Props {
  offer: OfferDto;
  show: boolean;
  onFullscreenButtonClick: () => void;
  onHideButtonClick: () => void;
  isFullScreen: boolean;
}

export default function RequestMap({
  offer,
  show,
  onFullscreenButtonClick,
  onHideButtonClick,
  isFullScreen,
}: Props) {
  const rootEl = useRef<HTMLDivElement>(null);
  const [shouldRender, setShouldRender] = useState(false);
  const { mapPointsData, isLoading, legs, error } = useMapFlightPoints({
    legs: offer.legs,
  });

  useEffect(() => {
    if (!show) {
      return;
    }
    // setShouldRender(false)

    if (!!mapPointsData && !error && !isLoading) {
      setShouldRender(true);
    }
  }, [show, isFullScreen, isLoading, mapPointsData, error]);

  if (!show) {
    return null;
  }

  return (
    <Wrapper ref={rootEl}>
      {shouldRender && !!mapPointsData ? (
        <StyledMapContainer
          style={{
            width: "100%",
            height: "100%",
          }}
          zoom={18}
          className={ROOT_SELECTOR}
          scrollWheelZoom={false}
        >
          <Inside
            legs={legs}
            offerStatus={offer.status}
            mapPointsData={mapPointsData}
          />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </StyledMapContainer>
      ) : (
        <Loader />
      )}
      <IconWrapper>
        {isFullScreen ? (
          <FullscreenExit onClick={onFullscreenButtonClick} fontSize="large" />
        ) : (
          <Fullscreen onClick={onFullscreenButtonClick} fontSize="large" />
        )}
        <Close onClick={onHideButtonClick} fontSize="large" />
      </IconWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px 0 0;
  width: 100%;
  height: 100%;
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 40;

  & > svg {
    cursor: pointer;
  }
`;

const StyledMapContainer = styled(MapContainer)``;
