import { useSelector } from 'react-redux'

import { selectUserInfo } from '@app/store/core/userInfo/userInfo.selectors'
import { DisplayAirportIdentifications } from '@strafos/common'
import { assertUnreachable } from '@strafos/common'

type InputWithICAOAndIATA = {
  icao_code: string | null
  iata_code: string | null
}

const useGetAirportIdentificationCode = () => {
  const userInfo = useSelector(selectUserInfo)

  return (input: InputWithICAOAndIATA) => {
    if (!userInfo?.display_airport_identification) {
      return input.icao_code
    }

    switch (userInfo.display_airport_identification) {
      case DisplayAirportIdentifications.IATA:
        return input.iata_code ?? input.icao_code

      case DisplayAirportIdentifications.ICAO:
        return input.icao_code ?? input.iata_code

      default:
        assertUnreachable(userInfo.display_airport_identification)
    }
  }
}

export default useGetAirportIdentificationCode
