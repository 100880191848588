import { ContactPersonFormData } from "@app/components/organisms/ContactPersonForm/ContactPersonForm";
import {
  CreateContactPersonDto,
  ContactPersonDetailDto,
} from "@strafos/common";

export const transformContactPersonToFormData = (
  contactPerson: ContactPersonDetailDto | null,
): ContactPersonFormData => {
  return {
    name: contactPerson?.name ?? "",
    email: contactPerson?.email ?? "",
    phone: contactPerson?.phone ?? "",
    mobilePhone: contactPerson?.mobile_phone ?? "",
  };
};

export const transformFormDataToPostContactPersonDto = (
  formData: ContactPersonFormData,
): Omit<CreateContactPersonDto, "client_id"> => {
  return {
    name: formData.name,
    email: formData.email,
    phone: formData.phone || undefined,
    mobile_phone: formData.mobilePhone || undefined,
  };
};
