import React, { useCallback, useState } from "react";
import { RequestNoteDto } from "@strafos/common";

import RequestNoteForm from "@app/components/organisms/RequestNotes/Form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Typography from "@app/components/atoms/Typography/Typography";
import RequestNote from "@app/components/organisms/RequestNotes/Note";

interface Props {
  notes: RequestNoteDto[] | null;
  requestId: number;
  className?: string;
}

export default function RequestNotes({ notes, requestId, className }: Props) {
  const [allNotes, setAllNotes] = useState<RequestNoteDto[]>(notes || []);
  const { t } = useTranslation();

  const handleNoteAdded = useCallback((n: RequestNoteDto) => {
    setAllNotes((notes) => [...notes, n]);
  }, []);
  return (
    <Container className={className}>
      <Heading variant="boldText">{t("organisms.RequestNote.heading")}</Heading>
      <MessagesContainer>
        {allNotes.length === 0 ? (
          <NoNotes variant="subtitle">
            {t("organisms.RequestNote.noNotes")}
          </NoNotes>
        ) : (
          allNotes?.map((note, index) => (
            <div key={index}>
              <StyledRequestNote note={note} />
            </div>
          ))
        )}
      </MessagesContainer>
      <RequestNoteForm requestId={requestId} onNoteAdded={handleNoteAdded} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  padding-top: 0;
`;

const MessagesContainer = styled.div`
  flex: 0 1 auto;
  overflow-y: auto;
  margin-bottom: 1rem;
  padding: 0.2rem;
  white-space: pre-wrap;
`;

const Heading = styled(Typography)`
  flex: 0 0 auto;
  margin-bottom: 1rem;
`;

const NoNotes = styled(Typography)`
  text-align: center;
  margin: 1rem 0;
`;

const StyledRequestNote = styled(RequestNote)`
  margin-bottom: 1rem;
`;
