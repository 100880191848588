import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { PASSWORD_REGEX } from '@strafos/common'

const usePasswordChangeValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object().shape({
    currentPassword: yup
      .string()
      .required(
        t('organisms.UserPanel.PasswordChangeForm.currentPasswordRequired'),
      )
      .typeError(
        t('organisms.UserPanel.PasswordChangeForm.currentPasswordRequired'),
      ),
    newPassword: yup
      .string()
      .matches(
        PASSWORD_REGEX,
        t(
          'organisms.UserPanel.PasswordChangeForm.newPasswordMinimalRequirements',
        ),
      )
      .required(t('organisms.UserPanel.PasswordChangeForm.newPasswordRequired'))
      .typeError(
        t('organisms.UserPanel.PasswordChangeForm.newPasswordRequired'),
      ),
    confirmedNewPassword: yup
      .string()
      .required(
        t(
          'organisms.UserPanel.PasswordChangeForm.confirmedNewPasswordRequired',
        ),
      )
      .oneOf(
        [yup.ref('newPassword')],
        t(
          'organisms.UserPanel.PasswordChangeForm.confirmedNewPasswordMismatch',
        ),
      )
      .typeError(
        t(
          'organisms.UserPanel.PasswordChangeForm.confirmedNewPasswordRequired',
        ),
      ),
  })
}

export default usePasswordChangeValidationSchema
