import TextField from "@app/components/atoms/TextField/TextField";
import Button from "@app/components/atoms/Button/Button";
import React, { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectSelectedOperator } from "@app/store/core/userOperators/userOperators.selectors";
import { api } from "@app/utils/api/api";
import { RequestNoteDto, CreateRequestNoteBody } from "@strafos/common";
import { useSnackbar } from "notistack";
import { AxiosResponse } from "axios";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

interface Props {
  onNoteAdded?: (note: RequestNoteDto) => void;
  requestId: number;
}

export default function RequestNoteForm({ onNoteAdded, requestId }: Props) {
  const [textValue, setTextValue] = useState("");
  const { t } = useTranslation();
  const operator = useSelector(selectSelectedOperator);
  const { enqueueSnackbar } = useSnackbar();

  const addNote = useMutation(
    (data: CreateRequestNoteBody) => {
      return api.addRequestNote(data);
    },
    {
      onSuccess: (data: AxiosResponse<RequestNoteDto>) => {
        onNoteAdded?.(data.data);
        setTextValue("");
        enqueueSnackbar(t("organisms.RequestNote.noteAdded"), {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar(t("organisms.RequestNote.noteError"), {
          variant: "error",
        });
      },
    },
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!operator?.id) {
        return;
      }
      const reqData: CreateRequestNoteBody = {
        request_id: requestId,
        text: textValue,
      };

      addNote.mutate(reqData);
    },
    [textValue, operator?.id, requestId, onNoteAdded],
  );

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        value={textValue}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          setTextValue(e.target.value);
        }}
        placeholder={t("organisms.RequestNote.placeholder")}
        rows={4}
        multiline
        fullWidth
      />
      <ButtonContainer>
        {addNote.isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Button type="submit" disabled={!textValue}>
            {t("organisms.RequestNote.submitButton")}
          </Button>
        )}
      </ButtonContainer>
    </form>
  );
}

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row-reverse;
`;
