import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { CellProps, Row } from 'react-table'
import { useTranslation } from 'react-i18next'

import Tooltip from '@app/components/atoms/Tooltip/Tooltip'

import {
  DataGridCell,
  DataGridColumn,
} from '@app/components/atoms/DataGrid/DataGrid'

import GridView, {
  GridViewProps,
} from '@app/components/molecules/GridView/GridView'
import {
  AircraftDetailDto,
  OfferFlags,
  OfferStatuses,
  PartialRequestDto,
} from '@strafos/common'
import { useDispatch } from 'react-redux'

import useUnreadMessages from '@app/hooks/useUnreadMessagesCount'
import NewMessageIcon from '@app/components/atoms/NewMessageIcon/NewMessageIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SearchIcon from '@material-ui/icons/Search'
import CheckIcon from '@material-ui/icons/Check'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {
  reloadRequestsListAction,
  resetRequestsListFiltersAction,
  setRequestsListFiltersAction,
} from '@app/store/pages/requests/requestList/requestList.actions'
import { useSnackbar } from 'notistack'
import { AdjustedQuoteCell } from '@app/components/molecules/RequestsGridView/Cells/AdjustedQuoteCell'
import {
  AircraftCountCell,
  FlagsCell,
  RequestedByCell,
  StatusCell,
  SummaryCell,
  TripIdCell,
  WarningCell,
} from '@app/components/molecules/RequestsGridView/Cells'
import TripInfoCell from '@app/components/molecules/RequestsGridView/Cells/TripInfoCell'
import EmptyListMessage from '@app/components/atoms/EmptyListMessage/EmptyListMessage'
import NoBookingsIcon from '@app/components/atoms/icons/NoBookingsIcon/NoBookingsIcon'
import { Box, Checkbox, lighten } from '@material-ui/core'
import Button from '@app/components/atoms/Button/Button'

import { api } from '@app/utils/api/api'

import { openConfirmationDialogAction } from '@app/store/ui/confirmationDialog/confirmationDialog.actions'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { RequestPageVariants } from '@app/components/pages/Requests/Requests'
import ChangeAircraftDialog from '@app/store/ui/changeAircraftDialog/changeAircraftDialog'

export interface RequestsGridViewProps
  extends Omit<
    GridViewProps<PartialRequestDto>,
    'columns' | 'dataTestIdNamespace' | 'getBadgeNotificationContent'
  > {
  onGetInitialData?: () => void
  variant?: RequestPageVariants
}

const RequestsGridView = ({
  onGetInitialData,
  ...props
}: RequestsGridViewProps): JSX.Element => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { data: unreadMessages, markAllMessagesAsRead } = useUnreadMessages()
  const anchorEl = useRef(null)
  const [showUnreadMenu, setShowUnreadMenu] = useState(false)
  const [checkedRequests, setCheckedRequests] = useState<PartialRequestDto[]>(
    [],
  )
  const [gridVariant] = useState(props.variant)
  const [bulkDeclineAvailable, setBulkDeclineAvailable] =
    useState<boolean>(false)
  const [bulkChangeAircraftAvailable, setBulkChangeAircraftAvailable] =
    useState<boolean>(false)
  const [bulkQuoteAvailable, setBulkQuoteAvailable] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [changeAircraftDialogOpen, setChangeAircraftDialogOpen] =
    useState(false)

  const dispatch = useDispatch()

  const handleUnreadMessagesFilter = useCallback(() => {
    setShowUnreadMenu(false)
    dispatch(resetRequestsListFiltersAction())
    dispatch(
      setRequestsListFiltersAction(
        {
          flags: [OfferFlags.NewMessage],
        },
        100,
      ),
    )
  }, [])

  const handleMarkAllMessagesAsRead = useCallback(async () => {
    const response = await markAllMessagesAsRead()
    setShowUnreadMenu(false)

    if (response.success) {
      enqueueSnackbar(response.message, {
        variant: 'success',
      })
      window.location.reload()
    } else {
      enqueueSnackbar(response.message, {
        variant: 'error',
      })
    }
  }, [])

  const handleRequestCheckboxClicked = useCallback(
    (row: Row<PartialRequestDto>) => {
      setCheckedRequests((prevValues) => {
        if (prevValues.some((curRow) => curRow.id === row.original.id)) {
          return prevValues.filter((curReq) => curReq.id !== row.original.id)
        } else {
          return [row.original, ...prevValues]
        }
      })
    },
    [],
  )

  useEffect(() => {
    const bolkOpsAvailable =
      checkedRequests.length > 0 &&
      checkedRequests.every((curItem) =>
        [
          OfferStatuses.New,
          OfferStatuses.Unhandled,
          OfferStatuses.Draft,
        ].includes(curItem.status),
      )
    setBulkDeclineAvailable(bolkOpsAvailable)
    setBulkQuoteAvailable(bolkOpsAvailable)

    const aircraftIds = checkedRequests.reduce((acc, current) => {
      if (current.aircraft_code && !acc.includes(current.aircraft_code)) {
        acc.push(current.aircraft_code)
      }
      return acc
    }, [] as string[])

    const switchAircraftAvailable =
      checkedRequests.length > 0 &&
      aircraftIds.length === 1 &&
      checkedRequests.every((curItem) =>
        [OfferStatuses.Booked].includes(curItem.status),
      )
    setBulkChangeAircraftAvailable(switchAircraftAvailable)
  }, [checkedRequests])

  const handleBulkDeclineClick = () => {
    dispatch(
      openConfirmationDialogAction({
        shouldCloseOnSubmit: true,
        i18nextKey: t('confirmations.bulkDeclineConfirmation'),
        onSubmit: handleBulkDeclineSubmit,
      }),
    )
  }

  const handleBulkChangeAircraftClick = () => {
    setChangeAircraftDialogOpen(true)
  }

  const handleBulkQuoteClick = () => {
    dispatch(
      openConfirmationDialogAction({
        shouldCloseOnSubmit: true,
        i18nextKey: t('confirmations.bulkQuoteConfirmation'),
        onSubmit: handleBulkQuoteSubmit,
      }),
    )
  }

  const handleBulkDeclineSubmit = () => {
    const requestIds = checkedRequests.map((curReq) => curReq.id)
    api.markMessagesAsRead({ request_ids: requestIds })
    api
      .bulkDeclineRequests(requestIds)
      .then(() => {
        dispatch(reloadRequestsListAction())
        setCheckedRequests([])
        dispatch(
          addNotificationAction({
            i18nextKey: 'messages.declineRequests.success',
            type: 'success',
          }),
        )
      })
      .catch(() => {
        dispatch(
          addNotificationAction({
            i18nextKey: 'errors.declineRequests.default',
            type: 'error',
          }),
        )
      })
  }

  const handleChangeAircraftSubmit = (aircraft: AircraftDetailDto) => {
    setChangeAircraftDialogOpen(false)
    const requestIds = checkedRequests.map((curReq) => curReq.id)
    const aircraftId = aircraft.id
    api
      .bulkChangeAircraft(aircraftId, requestIds)
      .then(() => {
        dispatch(reloadRequestsListAction())
        setCheckedRequests([])
        dispatch(
          addNotificationAction({
            i18nextKey: 'messages.changeAircraft.success',
            type: 'success',
          }),
        )
      })
      .catch(() => {
        dispatch(
          addNotificationAction({
            i18nextKey: 'errors.changeAircraft.default',
            type: 'error',
          }),
        )
      })
  }

  const handleChangeAircraftCancel = () => {
    setChangeAircraftDialogOpen(false)
  }

  const handleBulkQuoteSubmit = () => {
    const requestIds = checkedRequests.map((curReq) => curReq.id)
    api.markMessagesAsRead({ request_ids: requestIds })
    api
      .bulkQuoteRequests(requestIds)
      .then(() => {
        dispatch(reloadRequestsListAction())
        setCheckedRequests([])
        dispatch(
          addNotificationAction({
            i18nextKey: 'messages.quoteRequests.success',
            type: 'success',
          }),
        )
      })
      .catch(() => {
        dispatch(
          addNotificationAction({
            i18nextKey: 'errors.quoteRequests.default',
            type: 'error',
          }),
        )
      })
  }

  const columns = React.useMemo<DataGridColumn<PartialRequestDto>[]>(() => {
    const cols = [
      {
        id: 'request-checkbox',
        title: '',
        Cell: ({ row }) => (
          <StyledCheckbox
            checked={checkedRequests.some(
              (curReq) => curReq.id === row.original.id,
            )}
            onChange={() => {
              handleRequestCheckboxClicked(row)
            }}
          />
        ),
      },
      {
        id: 'created_at',
        title: 'Status / Created',
        Cell: StatusCell,
        width: '0',
        isSortable: true,
      },
      {
        id: 'flags',
        title: 'Flags',
        Cell: FlagsCell,
        width: '0',
        addorment:
          unreadMessages > 0 ? (
            <StyledHeaderTooltip
              title={t('enums.OfferFlags.newMessages') as string}
            >
              <div>
                <StyledNewMessageIcon
                  onClick={() => setShowUnreadMenu(true)}
                  ref={anchorEl}
                />
                <StyledMenu
                  anchorEl={anchorEl.current}
                  open={showUnreadMenu}
                  onClose={() => setShowUnreadMenu(false)}
                >
                  <MenuItem onClick={handleUnreadMessagesFilter}>
                    <ListItemIcon>
                      <SearchIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {t('requests.filterUnreadMessages')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleMarkAllMessagesAsRead}>
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {t('requests.markAllMessagesAsRead')}
                    </ListItemText>
                  </MenuItem>
                </StyledMenu>
              </div>
            </StyledHeaderTooltip>
          ) : undefined,
      },
      {
        id: 'requested_by',
        title: 'Requested by',
        Cell: RequestedByCell,
        isSortable: true,
      },
      {
        id: 'trip_date',
        title: 'Trip information',
        Cell: TripInformationCell,
        isSortable: true,
      },
      {
        id: 'trip_id',
        title: 'Trip ID',
        Cell: TripIdCell,
        isSortable: true,
      },
      {
        id: 'aircraft_count',
        title: 'Aircraft count',
        Cell: AircraftCountCell,
        width: '6rem',
      },
      {
        id: 'total_profit',
        title: 'Aircraft / Profit & Price',
        Cell: SummaryCell,
        isSortable: true,
      },
      {
        id: 'adjusted_quote',
        title: 'Adjusted Quote by Schedule Change',
        Cell: AdjustedQuoteCell,
      },
      {
        id: 'warnings',
        title: 'Warnings',
        Cell: WarningCell,
      },
    ]

    if (gridVariant === RequestPageVariants.Bookings) {
      cols.shift()
    }
    return cols
  }, [
    unreadMessages,
    showUnreadMenu,
    anchorEl,
    handleUnreadMessagesFilter,
    checkedRequests,
    gridVariant,
  ])

  useEffect(() => {
    onGetInitialData?.()
  }, [])

  return (
    <>
      {gridVariant !== RequestPageVariants.Bookings && (
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            '& > *:not(:last-child)': {
              marginRight: '0.5rem',
            },
          }}
        >
          <Button
            disabled={!bulkQuoteAvailable}
            onClick={handleBulkQuoteClick}
            data-testid="LegEditorForm__bulk-quote"
          >
            {t('organisms.RequestsList.bulkRequestQuote')}
          </Button>
          <Button
            disabled={!bulkChangeAircraftAvailable}
            onClick={handleBulkChangeAircraftClick}
            data-testid="LegEditorForm__bulk-change-aircraft"
          >
            {t('organisms.RequestsList.bulkChangeAircraft')}
          </Button>

          <ChangeAircraftDialog
            open={changeAircraftDialogOpen}
            onClose={handleChangeAircraftCancel}
            onSubmit={handleChangeAircraftSubmit}
          ></ChangeAircraftDialog>
          <StyledButton
            disabled={!bulkDeclineAvailable}
            onClick={handleBulkDeclineClick}
            data-testid="LegEditorForm__bulk-decline"
            $background={theme.colors.brown}
          >
            {t('organisms.RequestsList.bulkRequestDecline')}
          </StyledButton>
        </Box>
      )}
      <StyledGridView<PartialRequestDto>
        columns={columns}
        dataTestIdNamespace="RequestsGridView"
        getBadgeNotificationContent={(request) =>
          (request as PartialRequestDto).unread_messages_count
        }
        noDataContent={
          <EmptyListMessage
            title={t('molecules.RequestsGridView.noDataTitle')}
            description={t('molecules.RequestsGridView.noDataDescription')}
            Icon={NoBookingsIcon}
          />
        }
        {...props}
      />
    </>
  )
}

const TripInformationCell = ({
  cell,
}: CellProps<PartialRequestDto, undefined>) => {
  return <TripInfoCell tripInfo={cell.row.original.trip_info} />
}

const StyledButton = styled(Button)<{ $background?: string }>`
  background: ${({ $background }) => $background};

  &:hover {
    background: ${({ $background }) =>
      $background && lighten($background, 0.1)};
  }
`

const StyledMenu = styled(Menu)`
  margin-top: 20px;
  margin-left: 20px;
`

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`

const StyledGridView = styled(GridView)`
  ${DataGridCell} {
    padding: 0.5rem 1rem;
  }
`

const StyledNewMessageIcon = styled(NewMessageIcon)`
  transform: translate(0.5rem, 0.3rem);
  width: 1rem;
  height: 1rem;

  &:hover {
    cursor: pointer;
  }
`

const StyledHeaderTooltip = styled(Tooltip)`
  display: inline-block;
  min-width: 2rem;
`

export default RequestsGridView
