import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Avatar from "@material-ui/core/Avatar";

import Logo from "@app/components/atoms/Logo/Logo";
import { ChatMessageDto, ChatMessageTypes } from "@strafos/common";

interface ChatMessageAvatarProps {
  message: ChatMessageDto;
  className?: string;
}

const ChatMessageAvatar = ({ message, className }: ChatMessageAvatarProps) => {
  const { t } = useTranslation();

  switch (message.type) {
    case ChatMessageTypes.SystemMessage:
    case ChatMessageTypes.UserMessage:
      if (!message.sender) {
        return (
          <StyledAvatar className={className}>
            <StyledStrafosLogo />
          </StyledAvatar>
        );
      }

      return (
        <StyledAvatar
          className={className}
          title={`${message.sender.first_name} ${message.sender.last_name}`}
        >
          {message.sender.first_name.charAt(0)}
          {message.sender.last_name.charAt(0)}
        </StyledAvatar>
      );

    case ChatMessageTypes.AvinodeMessage:
      // TODO perhaps the Avinode logo instead of Strafos?
      return (
        <StyledAvatar
          className={className}
          title={t("molecules.ChatMessage.avinodeMessageTitle")}
        >
          <StyledStrafosLogo />
        </StyledAvatar>
      );

    default:
      return (
        <StyledAvatar className={className}>
          <StyledStrafosLogo />
        </StyledAvatar>
      );
  }
};

const StyledAvatar = styled(Avatar)`
  font-size: 1rem;
`;

const StyledStrafosLogo = styled(Logo)`
  color: #fff;
  width: 1rem;
`;

export default ChatMessageAvatar;
