import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { getISOStringIgnoringTimezone } from '@app/utils/dateUtils'
import { getCombinedDate } from '@strafos/common'
import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'

dayjs.extend(utc)

const getTestIsFromDateWithinDateBoundaries: (boundaries: {
  leftBoundary: Date | string | undefined
  rightBoundary: Date | string
}) => yup.TestFunction =
  ({ leftBoundary, rightBoundary }) =>
  (value, { parent }) => {
    let fromDateTime = parent.fromDate

    if (parent.fromTime) {
      fromDateTime = getCombinedDate(parent.fromDate, parent.fromTime)
    }

    if (!leftBoundary) {
      return !dayjs
        .utc(getISOStringIgnoringTimezone(fromDateTime))
        .isAfter(dayjs.utc(rightBoundary))
    }

    return (
      !dayjs
        .utc(getISOStringIgnoringTimezone(fromDateTime))
        .isAfter(dayjs.utc(rightBoundary)) &&
      !dayjs
        .utc(getISOStringIgnoringTimezone(fromDateTime))
        .isBefore(dayjs.utc(leftBoundary))
    )
  }

const getTestIsToDateWithinDateBoundaries: (boundaries: {
  leftBoundary: Date | string
  rightBoundary: Date | string | undefined
}) => yup.TestFunction =
  ({ leftBoundary, rightBoundary }) =>
  (value, { parent }) => {
    let toDateTime = new Date(parent.toDate)

    if (parent.toTime) {
      toDateTime = getCombinedDate(parent.toDate, parent.toTime)
    }

    if (!rightBoundary) {
      return !dayjs
        .utc(getISOStringIgnoringTimezone(toDateTime))
        .isBefore(dayjs.utc(leftBoundary))
    }

    return (
      !dayjs
        .utc(getISOStringIgnoringTimezone(toDateTime))
        .isAfter(dayjs.utc(rightBoundary)) &&
      !dayjs
        .utc(getISOStringIgnoringTimezone(toDateTime))
        .isBefore(dayjs.utc(leftBoundary))
    )
  }

const testIsFromDateInFuture: yup.TestFunction = (value, { parent }) => {
  let fromDateTime = new Date(parent.fromDate)

  if (parent.fromTime) {
    fromDateTime = getCombinedDate(parent.fromDate, parent.fromTime)
  }

  return dayjs.utc().isBefore(fromDateTime)
}

const testIsToDateInFuture: yup.TestFunction = (value, { parent }) => {
  let toDateTime = new Date(parent.fromDate)

  if (parent.toTime) {
    toDateTime = getCombinedDate(parent.toDate, parent.toTime)
  }

  return dayjs.utc().isBefore(toDateTime)
}

const useMarketplaceExtensionValidationSchema = ({
  fromLeftBoundary,
  fromRightBoundary,
  toLeftBoundary,
  toRightBoundary,
}: {
  fromLeftBoundary: string | Date | undefined
  fromRightBoundary: string | Date
  toLeftBoundary: string | Date
  toRightBoundary: string | Date | undefined
}) => {
  const { t } = useTranslation()
  const { dateTimeFormat } = useDateAndTimeFormats()

  return yup.object().shape({
    fromDate: yup
      .date()
      .required(t('organisms.MarketplaceExtensionForm.fromDateRequired'))
      .typeError(t('organisms.MarketplaceExtensionForm.fromDateRequired'))
      .test(
        'fromDateWithinBoundaries',
        t('organisms.MarketplaceExtensionForm.fromDateWithinBoundaries', {
          leftBoundary: dayjs.utc(fromLeftBoundary).format(dateTimeFormat),
          rightBoundary: dayjs.utc(fromRightBoundary).format(dateTimeFormat),
          interpolation: { escapeValue: false },
        }),
        getTestIsFromDateWithinDateBoundaries({
          leftBoundary: fromLeftBoundary,
          rightBoundary: fromRightBoundary,
        }),
      )
      .test(
        'fromDateInFuture',
        t('organisms.MarketplaceExtensionForm.fromDateInFuture'),
        testIsFromDateInFuture,
      ),
    fromTime: yup
      .date()
      .required(t('organisms.MarketplaceExtensionForm.fromTimeRequired'))
      .typeError(t('organisms.MarketplaceExtensionForm.fromTimeRequired'))
      .test(
        'fromDateWithinBoundaries',
        t('organisms.MarketplaceExtensionForm.fromDateWithinBoundaries', {
          leftBoundary: dayjs.utc(fromLeftBoundary).format(dateTimeFormat),
          rightBoundary: dayjs.utc(fromRightBoundary).format(dateTimeFormat),
          interpolation: { escapeValue: false },
        }),
        getTestIsFromDateWithinDateBoundaries({
          leftBoundary: fromLeftBoundary,
          rightBoundary: fromRightBoundary,
        }),
      )
      .test(
        'fromDateInFuture',
        t('organisms.MarketplaceExtensionForm.fromDateInFuture'),
        testIsFromDateInFuture,
      ),
    toDate: yup
      .date()
      .required(t('organisms.MarketplaceExtensionForm.toDateRequired'))
      .typeError(t('organisms.MarketplaceExtensionForm.toDateRequired'))
      .test(
        'toDateWithinBoundaries',
        t('organisms.MarketplaceExtensionForm.toDateWithinBoundaries', {
          leftBoundary: dayjs.utc(toLeftBoundary).format(dateTimeFormat),
          rightBoundary: dayjs.utc(toRightBoundary).format(dateTimeFormat),
          interpolation: { escapeValue: false },
        }),
        getTestIsToDateWithinDateBoundaries({
          leftBoundary: toLeftBoundary,
          rightBoundary: toRightBoundary,
        }),
      )
      .test(
        'toDateInFuture',
        t('organisms.MarketplaceExtensionForm.toDateInFuture'),
        testIsToDateInFuture,
      ),
    toTime: yup
      .date()
      .required(t('organisms.MarketplaceExtensionForm.toTimeRequired'))
      .typeError(t('organisms.MarketplaceExtensionForm.toTimeRequired'))
      .test(
        'toDateWithinBoundaries',
        t('organisms.MarketplaceExtensionForm.toDateWithinBoundaries', {
          leftBoundary: dayjs.utc(toLeftBoundary).format(dateTimeFormat),
          rightBoundary: dayjs.utc(toRightBoundary).format(dateTimeFormat),
          interpolation: { escapeValue: false },
        }),
        getTestIsToDateWithinDateBoundaries({
          leftBoundary: toLeftBoundary,
          rightBoundary: toRightBoundary,
        }),
      )
      .test(
        'toDateInFuture',
        t('organisms.MarketplaceExtensionForm.toDateInFuture'),
        testIsToDateInFuture,
      ),
  })
}

export default useMarketplaceExtensionValidationSchema
