import React, { MutableRefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  FieldError,
  FieldErrors,
  useFieldArray,
  useForm,
} from "react-hook-form";

import ControlledLegFieldGroup from "@app/components/organisms/CreateRequestForm/LegFieldGroup/ControlledLegFieldGroup";
import Button from "@app/components/atoms/Button/Button";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Logo from "@app/components/atoms/Logo/Logo";

import { useYupValidationResolver } from "@app/hooks/useYupValidationResolver";
import { DateTypes } from "@app/components/atoms/DateTypeSwitch/DateTypeSwitch";
import { api } from "@app/utils/api/api";
import useCreateRequestFormValidationSchema from "@app/components/organisms/CreateRequestForm/useCreateRequestFormValidationSchema";
import {
  DisplayTimeTypes,
  LegComputationRequest,
  AirportDetailDto,
  PartialRequestDto,
} from "@strafos/common";
import { getTimezoneAwareDateFromUTC } from "@app/utils/dateUtils";

import {
  transformFormDataToLegComputationRequests,
  transformRequestToFormData,
} from "@app/components/organisms/CreateRequestForm/CreateRequestForm.transformer";

export enum CreateRequestFormVariant {
  OneWayTrip = "OneWayTrip",
  RoundTrip = "RoundTrip",
}

interface CreateRequestLegFormData {
  departureAirport?: Omit<AirportDetailDto, "extras" | "airport_fees"> | null;
  arrivalAirport?: Omit<AirportDetailDto, "extras" | "airport_fees"> | null;
  date: Date | null;
  time: Date | null;
  dateType: DateTypes;
  returnDate: Date | null;
  returnTime: Date | null;
  returnDateType: DateTypes | null;
  passengerCount: number;
}

export interface CreateRequestFormData {
  legs: CreateRequestLegFormData[];
}

export interface CreateRequestFormProps {
  onSubmit: (values: { requests: LegComputationRequest[] }) => void;
  variant: CreateRequestFormVariant;
  request?: PartialRequestDto;
  timeDisplay?: DisplayTimeTypes;
  submitRef?: MutableRefObject<
    (() => void) | (() => Promise<void>) | undefined | null
  >;
}

const CreateRequestForm = ({
  onSubmit,
  submitRef,
  request,
  variant,
  timeDisplay = DisplayTimeTypes.UTC,
}: CreateRequestFormProps) => {
  const { t } = useTranslation();

  const [isLoadingAirports, setIsLoadingAirports] = useState(false);

  const defaultDateType = DateTypes.Departure;

  const validationSchema = useCreateRequestFormValidationSchema({ variant });

  const defaultValues = transformRequestToFormData(variant, request ?? null);

  const validationResolver = useYupValidationResolver(validationSchema);

  const { handleSubmit, control, trigger, formState, watch, getValues, reset } =
    useForm<CreateRequestFormData>({
      resolver: validationResolver,
      defaultValues,
    });

  const watchedLegs = watch("legs");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "legs",
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchedLegs[index],
    };
  });

  const getErrorsForCurrentLeg = (
    errors: FieldErrors<CreateRequestFormData>,
    index: number,
  ) => {
    const baseLegPath = `legs[${index}].`;

    const getIsFieldError = (value: object): value is FieldError =>
      !!(value as FieldError).message;

    return Object.entries(errors)
      .filter(([key]) => key.startsWith(baseLegPath))
      .reduce((acc, [key, value]) => {
        if (!getIsFieldError(value)) {
          return acc;
        }

        return { ...acc, [key.replace(baseLegPath, "")]: value };
      }, {});
  };

  const handleDeleteClick = (index: number) => {
    remove(index);
  };

  const handleAddNewLeg = () => {
    const lastItem = controlledFields.length
      ? controlledFields[controlledFields.length - 1]
      : null;

    append({
      date: null,
      time: null,
      dateType: lastItem?.dateType ?? defaultDateType,
      departureAirport: lastItem?.arrivalAirport,
      arrivalAirport: null,
      passengerCount: lastItem?.passengerCount ?? 0,
    });
  };

  const handleSubmitClick = (values: CreateRequestFormData) => {
    const transformedValues = transformFormDataToLegComputationRequests(
      values,
      { variant, timeDisplay },
    );

    onSubmit(transformedValues);
  };

  useEffect(() => {
    if (submitRef) {
      submitRef.current = handleSubmit(handleSubmitClick);
    }
  }, []);

  useEffect(() => {
    if (request?.input.requests) {
      setIsLoadingAirports(true);

      const airportIdsSet = new Set(
        request.input.requests.flatMap((leg) => [
          leg.departure_airport_id,
          leg.arrival_airport_id,
        ]),
      );

      Promise.all(Array.from(airportIdsSet).map((id) => api.getAirport(id)))
        .then((results) => {
          const updatedLegs = getValues().legs.map((leg, index) => {
            const originalLegRequest = request.input.requests[index];

            if (!originalLegRequest) {
              return leg;
            }

            const arrivalAirportResponse = results.find(
              ({ data: airport }) =>
                airport.id === originalLegRequest.arrival_airport_id,
            );

            const departureAirportResponse = results.find(
              ({ data: airport }) =>
                airport.id === originalLegRequest.departure_airport_id,
            );

            const timezone =
              leg.dateType === DateTypes.Departure
                ? departureAirportResponse?.data.timezone
                : arrivalAirportResponse?.data.timezone;

            const date =
              leg.date && timezone
                ? getTimezoneAwareDateFromUTC(leg.date, timezone, timeDisplay)
                : leg.date;

            const returnTimezone =
              leg.returnDateType === DateTypes.Departure
                ? arrivalAirportResponse?.data.timezone
                : departureAirportResponse?.data.timezone;

            const returnDate =
              leg.returnDate && returnTimezone
                ? getTimezoneAwareDateFromUTC(
                    leg.returnDate,
                    returnTimezone,
                    timeDisplay,
                  )
                : leg.returnDate;

            return {
              ...leg,
              arrivalAirport: arrivalAirportResponse?.data ?? null,
              departureAirport: departureAirportResponse?.data ?? null,
              time: date,
              returnTime: returnDate,
              date,
              returnDate,
            };
          });

          reset({
            ...getValues(),
            legs: updatedLegs,
          });
        })
        .finally(() => {
          setIsLoadingAirports(false);
        });
    }
  }, []);

  if (isLoadingAirports) {
    return (
      <StyledLoadingSpinner loading={isLoadingAirports}>
        <StyledLogo />
      </StyledLoadingSpinner>
    );
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitClick)}>
      {controlledFields.map((field, index) => (
        <ControlledLegFieldGroup
          key={field.id}
          index={index}
          control={control}
          trigger={trigger}
          variant={variant}
          errors={getErrorsForCurrentLeg(formState.errors, index)}
          isDeleteButtonDisabled={fields.length < 2}
          onDeleteClick={() => handleDeleteClick(index)}
        />
      ))}
      <ButtonsContainer>
        {variant === CreateRequestFormVariant.OneWayTrip &&
          !!controlledFields[controlledFields.length - 1].arrivalAirport && (
            <Button
              inverted
              onClick={handleAddNewLeg}
              data-testid="CreateRequestForm__submit-button"
            >
              {t("organisms.CreateRequestForm.addNewLeg")}
            </Button>
          )}
      </ButtonsContainer>
    </form>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledLogo = styled(Logo)`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 3rem;
  height: 3rem;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  display: flex;
  justify-content: center;
`;

export default CreateRequestForm;
