import { useSelector } from 'react-redux'

import { selectUserInfo } from '@app/store/core/userInfo/userInfo.selectors'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { Actions, UserStatuses } from '@strafos/common'
import { userHasRights } from '@strafos/common'

export const useCanUser = () => {
  const userInfo = useSelector(selectUserInfo)
  const selectedOperator = useSelector(selectSelectedOperator)

  return (action: Actions) => {
    if (!userInfo?.user_roles || !selectedOperator) {
      return false
    }

    const activeRole = userInfo.user_roles.find(
      (role) =>
        role.status === UserStatuses.Active &&
        role.operator_id === selectedOperator.id,
    )

    if (!activeRole) {
      return false
    }

    return userHasRights(action, activeRole?.role)
  }
}
