import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  LegFormData,
  DisplayTimeTypes,
  AirportDetailDto,
} from "@strafos/common";
import Typography from "@app/components/atoms/Typography/Typography";
import { useTranslation } from "react-i18next";
import { useAviaCalculations } from "@app/hooks/useAviaCalculations";
import { Control, useFieldArray } from "react-hook-form";
import { LegEditorFormData } from "@app/components/organisms/LegEditorForm/LegEditorForm";
import { AviaPagesCalculations } from "@app/components/organisms/LegEditorForm/LegFlightRecalculate/AviaPagesCalculations";
import { api } from "@app/utils/api/api";
import Button from "@app/components/atoms/Button/Button";
import produce from "immer";
import { useQueryClient } from "@tanstack/react-query";
import {
  applyLegCalculations,
  createAirwayFromQuery,
  getInitialValue,
  getSubmitButtonText,
  isCalculationType,
  isSubmitDisabled,
} from "@app/components/organisms/LegEditorForm/LegFlightRecalculate/avia.utils";
import { RadioValue } from "@app/components/organisms/LegEditorForm/LegFlightRecalculate/avia.types";
import { Loader } from "@app/components/organisms/LegEditorForm/components/Loader";
import { useActiveUser } from "@app/hooks/useActiveUser";

type Props = {
  leg: LegFormData;
  closeMenu: () => void;
  index: number;
  control?: Control<LegEditorFormData>;
  handleSplitLegByTechStop: (
    index: number,
    firstLeg: LegFormData,
    secondLeg: LegFormData,
  ) => void;
  updateLegFromCalculations: (index: number, leg: LegFormData) => void;
};

export const AviaPagesMenu = (props: Props) => {
  const {
    leg,
    control,
    index,
    handleSplitLegByTechStop,
    closeMenu,
    updateLegFromCalculations,
  } = props;
  const { t } = useTranslation();
  const { aviaResponse, isLoading, error } = useAviaCalculations({
    leg,
  });
  const { fields } = useFieldArray({
    control,
    name: "legs",
  });

  const [values, setValues] = useState<RadioValue[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [firstLeg, setFirstLeg] = useState<LegFormData>(leg);
  const [secondLeg, setSecondLeg] = useState<LegFormData | null>(null);
  const queryClient = useQueryClient();

  const user = useActiveUser();

  useEffect(() => {
    let initValues = undefined;
    if (!!aviaResponse) {
      initValues = getInitialValue(aviaResponse);
      setValues(initValues);
    }
    setShowLoader(
      !initValues ||
        initValues.length === 0 ||
        (initValues.length > 1 && !secondLeg),
    );
  }, [aviaResponse]);

  if (!isLoading && error) {
    return null;
  }

  const handleFlightTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: RadioValue,
  ) => {
    if (isCalculationType(newValue.value)) {
      const newValues = values.map((value) =>
        value.order === newValue.order
          ? ({
              type: "leg",
              value: newValue.value,
              order: newValue.order,
            } as const)
          : value,
      );
      setValues(newValues);
    } else {
      const newValues = values.map((value) =>
        value.order === newValue.order
          ? ({
              type: "techStop",
              value: newValue.value,
              order: newValue.order,
            } as const)
          : value,
      );
      setValues(newValues);
    }
  };

  const onSubmit = async () => {
    // get rid of the undesirable behaviour
    if (values.length < 1 || values.length > 2) return;

    if (values.length === 1) {
      const firstValue = values[0];

      if (firstValue.type === "leg" && !!aviaResponse) {
        const newLeg = await applyLegCalculations(
          firstValue,
          firstLeg,
          aviaResponse,
          user?.display_time_type ?? DisplayTimeTypes.AirportLocalTime,
        );

        setFirstLeg(newLeg);
        updateLegFromCalculations(index, newLeg);
        closeMenu();
      }

      if (firstValue.type === "techStop" && !!leg.aircraft) {
        const fetchedAirport = await api
          .getTechStopAirports({
            tech_stops: `${firstValue.value}`,
          })
          .then((res) => res.data);

        const splitField = produce<Partial<(typeof fields)[0]>>(
          fields[index],
          (draft) => {
            delete draft.id;
            if (!!draft.arrivalAirport)
              draft.arrivalAirport = fetchedAirport[0] as AirportDetailDto;
          },
        ) as LegFormData;

        setFirstLeg(splitField);

        const newLeg = {
          ...splitField,
          departureAirport: fetchedAirport[0],
          arrivalAirport: leg.arrivalAirport,
          departureDate: splitField.arrivalDate,
          departureTime: splitField.arrivalTime,
          arrivalDate: null,
          arrivalTime: null,
          isSplitLeg: true,
        } as LegFormData;

        setValues((prevState) => {
          if (prevState.length === 1 && !!aviaResponse) {
            return [
              ...getInitialValue(aviaResponse),
              { type: "techStop", value: "", order: 2 },
            ];
          }

          if (prevState.length === 2) {
            return prevState.map((value) =>
              value.order === 1
                ? value
                : { type: "leg", value: "airway", order: 2 },
            );
          }

          return prevState;
        });
        setSecondLeg(newLeg);
      }
    }

    if (values.length === 2 && !!secondLeg) {
      const cachedCalculations = queryClient.getQueryCache().getAll();
      const aviaResponses = cachedCalculations.filter(
        (cache) => cache.queryKey[0] === "aviaPagesCalculations",
      );

      const finalFirstLeg = await createAirwayFromQuery(
        firstLeg,
        aviaResponses,
        values[0],
        user?.display_time_type ?? DisplayTimeTypes.UTC,
      );

      const finalSecondLeg = await createAirwayFromQuery(
        secondLeg,
        aviaResponses,
        values[1],
        user?.display_time_type ?? DisplayTimeTypes.UTC,
      );

      handleSplitLegByTechStop(index, finalFirstLeg, finalSecondLeg);
      closeMenu();
    }
  };

  return (
    <>
      <HeaderContainer>
        <Typography variant="dialogHeading">
          {t("organisms.AviaPagesMenu.selectFlightTime")}
        </Typography>
      </HeaderContainer>
      <Content>
        <CalculationContainer>
          {showLoader && <Loader />}
          {!!values &&
            values.map((value, i) => {
              if (i > 0 && !secondLeg) {
                return null;
              }
              return (
                <AviaPagesCalculations
                  key={i}
                  leg={i === 0 ? firstLeg : secondLeg!}
                  handleFlightTimeChange={handleFlightTimeChange}
                  value={value}
                  setValue={setValues}
                />
              );
            })}
        </CalculationContainer>
        {values.length > 0 && (
          <StyledSaveButton
            onClick={onSubmit}
            disabled={isSubmitDisabled(values)}
          >
            {t(getSubmitButtonText(values))}
          </StyledSaveButton>
        )}
      </Content>
    </>
  );
};

const CalculationContainer = styled.div`
  display: flex;
`;

const HeaderContainer = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 0.8rem 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;
const StyledSaveButton = styled(Button)`
  margin-top: 20px;
  margin-right: 1.5rem;
  align-self: flex-end;
  width: fit-content;
`;
