import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { OutageFormData } from "@app/components/organisms/OutageForm/OutageForm";

import {
  getISOStringIgnoringTimezone,
  getLocalDateIgnoringTimezone,
} from "@app/utils/dateUtils";

import {
  CreateOutageDto,
  getCombinedDate,
  ScheduleDetailDto,
} from "@strafos/common";

dayjs.extend(utc);

export const transformScheduleItemToOutageFormData = (
  schedule: ScheduleDetailDto | null,
): OutageFormData | object => {
  if (!schedule || !schedule.aircraft) {
    return {};
  }

  const fromDateTime = getLocalDateIgnoringTimezone(schedule.departure_date);
  const toDateTime = getLocalDateIgnoringTimezone(schedule.arrival_date);

  return {
    label: schedule.label ?? "",
    fromDate: fromDateTime,
    fromTime: fromDateTime,
    toDate: toDateTime,
    toTime: toDateTime,
    aircraft: schedule.aircraft,
  };
};

export const transformOutageFormDataToCreateOutageDto = (
  formData: OutageFormData,
): CreateOutageDto => {
  const fromDateTime = getCombinedDate(formData.fromDate, formData.fromTime);

  const toDateTime = getCombinedDate(formData.toDate, formData.toTime);

  return {
    label: formData.label,
    departure_date: getISOStringIgnoringTimezone(fromDateTime),
    arrival_date: getISOStringIgnoringTimezone(toDateTime),
    aircraft_id: formData.aircraft?.id,
  };
};
