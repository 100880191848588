import React, { ChangeEvent, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import Select from "@app/components/atoms/Select/Select";
import Button from "@app/components/atoms/Button/Button";
import Typography from "@app/components/atoms/Typography/Typography";
import SimpleButton from "@app/components/atoms/SimpleButton/SimpleButton";

import { useYupValidationResolver } from "@app/hooks/useYupValidationResolver";
import { ClientDetailDto } from "@strafos/common";

interface SelectOption {
  id: number;
  label: string;
}

export interface SelectCustomerFormData {
  customer: SelectOption | null;
  contactPerson: SelectOption | null;
}

export interface SelectCustomerFormOutputData {
  customerId: number;
  contactPersonId: number;
}

export interface CustomerSelectorCreationStateProps {
  customers: ClientDetailDto[];
  onSubmit: (values: SelectCustomerFormOutputData) => void;
  onNewCustomerClick?: () => void;
  onCloseClick: () => void;
  onCustomerTextInputChange: (text: string) => void;
  defaultValues?: Partial<SelectCustomerFormData>;
  className?: string;
  disabled?: boolean;
}

const CustomerSelectorCreationState = ({
  defaultValues,
  customers,
  onSubmit,
  onNewCustomerClick,
  onCloseClick,
  onCustomerTextInputChange,
  className,
  disabled,
}: CustomerSelectorCreationStateProps): JSX.Element => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return yup.object({
      customer: yup
        .object({
          id: yup
            .number()
            .required(
              t("molecules.CustomerSelectorCreationState.customerRequired"),
            ),
        })
        .required(
          t("molecules.CustomerSelectorCreationState.customerRequired"),
        ),
      contactPerson: yup
        .object({
          id: yup
            .number()
            .required(
              t(
                "molecules.CustomerSelectorCreationState.contactPersonRequired",
              ),
            ),
        })
        .required(
          t("molecules.CustomerSelectorCreationState.contactPersonRequired"),
        ),
    });
  }, [t]);

  const validationResolver = useYupValidationResolver(validationSchema);

  const { handleSubmit, watch, control, setValue, getValues, formState } =
    useForm<SelectCustomerFormData>({
      defaultValues,
      resolver: validationResolver,
      mode: "onChange",
    });

  const customerValue = watch("customer");

  const contactPersons = useMemo(() => {
    if (!customerValue?.id) {
      return;
    }

    return customers.find((customer) => customer.id === customerValue.id)
      ?.contact_persons;
  }, [customerValue?.id]);

  const isContactPersonDisabled = !contactPersons;

  const customerOptions: SelectOption[] = useMemo(() => {
    if (!customers) {
      return [];
    }

    return customers.map(({ id, company_name }: ClientDetailDto) => ({
      id,
      label: company_name,
    }));
  }, [customers]);

  const contactPersonOptions: SelectOption[] = useMemo(() => {
    if (!customers || !contactPersons) {
      return [];
    }

    return contactPersons.map(({ id, name }) => {
      return {
        label: name,
        id,
      };
    });
  }, [customers, contactPersons]);

  const onCustomerInputChange = (event: ChangeEvent<object>) => {
    const getIsTargetInput = (
      event: ChangeEvent<object>,
    ): event is ChangeEvent<HTMLInputElement> =>
      !!(event as ChangeEvent<HTMLInputElement>)?.target;

    if (getIsTargetInput(event) && event.target.value) {
      onCustomerTextInputChange(event.target.value);
    }
  };

  const handleSubmitClick = (values: SelectCustomerFormData) => {
    if (!values.customer || !values.contactPerson) {
      return;
    }

    onSubmit({
      customerId: values.customer.id,
      contactPersonId: values.contactPerson.id,
    });
  };

  useEffect(() => {
    if (customerValue?.id) {
      if (
        !contactPersons?.some(
          (contactPerson) =>
            contactPerson.id === getValues()?.contactPerson?.id,
        )
      ) {
        setValue("contactPerson", null);
      }
    }
  }, [customerValue?.id]);

  return (
    <StyledForm
      onSubmit={handleSubmit(handleSubmitClick)}
      className={className}
    >
      <CloseIconButton color="primary" onClick={onCloseClick}>
        <Close />
      </CloseIconButton>
      <Heading>{t("molecules.CustomerSelectorCreationState.heading")}</Heading>
      <Box py={1}>
        <Controller<SelectCustomerFormData>
          name="customer"
          control={control}
          render={({ field: { value, onBlur, onChange, name } }) => {
            return (
              <StyledSelect
                classes={{
                  inputRoot: "Select__inputRoot",
                }}
                name={name}
                onChange={(event, option) => {
                  onChange(option);
                }}
                onInputChange={onCustomerInputChange}
                onBlur={onBlur}
                value={value || null}
                options={customerOptions}
                getOptionLabel={(row) => (row as SelectOption).label}
                disabled={disabled}
                getOptionSelected={(option, value) =>
                  (option as SelectOption)?.id === (value as SelectOption)?.id
                }
                inputProps={{
                  "data-testid":
                    "CustomerSelectorCreationState__customer-input",
                }}
              />
            );
          }}
        />
      </Box>
      <Box py={1}>
        <Controller<SelectCustomerFormData>
          name="contactPerson"
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => {
            return (
              <StyledSelect
                classes={{
                  inputRoot: "Select__inputRoot",
                }}
                name={name}
                onChange={(event, option) => {
                  onChange(option);
                }}
                onBlur={onBlur}
                value={value || null}
                options={contactPersonOptions}
                getOptionLabel={(row) => (row as SelectOption).label}
                disabled={isContactPersonDisabled || disabled}
                getOptionSelected={(option, value) =>
                  (option as SelectOption)?.id === (value as SelectOption)?.id
                }
                inputProps={{
                  "data-testid":
                    "CustomerSelectorCreationState__context-person-input",
                }}
              />
            );
          }}
        />
      </Box>
      <Controls>
        <div>
          {onNewCustomerClick && !disabled && (
            <SimpleButton
              onClick={onNewCustomerClick}
              data-testid="CustomerSelectorCreationState__create-customer-button"
            >
              {t("molecules.CustomerSelectorCreationState.createButtonText")}
            </SimpleButton>
          )}
        </div>
        <Button
          disabled={!formState.isValid || disabled}
          inverted
          type="submit"
          data-testid="CustomerSelectorCreationState__save-button"
        >
          {t("molecules.CustomerSelectorCreationState.saveButtonText")}
        </Button>
      </Controls>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: 2rem;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  position: relative;
`;

const Heading = styled(Typography)`
  font-weight: bold;
`;

const Controls = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  & .Select__inputRoot {
    padding-right: 0;
  }
`;

const CloseIconButton = styled(IconButton)`
  float: right;
`;

export default CustomerSelectorCreationState;
