import React from "react";
import { CellProps } from "react-table";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";

import Price from "@app/components/atoms/Price/Price";
import Typography from "@app/components/atoms/Typography/Typography";

import useGetAirportIdentificationCode from "@app/hooks/useGetAirportIdentificationCode";
import {
  SIMPLE_DURATION_FORMAT,
  OfferComputationResultItemDto,
} from "@strafos/common";

import DataGrid, {
  DataGridColumn,
  DataGridProps,
} from "@app/components/atoms/DataGrid/DataGrid";

export type AvailableAircraftDateGridItem = OfferComputationResultItemDto & {
  id: number;
};

interface AvailableAircraftDataGridProps
  extends Omit<
    DataGridProps<AvailableAircraftDateGridItem>,
    "columns" | "isSelectable" | "data" | "dataTestIdNamespace"
  > {
  data: AvailableAircraftDateGridItem[];
}

const AvailableAircraftDataGrid = ({
  data,
  ...props
}: AvailableAircraftDataGridProps): JSX.Element => {
  const { t } = useTranslation();

  const columns = React.useMemo<
    DataGridColumn<AvailableAircraftDateGridItem>[]
  >(
    () => [
      {
        id: "availableAircraft",
        title: t("molecules.AvailableAircraftDataGrid.aircraftName"),
        Cell: AircraftCell,
      },
      {
        id: "flightTime",
        title: t("molecules.AvailableAircraftDataGrid.flightTime"),
        Cell: FlightTimeCell,
      },
      {
        id: "position",
        title: t("molecules.AvailableAircraftDataGrid.aircraftPosition"),
        Cell: AircraftPositionCell,
      },
      {
        id: "profit",
        title: t("molecules.AvailableAircraftDataGrid.profit"),
        Cell: ProfitCell,
      },
      {
        id: "price",
        title: t("molecules.AvailableAircraftDataGrid.price"),
        Cell: PriceCell,
      },
      {
        title: t("molecules.AvailableAircraftDataGrid.profitRatio"),
        accessor: "profit_ratio",
        Cell: ProfitRatioCell,
      },
    ],
    [],
  );

  return (
    <DataGrid<AvailableAircraftDateGridItem>
      data={data}
      columns={columns}
      isSelectable
      dataTestIdNamespace="AvailableAircraftDataGrid"
      {...props}
    />
  );
};

const AircraftCell = ({
  cell,
}: CellProps<AvailableAircraftDateGridItem, undefined>) => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography variant="content">
        {cell.row.original.aircraft_code}
      </Typography>
      <Typography variant="subtitle">
        {cell.row.original.aircraft_name}
      </Typography>
    </Box>
  );
};

const ProfitCell = ({
  cell,
}: CellProps<AvailableAircraftDateGridItem, undefined>) => {
  return (
    <Price
      size="small"
      type={cell.row.original.profit >= 0 ? "profit" : "loss"}
      value={cell.row.original.profit}
    />
  );
};

const PriceCell = ({
  cell,
}: CellProps<AvailableAircraftDateGridItem, undefined>) => {
  return <Price size="small" value={cell.row.original.price} />;
};

const ProfitRatioCell = ({
  cell,
}: CellProps<AvailableAircraftDateGridItem, undefined>) => {
  return (
    <Typography variant="content">
      {cell.row.original.profit_ratio.toFixed(2)}%
    </Typography>
  );
};

const FlightTimeCell = ({
  cell,
}: CellProps<AvailableAircraftDateGridItem, undefined>) => {
  return (
    <Typography variant="content">
      {dayjs()
        .startOf("day")
        .minute(cell.row.original.occupied_flight_time_in_minutes)
        .format(SIMPLE_DURATION_FORMAT)}
    </Typography>
  );
};

const AircraftPositionCell = ({
  cell,
}: CellProps<AvailableAircraftDateGridItem, undefined>) => {
  const getAirportCode = useGetAirportIdentificationCode();

  const airportCode = getAirportCode({
    icao_code: cell.row.original.aircraft_position_icao,
    iata_code: cell.row.original.aircraft_position_iata,
  });

  return <Typography variant="content">{airportCode}</Typography>;
};

export default AvailableAircraftDataGrid;
