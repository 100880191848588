import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'

import Dialog from '@app/components/atoms/Dialog/Dialog'
import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'
import TextField from '@app/components/atoms/TextField/TextField'
import Button from '@app/components/atoms/Button/Button'
import Typography from '@app/components/atoms/Typography/Typography'
import { assertUnreachable } from '@strafos/common'

export enum UserNoteDialogVariant {
  Quotation = 'quotation',
  Booking = 'booking',
  Decline = 'decline',
}

interface UserNoteFormData {
  note?: string
}

interface UserNoteDialogProps {
  open: boolean
  onClose: () => void
  variant: UserNoteDialogVariant
  defaultValues?: UserNoteFormData
  onSubmit: (
    variant: UserNoteDialogVariant,
    note?: string,
    shouldSendEmail?: boolean,
    shouldSendToAvinode?: boolean,
  ) => void
}

type Wording = {
  heading: string
  text: string
  markButtonLabel: string
  sendButtonLabel: string
}

const UserNoteDialog = ({
  open,
  onSubmit,
  onClose,
  variant,
  defaultValues,
}: UserNoteDialogProps) => {
  const { t } = useTranslation()

  const { handleSubmit, formState, reset, control, watch } =
    useForm<UserNoteFormData>({
      defaultValues,
    })

  const noteValue = watch('note')

  const getSubmitHandler =
    (shouldSendEmail: boolean, shouldSendToAvinode: boolean) =>
    ({ note }: UserNoteFormData) => {
      onSubmit(variant, note || undefined, shouldSendEmail, shouldSendToAvinode)

      reset()

      onClose()
    }

  /**
   * @todo Remove the need to do this
   */
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  const { heading, text, markButtonLabel, sendButtonLabel } =
    useMemo<Wording>(() => {
      switch (variant) {
        case UserNoteDialogVariant.Quotation:
          return {
            heading: t('organisms.UserNoteDialog.quotationHeading'),
            text: t('organisms.UserNoteDialog.quotationText'),
            markButtonLabel: t('organisms.UserNoteDialog.markAsQuotation'),
            sendButtonLabel: t('organisms.UserNoteDialog.sendAsQuotation'),
          }

        case UserNoteDialogVariant.Booking:
          return {
            heading: t('organisms.UserNoteDialog.bookingHeading'),
            text: t('organisms.UserNoteDialog.bookingText'),
            markButtonLabel: t('organisms.UserNoteDialog.markAsBooking'),
            sendButtonLabel: t('organisms.UserNoteDialog.sendAsBooking'),
          }

        case UserNoteDialogVariant.Decline:
          return {
            heading: t('organisms.UserNoteDialog.declineHeading'),
            text: t('organisms.UserNoteDialog.declineText'),
            markButtonLabel: t('organisms.UserNoteDialog.markAsDecline'),
            sendButtonLabel: t('organisms.UserNoteDialog.sendAsDecline'),
          }

        default:
          assertUnreachable(variant)
      }
    }, [variant])

  return (
    <Dialog open={open} onClose={onClose}>
      <Content>
        <Typography variant="dialogHeading">{heading}</Typography>
        <StyledTypography>{text}</StyledTypography>
        <form onSubmit={handleSubmit(getSubmitHandler(true, true))}>
          <Controller<UserNoteFormData, 'note'>
            name="note"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <ErrorTooltip title={formState.errors.note?.message}>
                <TextField
                  value={value ?? ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!formState.errors.note}
                  placeholder={t('organisms.UserNoteDialog.notePlaceholder')}
                  rows={6}
                  multiline
                  fullWidth
                />
              </ErrorTooltip>
            )}
          />
          <ButtonsContainer>
            <Button type="submit">{sendButtonLabel}</Button>
            <Button
              inverted
              onClick={() => {
                getSubmitHandler(false, false)({ note: noteValue || undefined })
              }}
            >
              {markButtonLabel}
            </Button>
          </ButtonsContainer>
        </form>
      </Content>
    </Dialog>
  )
}

const Content = styled.div`
  padding: 2rem;
  min-width: 30rem;
`

const ButtonsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`

const StyledTypography = styled(Typography)`
  margin-bottom: 2rem;
  white-space: pre-wrap;
`

export default UserNoteDialog
