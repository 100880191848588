import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@app/components/atoms/Dialog/Dialog";
import Typography from "@app/components/atoms/Typography/Typography";
import Button from "@app/components/atoms/Button/Button";
import Tabs, { Tab, TabPanel } from "@app/components/atoms/Tabs/Tabs";

import { selectIsRebookingSearchLoading } from "@app/store/pages/requests/rebooking/rebooking.selectors";
import { selectUserInfo } from "@app/store/core/userInfo/userInfo.selectors";
import { useActiveOffer } from "@app/hooks/useActiveOffer";
import { getIsRoundtripFlight } from "@app/utils/requestUtils";

import { PartialRequestDto } from "@strafos/common";

import CreateRequestForm, {
  CreateRequestFormProps,
  CreateRequestFormVariant,
} from "@app/components/organisms/CreateRequestForm/CreateRequestForm";

import {
  initRebookingAction,
  resetRebookingStateAction,
} from "@app/store/pages/requests/rebooking/rebooking.actions";

interface RebookingRequestDialogProps {
  onClose: () => void;
  open: boolean;
  data: PartialRequestDto;
}

const RebookingRequestDialog = ({
  open,
  onClose,
  data,
}: RebookingRequestDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(getIsRoundtripFlight(data) ? 1 : 0);

  const activeOffer = useActiveOffer();

  const userInfo = useSelector(selectUserInfo);
  const isRebookingSearchLoading = useSelector(selectIsRebookingSearchLoading);

  const submitRef = useRef<() => void>();

  const onRebookingSearchSubmit: CreateRequestFormProps["onSubmit"] = (
    data,
  ) => {
    if (!activeOffer) {
      throw new Error(`Active offer is required`);
    }

    dispatch(resetRebookingStateAction());

    dispatch(initRebookingAction(activeOffer.id, data.requests));
  };

  return (
    <StyledDialog
      classes={{ paper: "Dialog__paper" }}
      open={open}
      onClose={onClose}
      disableEnforceFocus
    >
      <HeaderContainer>
        <Typography variant="dialogHeading">
          {t("organisms.RebookingRequestDialog.heading")}
        </Typography>
        <Tabs
          value={tabIndex}
          onChange={(event, tabIndex) => setTabIndex(tabIndex)}
        >
          <StyledTab
            label={t("organisms.CreateRequestDialog.singleOrMultiLeg")}
            data-testid="CreateRequestDialog__single-or-multi-leg-tab"
          />
          <StyledTab label={t("organisms.CreateRequestDialog.roundTrip")} />
        </Tabs>
      </HeaderContainer>
      <Content>
        <Container>
          <StyledTabPanel value={tabIndex} index={0}>
            <CreateRequestForm
              onSubmit={onRebookingSearchSubmit}
              submitRef={submitRef}
              request={data}
              timeDisplay={userInfo?.display_time_type ?? undefined}
              variant={CreateRequestFormVariant.OneWayTrip}
            />
          </StyledTabPanel>
          <StyledTabPanel value={tabIndex} index={1}>
            <CreateRequestForm
              onSubmit={onRebookingSearchSubmit}
              submitRef={submitRef}
              request={data}
              timeDisplay={userInfo?.display_time_type ?? undefined}
              variant={CreateRequestFormVariant.RoundTrip}
            />
          </StyledTabPanel>

          <ButtonsContainer>
            <Button
              data-testid="RebookingRequestDialog__submit-button"
              loading={isRebookingSearchLoading}
              onClick={() => submitRef?.current?.()}
            >
              {t("organisms.RebookingRequestDialog.submit")}
            </Button>
          </ButtonsContainer>
        </Container>
      </Content>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  .Dialog__paper {
    padding: 0;
    min-width: 50rem;
    min-height: 30rem;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  margin-bottom: 1rem;
  padding: 2rem 2rem 0 2rem;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 2rem;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  & > * {
    margin-right: 0.5rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

const StyledTab = styled(Tab)`
  &.Tab__selected {
    background: ${({ theme }) => theme.palette.common.white};
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
`;

export default RebookingRequestDialog;
