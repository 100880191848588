import React, { FocusEventHandler, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Popper from "@material-ui/core/Popper";
import PlaceOutlined from "@material-ui/icons/PlaceOutlined";

import EntityPicker, {
  EntityPickerProps,
} from "@app/components/atoms/EntityPicker/EntityPicker";

import { api } from "@app/utils/api/api";
import useGetAirportIdentificationCode from "@app/hooks/useGetAirportIdentificationCode";
import { selectUserInfo } from "@app/store/core/userInfo/userInfo.selectors";
import { useSelector } from "react-redux";
import {
  AirportDetailDto,
  DisplayAirportIdentifications,
} from "@strafos/common";

export enum AirportPickerVariants {
  Condensed = "Condensed",
  Regular = "Regular",
}

export type AirportBase = Pick<
  AirportDetailDto,
  "id" | "icao_code" | "iata_code" | "name" | "city" | "timezone"
>;

export interface AirportPickerProps
  extends Omit<
    EntityPickerProps<AirportBase>,
    | "searchKey"
    | "availableItems"
    | "getOptionLabel"
    | "renderOption"
    | "variant"
    | "getNotFoundMessage"
    | "popupIcon"
    | "getErrorMessage"
    | "onTriggerSearch"
    | "fallbackKey"
  > {
  availableAirports?: AirportBase[];
  variant?: AirportPickerVariants;
  onBlur?: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
  >;
  onTransformSearch?: (val: string) => string;
}

// @todo Add airport_id prop and auto load display value
// @todo Request caching
const AirportPicker = ({
  availableAirports,
  placeholder,
  noOptionsText,
  variant = AirportPickerVariants.Regular,
  ...props
}: AirportPickerProps): JSX.Element => {
  const { t } = useTranslation();

  const userInfo = useSelector(selectUserInfo);

  const getAirportCode = useGetAirportIdentificationCode();

  const getOptionLabel = useCallback(
    (row: AirportBase) => {
      switch (variant) {
        case AirportPickerVariants.Condensed:
          return getAirportCode(row) ?? "";

        case AirportPickerVariants.Regular:
        default:
          return `${getAirportCode(row) ?? ""} - ${row.name}`;
      }
    },
    [variant],
  );

  const renderOption = useCallback(
    (row: AirportBase) => {
      switch (variant) {
        case AirportPickerVariants.Condensed:
          let option = row.icao_code;

          if (row.iata_code) {
            option += `, ${row.iata_code}`;
          }

          option += ` (${row.name}, ${row.city})`;

          return <OptionContainer>{option}</OptionContainer>;

        case AirportPickerVariants.Regular:
        default:
          return (
            <>
              <b>{getAirportCode(row)}</b> - {row.name}
            </>
          );
      }
    },
    [variant],
  );

  const shouldForcePopupIcon = useMemo(() => {
    switch (variant) {
      case AirportPickerVariants.Condensed:
        return true;

      case AirportPickerVariants.Regular:
      default:
        return false;
    }
  }, [variant]);

  const handleSearch = (search: string) => {
    return api.listAirports({
      q: search,
      airportCode:
        userInfo?.display_airport_identification ||
        DisplayAirportIdentifications.ICAO,
      limit: 120,
    });
  };
  const transformSearch = (val: string) => val.toUpperCase();

  const searchKey = getAirportCode({
    icao_code: "icao_code",
    iata_code: "iata_code",
  });

  return (
    <EntityPicker<AirportBase>
      searchKey={searchKey}
      fallbackKey={"icao_code"}
      onTriggerSearch={handleSearch}
      availableItems={availableAirports}
      getNotFoundMessage={(search: string) =>
        t("atoms.AirportPicker.airportNotFound", {
          registrationCode: search,
        })
      }
      getErrorMessage={() => t("atoms.AirportPicker.defaultErrorMessage")}
      placeholder={
        placeholder ??
        (userInfo?.display_airport_identification ===
        DisplayAirportIdentifications.IATA
          ? t("atoms.AirportPicker.placeholder_iata")
          : t("atoms.AirportPicker.placeholder_icao"))
      }
      noOptionsText={noOptionsText ?? t("atoms.AirportPicker.noOptions")}
      popupIcon={<StyledPlaceOutlinedIcon />}
      forcePopupIcon={shouldForcePopupIcon}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      PopperComponent={(props) => (
        <StyledPopper {...props} placement="bottom-start" />
      )}
      onTransformSearch={transformSearch}
      {...props}
    />
  );
};

// @todo How to reuse InputEndAdornment component?
const StyledPlaceOutlinedIcon = styled(PlaceOutlined)`
  width: 1.25rem;
`;

const OptionContainer = styled.div`
  font-size: 0.75rem;
`;

const StyledPopper = styled(Popper)`
  min-width: 20rem;
  z-index: 2000;
`;

export default AirportPicker;
