import { removeOfferDocumentIds } from '@app/store/pages/requests/requestDetail/requestDetail.actions'
import { selectOfferDocumentIds } from '@app/store/pages/requests/requestDetail/requestDetail.selectors'
import { api } from '@app/utils/api/api'
import { DocumentFileType, DocumentStatus } from '@strafos/common'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

type Document = {
  id: number
  type: DocumentFileType
}

function useDocumentStatus() {
  const [inProgress, setInProgress] = useState<Array<Document>>([])
  const downloadedDocuments = useRef<number[]>([])
  const documentCounter = useRef<Record<string, number>>({})
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const downloadCache = useRef<number[]>([])

  const checkDocument = useCallback(
    async ({ id }: Document) => {
      const removeDocumentFromQ = () => {
        dispatch(removeOfferDocumentIds(id))
        setInProgress((state) => state.filter((doc) => doc.id !== id))
      }

      const res = await queryClient.fetchQuery({
        queryKey: ['offer-document-status', id],
        queryFn: () => api.getDocumentStatus({ id }),
      })
      if (
        res.data?.status === DocumentStatus.CREATED &&
        !downloadCache.current.includes(id)
      ) {
        downloadCache.current.push(id)
        queryClient
          .fetchQuery({
            queryKey: ['offer-document-download', id],
            queryFn: () => api.downloadDocument({ id }),
          })
          .then((q) => {
            const link = document.createElement('a')
            const objectUrl = URL.createObjectURL(q.data)
            const filename = q.headers['content-disposition']
              .split('filename=')[1]
              .split(';')[0]

            link.href = objectUrl
            link.download = filename
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            //saveas
            removeDocumentFromQ()
          })
      } else {
        const currentCount = documentCounter.current?.[id] || 0
        documentCounter.current[id] = currentCount + 1
        if (currentCount > 100 || res.data?.status === DocumentStatus.ERROR) {
          enqueueSnackbar('An error occurred while creating the document', {
            variant: 'error',
          })
          removeDocumentFromQ()
        }
      }
    },
    [queryClient, dispatch],
  )

  useEffect(() => {
    const intDocx = setInterval(() => {
      inProgress
        .filter((doc) => doc.type === DocumentFileType.DOCx)
        .map(checkDocument)
    }, 500)

    const intPdf = setInterval(() => {
      inProgress
        .filter((doc) => doc.type === DocumentFileType.PDF)
        .map(checkDocument)
    }, 500)

    return () => {
      clearInterval(intDocx)
      clearInterval(intPdf)
    }
  }, [inProgress, queryClient, checkDocument])

  return useCallback(
    (document: Document) => {
      if (
        inProgress.includes(document) ||
        downloadedDocuments.current.includes(document.id)
      ) {
        return
      }

      setInProgress((state) => [...state, document])
    },
    [inProgress],
  )
}

export default function OfferDocuments() {
  const documentIds = useSelector(selectOfferDocumentIds)
  const downloadedDocuments = useRef<number[]>([])

  const addDocument = useDocumentStatus()

  useEffect(() => {
    const newDocumentIds = documentIds.filter(
      (d) => !downloadedDocuments.current.includes(d.id),
    )
    const newDocument = newDocumentIds[newDocumentIds.length - 1]
    if (newDocumentIds.length && newDocument) {
      addDocument(newDocument)
      downloadedDocuments.current.push(newDocument.id)
    }
  }, [documentIds])

  return null
}
