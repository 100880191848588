import Avatar from "@material-ui/core/Avatar";
import { RequestNoteDto } from "@strafos/common";
import React from "react";
import styled from "styled-components";

interface Props {
  author: RequestNoteDto["author"];
  className?: string;
}

export default function RequestNoteAvatar({ author, className }: Props) {
  return (
    <StyledAvatar
      className={className}
      title={`${author.first_name} ${author.last_name}`}
    >
      {author.first_name.charAt(0)}
      {author.last_name.charAt(0)}
    </StyledAvatar>
  );
}

const StyledAvatar = styled(Avatar)`
  font-size: 1rem;
`;
