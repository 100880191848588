import { DEFAULT_NUMBER_FORMAT } from '@strafos/common'
import format from 'number-format.js'

/**
 * @returns Width and height of the runway with the longest width in a given
 * string representing available runways
 *
 * @example
 *
 * getLongestRunwayDimensions('07 7093x147 ;08 7087x149 ft lts') // '7093x147'
 *
 * getLongestRunwayDimensions('NULL') // null
 */
export function getLongestRunwayDimensions(
  rawRunways: string | null,
): string | null {
  if (rawRunways === 'NULL' || !rawRunways || typeof rawRunways !== 'string') {
    return null
  }

  /**
   * Because the input usually comes raw from externally imported data we can't
   * guarantee the format and therefore that the execution won't fail
   *
   * @todo [Tech] Is it ok to add the try / catch here or would place of usage
   *              be more correct?
   */
  try {
    const runways = rawRunways.split(';')

    const [longestRunway] = runways
      .map((runway) => {
        const dimensions = runway.replace(/.+?(\d+x\d+).+/, '$1')

        const [_width, _height] = dimensions.split('x')

        const width = format(DEFAULT_NUMBER_FORMAT, Number(_width))
        const height = format(DEFAULT_NUMBER_FORMAT, Number(_height))

        return {
          text: `${width}x${height}`,
          width: Number(_width),
        }
      })
      .sort((a, b) => {
        return b.width - a.width
      })

    return longestRunway.text
  } catch (e) {
    /**
     * @todo [Tech] [Business] FE Error monitoring
     */
    return null
  }
}
