import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { usePrevious, useUpdateEffect } from "react-use";
import { FieldErrors } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import equal from "fast-deep-equal";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import produce from "immer";
import Big from "big.js";

import Box from "@material-ui/core/Box";

import OfferDetailTabPanelActions from "@app/components/organisms/RequestDetail/OfferDetailTabPanelActions/OfferDetailTabPanelActions";
import OfferSummary from "@app/components/organisms/RequestDetail/OfferSummary/OfferSummary";
import Typography from "@app/components/atoms/Typography/Typography";
import RemovedLegsList from "@app/components/organisms/RemovedLegsList/RemovedLegsList";
import PriceCalculation from "@app/components/molecules/PriceCalculation/PriceCalculation";
import ConfirmationDialog from "@app/components/molecules/ConfirmationDialog/ConfirmationDialog";
import ScheduleCalendarView from "@app/components/molecules/ScheduleCalendarView/ScheduleCalendarView";
import MarketplaceExtensionDialog from "@app/components/organisms/MarketplaceExtensionDialog/MarketplaceExtensionDialog";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Logo from "@app/components/atoms/Logo/Logo";

import LegEditorForm, {
  LegEditorFormData,
  LegEditorProps,
} from "@app/components/organisms/LegEditorForm/LegEditorForm";

import LegPriceBreakdown, {
  AirportFeeInputIds,
  LegPriceBreakdownProps,
} from "@app/components/organisms/LegPriceBreakdown/LegPriceBreakdown";

import CopyAirportFeeDialog, {
  CopyAirportFeeDialogProps,
} from "@app/components/pages/Airports/AirportFeesTab/CopyAirportFeeDialog";

import { useDebounce } from "@app/hooks/useDebounce";
import { postCustomRouteAction } from "@app/store/api/customRoutes/customRoutes.actions";
import { selectSelectedOperator } from "@app/store/core/userOperators/userOperators.selectors";
import { selectUserInfo } from "@app/store/core/userInfo/userInfo.selectors";
import { useCanUser } from "@app/hooks/useCanUser";
import {
  getAreAirportFeesSameAsLegValues,
  getExtenralAppSources,
} from "@app/components/organisms/RequestDetail/OfferDetailTabPanelContent/utils";
import { CreateAirportFeeFormData } from "@app/components/pages/Airports/AirportFeesTab/CreateAirportFeeForm";
import { api } from "@app/utils/api/api";

import { openConfirmationDialogAction } from "@app/store/ui/confirmationDialog/confirmationDialog.actions";

import {
  getLegsFinalPrice,
  getLegsProfitMargin,
  getLegsTripCosts,
  getOfferFinalPrice,
  getOfferOptimizationProfit,
  getOfferProfitLoss,
  getOfferTotalProfit,
  getRemovedLegsCosts,
  castLegJunctureToDates,
  getLegsSortedByDepartureDate,
  offerTransitions,
  REQUIRED_LEG_TYPES,
  LegComputationRequest,
  getCancelledLegs,
  getLegsRemovedFromOffer,
  getOptimizedLegs,
  Actions,
  ComputationTypes,
  LegTypes,
  OfferFlags,
  OfferStatuses,
  OfferTypes,
  ScheduleSource,
  RequestDetailDto,
  BaseLegDetailDto,
  OfferDto,
  ScheduleDetailDto,
} from "@strafos/common";

import {
  cancelOfferAction,
  getLegEditorScheduleAction,
  getOfferRelatedAirportFees,
  getOfferRelatedCustomRoutesAction,
  getOfferScheduleAction,
  ignoreLegsFromOptimizationAction,
  patchOfferAction,
  recalculateOfferAction,
  resetIgnoreLegsFromOptimizationStateAction,
  resetRecalculationStateAction,
} from "@app/store/pages/requests/requestDetail/requestDetail.actions";

import {
  selectIgnoreLegsFromOptimizationData,
  selectIgnoreLegsFromOptimizationError,
  selectIsIgnoreLegsFromOptimizationLoading,
  selectIsLegEditorScheduleLoading,
  selectIsOfferCancellationLoading,
  selectIsPatchOfferLoading,
  selectIsRecalculationLoading,
  selectLegEditorSchedule,
  selectLegEditorScheduleError,
  selectOfferDetailError,
  selectOfferRecalculationData,
  selectOfferRelatedAirportFeesData,
  selectOfferRelatedCustomRoutesData,
  selectOfferSchedule,
} from "@app/store/pages/requests/requestDetail/requestDetail.selectors";

import {
  deleteMarketplaceExtensionAction,
  deleteOutageAction,
  patchMarketplaceExtensionAction,
  patchOutageAction,
} from "@app/store/pages/schedule/scheduleManagement/scheduleManagement.actions";

import {
  selectDeleteMarketplaceExtensionError,
  selectIsDeleteMarketplaceExtensionLoading,
  selectIsDeleteOutageLoading,
  selectIsPatchMarketplaceExtensionLoading,
  selectIsPatchOutageLoading,
  selectPatchMarketplaceExtensionError,
  selectPatchOutageError,
} from "@app/store/pages/schedule/scheduleManagement/scheduleManagement.selectors";

import {
  selectIsPatchCustomRouteLoading,
  selectIsPostCustomRouteLoading,
} from "@app/store/api/customRoutes/customRoutes.selectors";

import {
  selectIsPatchAirportFeeLoading,
  selectIsPostAirportFeeLoading,
} from "@app/store/api/airportFees/airportFees.selectors";
import { useGetReservationLegs } from "@app/hooks/useGetReservationLegs";
import OutageDialog from "@app/components/organisms/OutageDialog/OutageDialog";

dayjs.extend(utc);
dayjs.extend(duration);

const DEFAULT_REQUEST_DEBOUNCE_IN_MILLISECONDS = 500;

interface OfferDetailTabPanelContentProps {
  request: RequestDetailDto;
  offer: OfferDto;
  onRebookingButtonClick: () => void | Promise<void>;
  onShowMapButtonClick?: () => void;
}

interface ConfirmationDialogState {
  isOpen: boolean;
  hasWarnings: boolean;
}

const OfferDetailTabPanelContent = ({
  request,
  offer,
  onRebookingButtonClick,
  onShowMapButtonClick,
}: OfferDetailTabPanelContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const debounce = useDebounce();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const canUserUpdateOfferStatus = canUser(Actions.UpdateOfferStatus);
  const canUserUpdateOffer = canUser(Actions.UpdateOffer);
  const canUserCancelOffer = canUser(Actions.CancelOffer);
  const canUserDisplaySchedule = canUser(Actions.GetSchedule);

  const canUserUpdateMarketplaceExtension = canUser(
    Actions.UpdateMarketplaceExtension,
  );

  const canUserDeleteMarketplaceExtension = canUser(
    Actions.DeleteMarketplaceExtension,
  );

  const [marketplaceLegToUpdateId, setMarketplaceLegToUpdateId] = useState<
    number | null
  >(null);

  const [airportFeeSourceLegIndex, setAirportFeeSourceLegIndex] = useState<
    number | null
  >(null);

  const selectedOperator = useSelector(selectSelectedOperator);
  const userInfo = useSelector(selectUserInfo);

  const offerRecalculationData = useSelector(selectOfferRecalculationData);
  const isLoading = useSelector(selectIsRecalculationLoading);
  const isPatchLoading = useSelector(selectIsPatchOfferLoading);
  const error = useSelector(selectOfferDetailError);
  const isPostCustomRouteLoading = useSelector(selectIsPostCustomRouteLoading);
  const isPatchCustomRouteLoading = useSelector(
    selectIsPatchCustomRouteLoading,
  );
  const isPostAirportFeeLoading = useSelector(selectIsPostAirportFeeLoading);
  const isPatchAirportFeeLoading = useSelector(selectIsPatchAirportFeeLoading);

  const isOfferCancellationLoading = useSelector(
    selectIsOfferCancellationLoading,
  );

  const isIgnoreLegsFromOptimizationLoading = useSelector(
    selectIsIgnoreLegsFromOptimizationLoading,
  );

  const ignoreLegsFromOptimizationData = useSelector(
    selectIgnoreLegsFromOptimizationData,
  );

  const ignoreLegsFromOptimizationError = useSelector(
    selectIgnoreLegsFromOptimizationError,
  );

  const schedule = useSelector(selectOfferSchedule);
  const legEditorSchedule = useSelector(selectLegEditorSchedule);
  const isLegEditorScheduleLoading = useSelector(
    selectIsLegEditorScheduleLoading,
  );
  const legEditorScheduleError = useSelector(selectLegEditorScheduleError);

  const isPatchMarketplaceExtensionLoading = useSelector(
    selectIsPatchMarketplaceExtensionLoading,
  );

  const patchMarketplaceExtensionError = useSelector(
    selectPatchMarketplaceExtensionError,
  );

  const isDeleteMarketplaceExtensionLoading = useSelector(
    selectIsDeleteMarketplaceExtensionLoading,
  );

  const deleteMarketplaceExtensionError = useSelector(
    selectDeleteMarketplaceExtensionError,
  );

  const offerRelatedCustomRoutes = useSelector(
    selectOfferRelatedCustomRoutesData,
  );

  const offerRelatedAirportFees = useSelector(
    selectOfferRelatedAirportFeesData,
  );

  const canUserUpdateOutage = canUser(Actions.UpdateOutage);
  const canUserDeleteOutage = canUser(Actions.DeleteOutage);

  const [outageToUpdateId, setOutageToUpdateId] = useState<number | null>(null);

  const outageToUpdate = schedule?.find(
    (schedule) => schedule.id === outageToUpdateId,
  );

  const isPatchOutageLoading = useSelector(selectIsPatchOutageLoading);
  const patchOutageError = useSelector(selectPatchOutageError);
  const isOutageRemoveLoading = useSelector(selectIsDeleteOutageLoading);

  useEffect(() => {
    if (!isPatchOutageLoading && !patchOutageError) {
      setOutageToUpdateId(null);
    }
  }, [isPatchOutageLoading]);

  const marketplaceLegToUpdate = schedule?.find(
    (schedule) => schedule.id === marketplaceLegToUpdateId,
  );

  const activeOfferLegs = offer.legs.filter(
    (leg) => leg.type !== LegTypes.Removed,
  );

  const removedOfferLegs = offer.legs.filter(
    (leg) => leg.type === LegTypes.Removed,
  );

  const [confirmationDialogState, setConfirmationDialogState] =
    useState<ConfirmationDialogState>({
      isOpen: false,
      hasWarnings: false,
    });

  const [touchedFeesLegFieldsMap, setTouchedFeesLegFieldsMap] = useState<
    Record<number, AirportFeeInputIds[]>
  >({});

  const [currentLegs, setCurrentLegs] =
    useState<BaseLegDetailDto[]>(activeOfferLegs);

  const [legEditorErrors, setLegEditorErrors] =
    useState<FieldErrors<LegEditorFormData> | null>(null);

  const isLegEditorError =
    legEditorErrors === null ? false : Object.keys(legEditorErrors).length > 0;

  const [removedLegsByUser, setRemovedLegsByUser] = useState<
    BaseLegDetailDto[]
  >([]);

  const removedLegs: BaseLegDetailDto[] = removedLegsByUser.concat(
    ignoreLegsFromOptimizationData
      ? ignoreLegsFromOptimizationData.filter(
          (leg) => leg.type === LegTypes.Removed,
        )
      : removedOfferLegs,
  );

  const initialAirportFeeFormData = useMemo<
    CopyAirportFeeDialogProps["data"] | null
  >(() => {
    if (airportFeeSourceLegIndex === null) {
      return null;
    }

    const airportFeeSourceLeg = currentLegs[airportFeeSourceLegIndex];

    const nextFee = {
      matcher: airportFeeSourceLeg.arrival_airport.icao_code,
      aircraft_id: offer.aircraft.id,
      airport_fee: airportFeeSourceLeg.airport_fee,
      handling_fee: airportFeeSourceLeg.handling_fee,
      departure_fee: 0,

      arrival_fee: Big(airportFeeSourceLeg.arrival_fee)
        .div(airportFeeSourceLeg.passenger_count || 1)
        .round()
        .toNumber(),
    };

    const departureFeeLeg = currentLegs.find(
      (leg) =>
        leg.departure_airport_id === airportFeeSourceLeg.arrival_airport_id,
    );

    if (departureFeeLeg?.departure_fee) {
      nextFee.departure_fee = Big(departureFeeLeg.departure_fee)
        .div(departureFeeLeg.passenger_count || 1)
        .round()
        .toNumber();
    }

    return nextFee;
  }, [airportFeeSourceLegIndex, currentLegs]);

  const [copyAirportFeeData, setCopyAirportFeeData] = useState<
    CopyAirportFeeDialogProps["data"] | null
  >(initialAirportFeeFormData);

  const [isCopyAirportFeeDialogOpen, setIsCopyAirportFeeDialogOpen] =
    useState(false);

  useEffect(() => {
    setCopyAirportFeeData(initialAirportFeeFormData);
  }, [initialAirportFeeFormData]);

  type UpdateData = CreateAirportFeeFormData & { id: number };

  const updateAirportFeeMutation = useMutation(
    (updateData: UpdateData) => {
      const { id, ...data } = updateData;

      return api.updateAirportFee(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["airport-fees"]);

        setIsCopyAirportFeeDialogOpen(false);

        enqueueSnackbar(
          t("OfferDetailTabPanelContent.update_airport_fee_success"),
          { variant: "success" },
        );
      },
      onError: () => {
        enqueueSnackbar(
          t("OfferDetailTabPanelContent.update_airport_fee_failure"),
          { variant: "error" },
        );
      },
    },
  );

  const createAirportFeeMutation = useMutation(
    (formData: CreateAirportFeeFormData) => {
      return api.createAirportFee({
        matcher: formData.matcher,
        aircraft_id: formData.aircraft_id,
        airport_fee: formData.airport_fee,
        handling_fee: formData.handling_fee,
        departure_fee: formData.departure_fee,
        arrival_fee: formData.arrival_fee,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["airport-fees"]);

        setIsCopyAirportFeeDialogOpen(false);

        enqueueSnackbar(
          t("OfferDetailTabPanelContent.create_airport_fee_success"),
          { variant: "success" },
        );
      },

      onError: (error, data) => {
        if (
          axios.isAxiosError(error) &&
          error.response?.status === 409 &&
          error.response?.data.conflicting_resource_id
        ) {
          dispatch(
            openConfirmationDialogAction({
              shouldCloseOnSubmit: true,
              i18nextKey: t(
                "OfferDetailTabPanelContent.confirm_airport_fee_override",
              ),
              onSubmit: () =>
                updateAirportFeeMutation.mutate({
                  id: error.response?.data.conflicting_resource_id,
                  ...data,
                }),
            }),
          );

          return;
        }

        enqueueSnackbar(
          t("OfferDetailTabPanelContent.create_airport_fee_failure"),
          { variant: "error" },
        );
      },
    },
  );

  const [isScheduleOutOfSync, setIsScheduleOutOfSync] = useState(false);
  const [scheduleViewBoundaries, setScheduleViewBoundaries] = useState([
    dayjs.utc(offer.legs[0]?.departure_date).subtract(1, "day").toDate(),
    dayjs
      .utc(offer.legs[offer.legs.length - 1]?.arrival_date)
      .add(1, "day")
      .toDate(),
  ]);

  const [scheduleBoundaryStart, scheduleBoundaryEnd] = scheduleViewBoundaries;

  const [isLegEditorDirty, setIsLegEditorDirty] = useState(false);

  const isOfferReadonly = [
    OfferStatuses.Quoted,
    OfferStatuses.Booked,
    OfferStatuses.BookedCancelled,
    OfferStatuses.Storno,
    OfferStatuses.Cancelled,
    OfferStatuses.Declined,
    OfferStatuses.Rejected,
  ].includes(offer.status);

  const isLegEditorReadonly =
    isOfferReadonly || isLoading || !canUserUpdateOffer;

  const isBookedChangeAvailable =
    [OfferStatuses.Booked, OfferStatuses.BookedCancelled].includes(
      offer.status,
    ) && canUserUpdateOffer;

  const isPriceBreakdownReadonly =
    isLegEditorDirty || isOfferReadonly || isLoading || !canUserUpdateOffer;

  const isAnyOfferLegChanged = !equal(currentLegs, activeOfferLegs);
  const isAnyRemovedLegAdded = !!removedLegsByUser.length;

  // get offer schedule when recalculation is done
  useEffect(() => {
    dispatch(
      getOfferScheduleAction(
        offer.aircraft.registration_code,
        scheduleBoundaryStart.toISOString(),
        scheduleBoundaryEnd.toISOString(),
      ),
    );
  }, [
    offerRecalculationData,
    offer,
    scheduleBoundaryStart,
    scheduleBoundaryEnd,
  ]);

  const scheduleWithRemovedLegs = schedule?.map(
    produce<ScheduleDetailDto>((draft) => {
      const isRemoved = removedLegs?.some(
        (removedLeg) => removedLeg.remove_leg_id === draft.id,
      );

      if (isRemoved) {
        draft.type = LegTypes.Removed;
      }
    }),
  );

  const { data: scheduleData } = useGetReservationLegs({
    registrationCode: offer.aircraft.registration_code,
    from: scheduleBoundaryStart.toISOString(),
    to: scheduleBoundaryEnd.toISOString(),
  });

  const scheduleWithReservedLegs = useMemo(() => {
    const offerLegs = offer.reserved
      ? offer.legs.map((leg) => ({
          ...leg,
          leg_id: leg.id,
          source: ScheduleSource.STRAFOS,
          aircraft: offer.aircraft,
          reserved: true,
        }))
      : [];

    return (scheduleWithRemovedLegs || [])
      .concat(
        (scheduleData?.data?.schedule || []).map((leg) => ({
          ...leg,
          leg_id: leg.id,
          source: ScheduleSource.STRAFOS,
          aircraft: offer.aircraft,
          reserved: true,
        })),
      )
      .concat(offerLegs);
  }, [scheduleWithRemovedLegs, offer, currentLegs, scheduleData?.data]);

  const combinedLegs = currentLegs.concat(removedLegs);

  const isCancellationOffer = offer.type === OfferTypes.Cancellation;
  const isRebookingOffer = offer.type === OfferTypes.Rebooking;

  const profitMargin = isCancellationOffer
    ? getLegsProfitMargin(combinedLegs).times(-1)
    : getLegsProfitMargin(combinedLegs);

  const tripCosts = isCancellationOffer
    ? getLegsTripCosts(combinedLegs).times(-1)
    : getLegsTripCosts(combinedLegs);

  const removedLegsCost = isCancellationOffer
    ? getRemovedLegsCosts(combinedLegs).times(-1)
    : getRemovedLegsCosts(combinedLegs);

  /**
   * @todo Remove
   */
  const basePartialOffer = {
    aircraft: offer.aircraft,
    legs: combinedLegs,
    cancellation_offer_id: offer.cancellation_offer_id,
    cancellation_offer: offer.cancellation_offer,
    rebooking_offer_id: offer.rebooking_offer_id,
    rebooking_offer: offer.rebooking_offer,
    type: offer.type,
  };

  const finalPrice = isCancellationOffer
    ? getOfferFinalPrice(basePartialOffer).times(-1)
    : getOfferFinalPrice(basePartialOffer);

  const [nextFinalPrice, setNextFinalPrice] = useState(finalPrice.toNumber());

  useEffect(() => {
    setNextFinalPrice(finalPrice.toNumber());
  }, [finalPrice.toNumber()]);

  const cancellationFinalPrice = finalPrice.abs().toNumber();

  const [cancellationFee, setCancellationFee] = useState(
    isCancellationOffer
      ? (offer.cancellation_fee ?? cancellationFinalPrice)
      : null,
  );

  useEffect(() => {
    if (isCancellationOffer && !Number.isFinite(offer.cancellation_fee)) {
      setCancellationFee(cancellationFinalPrice);
    }
  }, [cancellationFinalPrice]);

  useUpdateEffect(() => {
    const legs = getLegsSortedByDepartureDate(
      offer.legs.map(castLegJunctureToDates),
    );

    /**
     * @todo [Tech] Use Array.prototype.at after upgrading TS
     */
    const firstLeg = legs[0];
    const lastLeg = legs[legs.length - 1];

    setScheduleViewBoundaries([
      dayjs.utc(firstLeg?.departure_date).subtract(1, "day").toDate(),
      dayjs.utc(lastLeg?.arrival_date).add(1, "day").toDate(),
    ]);
  }, [offer.status, offer.legs]);

  const isCancellationFeeChanged =
    isCancellationOffer && offer.cancellation_fee !== cancellationFee;

  const partialOffer = {
    ...basePartialOffer,
    cancellation_fee: cancellationFee,
  };

  const profitLoss =
    isCancellationOffer || isRebookingOffer
      ? getOfferProfitLoss(partialOffer).times(-1)
      : null;

  const optimizationProfit = getOfferOptimizationProfit(partialOffer);
  const totalProfit = getOfferTotalProfit(partialOffer);

  useEffect(() => {
    setCurrentLegs(activeOfferLegs);
  }, [JSON.stringify(activeOfferLegs)]);

  useEffect(() => {
    if (!offerRecalculationData) {
      return;
    }

    setCurrentLegs(offerRecalculationData);
    // @see https://stackoverflow.com/a/59468261/3210641
  }, [JSON.stringify(offerRecalculationData)]);

  const previousScheduleBoundaryStart = usePrevious(scheduleBoundaryStart);
  const previousScheduleBoundaryEnd = usePrevious(scheduleBoundaryEnd);

  useEffect(() => {
    const currentDuration = dayjs.duration(
      dayjs(scheduleBoundaryEnd).diff(scheduleBoundaryStart),
    );

    const previousDuration = dayjs.duration(
      dayjs(previousScheduleBoundaryEnd).diff(previousScheduleBoundaryStart),
    );

    const isCurrentDurationShorter =
      currentDuration.asMilliseconds() < previousDuration.asMilliseconds();

    // We don't need to refetch the offer schedule on zoom in because we can
    // be sure that the required data is already loaded and up to date
    if (isCurrentDurationShorter) {
      return;
    }

    setIsScheduleOutOfSync(true);

    debounce(() => {
      if (isPatchOutageLoading || isOutageRemoveLoading) {
        return;
      }
      dispatch(
        getOfferScheduleAction(
          offer.aircraft.registration_code,
          scheduleBoundaryStart.toISOString(),
          scheduleBoundaryEnd.toISOString(),
        ),
      );

      setIsScheduleOutOfSync(false);
    }, DEFAULT_REQUEST_DEBOUNCE_IN_MILLISECONDS);
  }, [
    offer.aircraft.registration_code,
    offer.status,
    scheduleBoundaryStart,
    scheduleBoundaryEnd,
    isPatchOutageLoading,
    isOutageRemoveLoading,
  ]);

  useEffect(() => {
    if (
      !isPatchMarketplaceExtensionLoading &&
      !patchMarketplaceExtensionError
    ) {
      setMarketplaceLegToUpdateId(null);
    }
  }, [isPatchMarketplaceExtensionLoading]);

  useEffect(() => {
    setRemovedLegsByUser([]);
  }, [removedOfferLegs.length]);

  useUpdateEffect(() => {
    const isPatchRequestSuccessful =
      !isPatchMarketplaceExtensionLoading && !patchMarketplaceExtensionError;

    const isDeleteRequestSuccessful =
      !isDeleteMarketplaceExtensionLoading && !deleteMarketplaceExtensionError;

    if (isPatchRequestSuccessful || isDeleteRequestSuccessful) {
      dispatch(
        getOfferScheduleAction(
          offer.aircraft.registration_code,
          scheduleBoundaryStart.toISOString(),
          scheduleBoundaryEnd.toISOString(),
        ),
      );
    }
  }, [isPatchMarketplaceExtensionLoading, isDeleteMarketplaceExtensionLoading]);

  useEffect(() => {
    return () => {
      dispatch(resetRecalculationStateAction());
      dispatch(resetIgnoreLegsFromOptimizationStateAction());
    };
  }, []);

  useEffect(() => {
    if (!ignoreLegsFromOptimizationData) {
      return;
    }

    setCurrentLegs(
      ignoreLegsFromOptimizationData.filter(
        (leg) => leg.type !== LegTypes.Removed,
      ),
    );
    // @see https://stackoverflow.com/a/59468261/3210641
  }, [JSON.stringify(ignoreLegsFromOptimizationData)]);

  const onPriceBreakdownLegChange: LegPriceBreakdownProps["onChange"] = (
    index,
    id,
    value,
  ) => {
    const airportFeesRelatedKeys: (keyof BaseLegDetailDto)[] = [
      AirportFeeInputIds.AirportFee,
      AirportFeeInputIds.HandlingFee,
      AirportFeeInputIds.ArrivalFee,
    ];

    const getIsInputIdRelatedToAirportFee = (
      id: keyof BaseLegDetailDto,
    ): id is AirportFeeInputIds =>
      Object.values(AirportFeeInputIds).includes(id as AirportFeeInputIds);

    const isInputIdRelatedToAirportFee = getIsInputIdRelatedToAirportFee(id);

    if (isInputIdRelatedToAirportFee && airportFeesRelatedKeys.includes(id)) {
      setTouchedFeesLegFieldsMap((current) => {
        const currentValueForGivenLeg = current[index] ?? [];
        const nextValueForGivenLeg = [...currentValueForGivenLeg, id];

        return {
          ...current,
          [index]: Array.from(new Set(nextValueForGivenLeg)),
        };
      });
    }

    if (
      isInputIdRelatedToAirportFee &&
      id === AirportFeeInputIds.DepartureFee
    ) {
      const touchedLegIndices = currentLegs.reduce<number[]>(
        (acc, leg, idx) => {
          if (
            leg.arrival_airport_id !== currentLegs[index].departure_airport_id
          ) {
            return acc;
          }

          return [...acc, idx];
        },
        [],
      );

      if (touchedLegIndices.length) {
        setTouchedFeesLegFieldsMap((currentStateValue) => {
          return touchedLegIndices.reduce<Record<number, AirportFeeInputIds[]>>(
            (acc, currentLegIndex) => {
              if (!acc[currentLegIndex]) {
                return {
                  ...acc,
                  [currentLegIndex]: [AirportFeeInputIds.DepartureFee],
                };
              }

              const currentValueForGivenLeg = acc[currentLegIndex] ?? [];

              const nextValueForGivenLeg = [
                ...currentValueForGivenLeg,
                AirportFeeInputIds.DepartureFee,
              ];

              return {
                ...acc,
                [currentLegIndex]: Array.from(new Set(nextValueForGivenLeg)),
              };
            },
            currentStateValue,
          );
        });
      }
    }

    // @todo How to do this using immer ???
    setCurrentLegs(
      currentLegs.map((priceBreakdownLeg, priceBreakdownLegIndex) => {
        if (index !== priceBreakdownLegIndex) {
          return priceBreakdownLeg;
        }

        return {
          ...priceBreakdownLeg,
          [id]: value,
        };
      }),
    );
  };

  const onResetButtonClick = () => {
    setCurrentLegs(activeOfferLegs);

    if (offer.cancellation_fee) {
      setCancellationFee(offer.cancellation_fee);
    }
  };

  const onSaveOffer = () => {
    dispatch(
      patchOfferAction(offer.id, {
        cancellation_fee: cancellationFee,
        legs: currentLegs.concat(removedLegs),
      }),
    );
  };

  const onCancelButtonClick = () => {
    dispatch(cancelOfferAction(offer.id));
  };

  const onSaveButtonClick = () => {
    if (confirmationDialogState.hasWarnings) {
      return setConfirmationDialogState((currentState) => ({
        ...currentState,
        isOpen: true,
      }));
    }

    onSaveOffer();
  };

  const onLegEditorFormSubmit: LegEditorProps["onSubmit"] = (
    nextValues,
    warnings,
  ) => {
    setConfirmationDialogState((currentState) => ({
      ...currentState,
      hasWarnings: !!(warnings && Object.entries(warnings).length),
    }));

    const removedLegIds = removedLegs
      .map((removedLeg) => removedLeg.remove_leg_id)
      .filter(Boolean) as number[];

    dispatch(
      recalculateOfferAction(
        offer.aircraft.id,
        nextValues.requests,
        offer.id,
        isBookedChangeAvailable,
        removedLegIds,
      ),
    );
  };

  const onConfirmationDialogClose = () => {
    setConfirmationDialogState((currentState) => ({
      ...currentState,
      isOpen: false,
    }));
  };

  const onLegEditorFormIsDirtyChange: LegEditorProps["onIsDirtyChange"] = (
    nextIsDirty,
  ) => {
    setIsLegEditorDirty(nextIsDirty);
  };

  const onScheduleViewChange = (viewStart: Date, viewEnd: Date) => {
    setScheduleViewBoundaries([viewStart, viewEnd]);
  };

  const onMarkLegAsRemoved = (id: number) => {
    const legToRemove = schedule?.find(
      (scheduleItem) => scheduleItem.id === id,
    );

    if (!legToRemove) {
      throw new Error("Leg to remove has to be defined");
    }

    const { id: scheduleItemId, ...removedLegBase } = legToRemove;

    const nextRemovedLeg: BaseLegDetailDto = {
      ...removedLegBase,

      // @todo Can we set default to 0 ???
      offer_id: removedLegBase.offer_id ?? 0,

      type: LegTypes.Removed,
      remove_leg_id: scheduleItemId,
    };

    setRemovedLegsByUser((currentRemovedLegs) => [
      ...currentRemovedLegs,
      nextRemovedLeg,
    ]);
  };

  useEffect(() => {
    const isAnyDurationModified = currentLegs.some(
      (leg) => leg.duration_in_minutes !== leg.original_duration_in_minutes,
    );

    if (isAnyDurationModified) {
      const allAirportIds = currentLegs
        .filter(
          (leg) => leg.duration_in_minutes !== leg.original_duration_in_minutes,
        )
        .flatMap((leg) => [leg.departure_airport_id, leg.arrival_airport_id]);

      const uniqueAirportIds = Array.from(new Set(allAirportIds));

      dispatch(
        getOfferRelatedCustomRoutesAction(offer.aircraft.id, uniqueAirportIds),
      );
    }
  }, [
    JSON.stringify(currentLegs),
    isPostCustomRouteLoading,
    isPatchCustomRouteLoading,
  ]);

  useEffect(() => {
    if (Object.keys(touchedFeesLegFieldsMap).length) {
      const airportIds = new Set(
        Object.keys(touchedFeesLegFieldsMap).flatMap((legIndex) => [
          currentLegs[Number(legIndex)].departure_airport.icao_code,
          currentLegs[Number(legIndex)].arrival_airport.icao_code,
        ]),
      );

      dispatch(
        getOfferRelatedAirportFees(offer.aircraft.id, Array.from(airportIds)),
      );
    }
  }, [
    Object.keys(touchedFeesLegFieldsMap).length,
    isPostAirportFeeLoading,
    isPatchAirportFeeLoading,
  ]);

  const onIgnoreLegsFromOptimization = (ignoreLegIds: number[]) => {
    const removedLegIds = removedOfferLegs
      .map((curLeg) => curLeg.remove_leg_id)
      .filter(
        (curLegId): curLegId is number =>
          typeof curLegId === "number" &&
          !ignoreLegIds.some((curId) => curId === curLegId),
      );
    ignoreLegsFromOptimization(ignoreLegIds, removedLegIds);
  };

  const ignoreLegsFromOptimization = (
    ignoreLegIds: number[],
    deletedLegIds: number[] = [],
  ) => {
    if (!selectedOperator) {
      throw new Error(`Operator must be selected`);
    }

    const requests: LegComputationRequest[] = activeOfferLegs
      .filter((leg) => REQUIRED_LEG_TYPES.includes(leg.type))
      .map((leg) => {
        return {
          departure_airport_id: leg.departure_airport_id,
          arrival_airport_id: leg.arrival_airport_id,
          passenger_count: leg.passenger_count,
          type: leg.type,
          arrival_date: dayjs(leg.arrival_date).format(),
          departure_date: dayjs(leg.departure_date).format(),
        };
      });

    dispatch(
      ignoreLegsFromOptimizationAction({
        type: ComputationTypes.OfferCalculation,
        operator_id: selectedOperator?.id,
        cancellationOfferId: offer.cancellation_offer_id,
        ignore_leg_ids: ignoreLegIds,
        removed_leg_ids: deletedLegIds,
        aircraft_id: offer.aircraft.id,
        requests,
      }),
    );
  };

  const nextStates = offerTransitions[offer.status];

  const stringifiedRemovedLegs = JSON.stringify(removedLegs);
  const stringifiedCurrentLegs = JSON.stringify(currentLegs);
  const stringifiedOffer = JSON.stringify(offer);

  const optimizedLegs = useMemo(
    () =>
      getOptimizedLegs(
        removedLegs,
        offer.cancellation_offer_id,
        offer.rebooking_offer_id,
      ),
    [removedLegs, stringifiedOffer],
  );

  const cancelledLegs = useMemo(
    () =>
      getCancelledLegs(
        removedLegs,
        offer.cancellation_offer_id,
        offer.rebooking_offer_id,
      ),
    [stringifiedRemovedLegs, stringifiedOffer],
  );

  const displayLegs = useMemo(
    () =>
      getLegsSortedByDepartureDate(
        currentLegs
          .concat(getLegsRemovedFromOffer(removedLegs, offer.id))
          // @todo Remove the necessity to cast dates to Date objects
          .map((leg) => {
            return {
              ...leg,
              departure_date: new Date(leg.departure_date),
              arrival_date: new Date(leg.arrival_date),
            };
          }),
      ),
    [stringifiedCurrentLegs, stringifiedRemovedLegs, stringifiedOffer],
  );

  const isStatusTransitionDisabled =
    isAnyOfferLegChanged ||
    isAnyRemovedLegAdded ||
    isLegEditorDirty ||
    !canUserUpdateOfferStatus;

  const containsBookedOffer = request.offers.some((offer) =>
    [OfferStatuses.Booked, OfferStatuses.BookedCancelled].includes(
      offer.status,
    ),
  );

  const isRebookedOrCancelledOffer = offer.type !== OfferTypes.Regular;

  const filteredLegEditorSchedule = useMemo(() => {
    if (legEditorSchedule && displayLegs) {
      return legEditorSchedule.filter(
        (s) => !displayLegs.some((l) => l.id === s.leg_id),
      );
    }
    return [];
  }, [legEditorSchedule, displayLegs]);

  return (
    <>
      {(isIgnoreLegsFromOptimizationLoading ||
        offer?.status === OfferStatuses.Processing) && (
        <Overlay>
          <LoadingSpinner loading>
            <StyledLogo />
          </LoadingSpinner>
        </Overlay>
      )}

      <StyledOfferUpperContainer>
        <Box pr={4}>
          <OfferSummary
            offer={offer}
            totalProfit={totalProfit.round(0).toNumber()}
            aircraftRevenue={
              offer.type === OfferTypes.Cancellation
                ? (cancellationFee ?? 0)
                : getLegsFinalPrice(offer.legs).round(0).toNumber()
            }
          />
        </Box>

        {canUserDisplaySchedule && (
          <StyledScheduleWrapper py={2}>
            <ScheduleCalendarView
              schedule={scheduleWithReservedLegs ?? null}
              isLoading={!scheduleWithRemovedLegs}
              isReloading={isLoading || isScheduleOutOfSync}
              viewStart={scheduleBoundaryStart.toISOString()}
              viewEnd={scheduleBoundaryEnd.toISOString()}
              onViewChange={onScheduleViewChange}
              availableAircraft={[offer.aircraft]}
              showEmptyLines={false}
              error={error ?? ignoreLegsFromOptimizationError}
              requestTripId={request.trip_id}
              offerStatus={offer.status}
              onEditOutage={
                canUserUpdateOutage
                  ? (id) => setOutageToUpdateId(id)
                  : undefined
              }
              onRemoveOutage={
                canUserDeleteOutage
                  ? (id) => dispatch(deleteOutageAction(id))
                  : undefined
              }
              onMarkLegAsRemoved={
                isOfferReadonly ? undefined : onMarkLegAsRemoved
              }
              isMarketingLegsEnabled={
                selectedOperator?.is_marketing_legs_enabled
              }
              requestLegs={
                [OfferStatuses.Booked, OfferStatuses.BookedCancelled].includes(
                  offer.status,
                ) ||
                (offer.status === OfferStatuses.Quoted && !!offer.reserved)
                  ? undefined
                  : currentLegs
              }
              onEditMarketingLeg={
                canUserUpdateMarketplaceExtension
                  ? (id) => setMarketplaceLegToUpdateId(id)
                  : undefined
              }
              onRemoveMarketingLeg={
                canUserDeleteMarketplaceExtension
                  ? (id) => dispatch(deleteMarketplaceExtensionAction(id))
                  : undefined
              }
              onConvertToMarketingLeg={
                canUserUpdateMarketplaceExtension
                  ? (id, { departureDate, arrivalDate }) => {
                      dispatch(
                        patchMarketplaceExtensionAction(id, {
                          from: departureDate?.toISOString(),
                          to: arrivalDate?.toISOString(),
                        }),
                      );
                    }
                  : undefined
              }
              sources={
                selectedOperator
                  ? getExtenralAppSources(selectedOperator)
                  : [ScheduleSource.STRAFOS]
              }
            />
          </StyledScheduleWrapper>
        )}
      </StyledOfferUpperContainer>

      {outageToUpdateId && (
        <OutageDialog
          open={!!outageToUpdate}
          onClose={() => setOutageToUpdateId(null)}
          scheduleItem={outageToUpdate}
          isLoading={isPatchOutageLoading}
          onSubmit={(outage) =>
            dispatch(patchOutageAction(outageToUpdateId, outage))
          }
        />
      )}

      <Box py={2}>
        {cancelledLegs.length > 0 && (
          <Box mb="2rem" mr={2}>
            <Typography variant="sectionTitle">
              {t("organisms.OfferDetailTabPanelContent.cancelledLegs")}
            </Typography>
            <RemovedLegsList
              groupType={"cancelled"}
              offer={offer}
              legs={cancelledLegs}
              aircraft={offer.aircraft}
            />
          </Box>
        )}

        {optimizedLegs.length > 0 && (
          <Box mb="2rem" mr={2}>
            <Typography variant="sectionTitle">
              {t("organisms.OfferDetailTabPanelContent.optimizedLegs")}
            </Typography>
            <RemovedLegsList
              groupType={"optimized"}
              offer={offer}
              legs={optimizedLegs}
              aircraft={offer.aircraft}
              onIgnoreLegsFromOptimization={onIgnoreLegsFromOptimization}
            />
          </Box>
        )}

        <Box mr={2}>
          <Typography variant="sectionTitle">
            {t("organisms.OfferDetailTabPanelContent.calculatedLegs")}
          </Typography>
          {isLegEditorScheduleLoading && !legEditorSchedule && (
            <LoadingSpinner loading={true}>
              <StyledLogo />
            </LoadingSpinner>
          )}
          {legEditorSchedule && !legEditorScheduleError && (
            <LegEditorForm
              avinodeId={request.external_id}
              onLegEditorErrorsChange={(errors) => setLegEditorErrors(errors)}
              offer={offer}
              loading={isLoading}
              readonly={isBookedChangeAvailable ? false : isLegEditorReadonly}
              aircraft={offer.aircraft}
              legs={displayLegs}
              onSubmit={onLegEditorFormSubmit}
              onIsDirtyChange={onLegEditorFormIsDirtyChange}
              areCommonButtonsVisible={
                isBookedChangeAvailable ? false : !isOfferReadonly
              }
              schedule={filteredLegEditorSchedule}
              removedLegs={removedLegs}
              timeDisplay={userInfo?.display_time_type ?? undefined}
              onShowMapButtonClick={onShowMapButtonClick}
              offerRelatedCustomRoutes={offerRelatedCustomRoutes}
              onRequestTimeBoundariesChange={(from, to) =>
                dispatch(
                  getLegEditorScheduleAction(
                    offer.aircraft.registration_code,
                    from,
                    to,
                  ),
                )
              }
              isBookedChange={isBookedChangeAvailable}
            />
          )}
        </Box>

        {/* @todo Add LegPriceBreakdownForm */}
        <Box display="flex" pt={2}>
          <Box flex={1} display="flex" flexDirection="column" pr={1}>
            <Typography variant="sectionTitle">
              {t("organisms.OfferDetailTabPanelContent.legPriceBreakdownTitle")}
            </Typography>

            <Box flex={1}>
              {displayLegs.map((leg, index) => {
                const areAirportFeesSameAsLegValues =
                  getAreAirportFeesSameAsLegValues(
                    offerRelatedAirportFees,
                    leg,
                    touchedFeesLegFieldsMap[index] ?? [],
                  );

                const isAnyAirportFeesRelatedInputTouched = Object.keys(
                  touchedFeesLegFieldsMap,
                )
                  .map((key) => Number(key))
                  .includes(index);

                return (
                  <LegPriceBreakdown
                    readonly={isPriceBreakdownReadonly}
                    index={index}
                    key={index}
                    leg={leg}
                    aircraft={offer.aircraft}
                    offerStatus={offer.status}
                    onChange={onPriceBreakdownLegChange}
                    isEditAirportFeeIconVisible={Boolean(
                      isAnyAirportFeesRelatedInputTouched &&
                        !areAirportFeesSameAsLegValues,
                    )}
                    offerRelatedCustomRoutes={offerRelatedCustomRoutes}
                    onEditAirportFeesClick={() => {
                      setAirportFeeSourceLegIndex(index);
                      setIsCopyAirportFeeDialogOpen(true);
                    }}
                    onCreateCustomRouteClick={(postCustomRouteDto) =>
                      dispatch(postCustomRouteAction(postCustomRouteDto))
                    }
                  />
                );
              })}
            </Box>
          </Box>
          <Box flex={1} display="flex" flexDirection="column" pl={1}>
            <Typography variant="sectionTitle">
              {t("organisms.OfferDetailTabPanelContent.priceCalculationTitle")}
            </Typography>

            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              pt={2}
            >
              <StyledPriceCalculation
                optimizationProfit={optimizationProfit.toNumber()}
                profitMargin={profitMargin.toNumber()}
                removedLegsCost={removedLegsCost.toNumber()}
                totalProfit={totalProfit.toNumber()}
                tripCosts={tripCosts.toNumber()}
                profitLoss={profitLoss?.toNumber()}
                cancellationFee={cancellationFee ?? undefined}
                isProfitLossVisible={isCancellationOffer || isRebookingOffer}
                isCancellationFeeDisabled={isOfferReadonly}
                isCancellationFeeVisible={isCancellationOffer}
                onCancellationFeeChange={(nextCancellationFee) =>
                  void setCancellationFee(Number(nextCancellationFee))
                }
                calculatedPriceBySourceAmount={
                  offer.calculated_price_by_source_amount
                }
                calculatedPriceBySourceCurrency={
                  offer.calculated_price_by_source_currency
                }
                finalPrice={nextFinalPrice}
                isFinalPriceDisabled={isCancellationOffer || isOfferReadonly}
                onFinalPriceChange={(event, value) => {
                  if (value || value === 0) {
                    setNextFinalPrice(value === 0 ? 0 : value);
                  }
                }}
                onFinalPriceBlur={() => {
                  const requiredLegExists = currentLegs.some((leg) => {
                    return REQUIRED_LEG_TYPES.includes(leg.type);
                  });

                  const targetLegIndices = currentLegs
                    .map((leg, index) => {
                      const isTargetLeg = requiredLegExists
                        ? REQUIRED_LEG_TYPES.includes(leg.type)
                        : !REQUIRED_LEG_TYPES.includes(leg.type);

                      return isTargetLeg ? index : null;
                    })
                    .filter((index) => Number.isFinite(index));

                  const start = finalPrice.toNumber();
                  const end = nextFinalPrice;
                  const difference = end - start;

                  const count = targetLegIndices.length;
                  const fraction = difference / count;
                  const remainder = difference % count;

                  const result =
                    Math.sign(fraction) * Math.floor(Math.abs(fraction));

                  let isRemainderAdded = false;

                  setCurrentLegs(
                    currentLegs.map((leg, index) => {
                      return produce(leg, (draft) => {
                        if (targetLegIndices.includes(index)) {
                          let nextValue = result;

                          if (!isRemainderAdded) {
                            nextValue += remainder;

                            isRemainderAdded = true;
                          }

                          draft.profit += nextValue;
                        }
                      });
                    }),
                  );
                }}
              />
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <OfferDetailTabPanelActions
                  requestId={request.id}
                  onRebookingButtonClick={onRebookingButtonClick}
                  isRebookingButtonVisible={
                    offer.status === OfferStatuses.Booked
                  }
                  isDeclineButtonLoading={isPatchLoading}
                  isDeclineButtonVisible={nextStates.includes(
                    OfferStatuses.Declined,
                  )}
                  isDeclineButtonDisabled={isStatusTransitionDisabled}
                  isSaveButtonVisible={!isOfferReadonly}
                  isSaveButtonLoading={isPatchLoading}
                  isResetButtonVisible={!isOfferReadonly}
                  isResetButtonLoading={isPatchLoading}
                  isRejectButtonVisible={offer.status === OfferStatuses.Quoted}
                  isRejectButtonLoading={isPatchLoading}
                  isCancelButtonVisible={
                    OfferStatuses.Booked === offer.status && canUserCancelOffer
                  }
                  isCancelButtonLoading={isOfferCancellationLoading}
                  isReserveQuotationVisible={
                    offer.status === OfferStatuses.Quoted
                  }
                  areTransitionButtonsLoading={isPatchLoading}
                  onResetButtonClick={onResetButtonClick}
                  onSaveButtonClick={onSaveButtonClick}
                  onCancelButtonClick={onCancelButtonClick}
                  cancellationFee={cancellationFee ?? 0}
                  offer={offer}
                  isSaveButtonDisabled={
                    isPriceBreakdownReadonly ||
                    (!isAnyOfferLegChanged &&
                      !isAnyRemovedLegAdded &&
                      !isCancellationFeeChanged)
                  }
                  isRejectButtonDisabled={isStatusTransitionDisabled}
                  isResetButtonDisabled={
                    !isAnyOfferLegChanged &&
                    !isAnyRemovedLegAdded &&
                    !isCancellationFeeChanged
                  }
                  areTransitionButtonsVisible={
                    ![
                      OfferFlags.Conflict,
                      OfferFlags.AircraftUnavailable,
                    ].includes(offer.flag)
                  }
                  areTransitionButtonsDisabled={
                    isStatusTransitionDisabled ||
                    isLegEditorError ||
                    (!isRebookedOrCancelledOffer && containsBookedOffer)
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <ConfirmationDialog
        onClose={onConfirmationDialogClose}
        onCancel={onConfirmationDialogClose}
        onConfirm={() => {
          onSaveOffer();
          setConfirmationDialogState((currentState) => ({
            ...currentState,
            isOpen: false,
          }));
        }}
        open={confirmationDialogState.isOpen}
        dialogContent={
          <Box>
            <Typography variant="formTitle">
              {t(
                "organisms.OfferDetailTabPanelContent.saveWithWarningsConfirmationHeading",
              )}
            </Typography>
            <Typography>
              {t(
                "organisms.OfferDetailTabPanelContent.saveWithWarningsConfirmation",
              )}
            </Typography>
          </Box>
        }
      />
      {marketplaceLegToUpdate && (
        <MarketplaceExtensionDialog
          open={!!marketplaceLegToUpdate}
          onClose={() => setMarketplaceLegToUpdateId(null)}
          scheduleItem={marketplaceLegToUpdate}
          aircraftSchedule={schedule?.filter(
            (scheduleItem) =>
              scheduleItem.aircraft_id === marketplaceLegToUpdate.aircraft_id,
          )}
          onSubmit={(data) => {
            if (marketplaceLegToUpdateId) {
              dispatch(
                patchMarketplaceExtensionAction(marketplaceLegToUpdateId, data),
              );
            }
          }}
        />
      )}

      {copyAirportFeeData && (
        <CopyAirportFeeDialog
          data={copyAirportFeeData}
          isOpen={isCopyAirportFeeDialogOpen}
          onClose={() => setIsCopyAirportFeeDialogOpen(false)}
          onChange={(nextData) => setCopyAirportFeeData(nextData)}
          onSubmit={(data) => createAirportFeeMutation.mutate(data)}
        />
      )}
    </>
  );
};

const Overlay = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0.75;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const StyledScheduleWrapper = styled(Box)``;

const StyledOfferUpperContainer = styled(Box)`
  overflow-x: hidden;
`;

const StyledPriceCalculation = styled(PriceCalculation)`
  padding: 0 1rem;
  margin-bottom: 2rem;
`;

const StyledLogo = styled(Logo)`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 3rem;
  height: 3rem;
`;

export default OfferDetailTabPanelContent;
