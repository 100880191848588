import React, { ChangeEvent, FocusEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import formatNumber from 'number-format.js'

import Box from '@material-ui/core/Box'

import Typography from '@app/components/atoms/Typography/Typography'
import Price from '@app/components/atoms/Price/Price'

import TextField, {
  TextFieldPriceFormatter,
} from '@app/components/atoms/TextField/TextField'

import { DEFAULT_PRICE_FORMAT_WITHOUT_CURRENCY } from '@strafos/common'

interface PriceCalculationProps {
  className?: string

  onCancellationFeeChange: (nextValue: string) => void | Promise<void>

  isCancellationFeeVisible?: boolean
  isCancellationFeeDisabled?: boolean
  isProfitLossVisible?: boolean

  calculatedPriceBySourceAmount: number | null
  calculatedPriceBySourceCurrency: string | null

  cancellationFee?: number
  profitLoss?: number
  profitMargin: number
  removedLegsCost: number
  tripCosts: number
  optimizationProfit: number
  totalProfit: number

  finalPrice: number
  isFinalPriceDisabled: boolean

  onFinalPriceFocus?: (
    event: FocusEvent<HTMLInputElement>,
  ) => void | Promise<void>

  onFinalPriceBlur?: (
    event: FocusEvent<HTMLInputElement>,
  ) => void | Promise<void>

  onFinalPriceChange?: (
    event: ChangeEvent<HTMLInputElement>,
    value?: number,
  ) => void | Promise<void>
}

const PriceCalculation = ({
  className,
  profitMargin,
  removedLegsCost,
  tripCosts,
  optimizationProfit,
  totalProfit,
  calculatedPriceBySourceAmount,
  calculatedPriceBySourceCurrency,

  finalPrice,
  isFinalPriceDisabled,
  onFinalPriceFocus,
  onFinalPriceChange,
  onFinalPriceBlur,

  profitLoss = 0,
  cancellationFee = 0,
  onCancellationFeeChange,
  isCancellationFeeVisible = true,
  isCancellationFeeDisabled = false,
  isProfitLossVisible = false,
}: PriceCalculationProps) => {
  const { t } = useTranslation()

  return (
    <Box className={className}>
      <PriceItem>
        <PriceLabel>{t('molecules.PriceCalculation.profitMargin')}</PriceLabel>
        <Price
          value={profitMargin}
          type={profitMargin < 0 ? 'loss' : 'profit'}
        />
      </PriceItem>

      <PriceItem>
        <PriceLabel>{t('molecules.PriceCalculation.tripCosts')}</PriceLabel>
        <Price value={tripCosts} type={tripCosts < 0 ? 'loss' : 'default'} />
      </PriceItem>

      <PriceItem>
        <PriceLabel>
          {t('molecules.PriceCalculation.removedLegsCost')}
        </PriceLabel>
        <Price
          value={removedLegsCost}
          type={removedLegsCost < 0 ? 'loss' : 'default'}
        />
      </PriceItem>

      {isProfitLossVisible && (
        <PriceItem>
          <PriceLabel>{t('molecules.PriceCalculation.profitLoss')}</PriceLabel>
          <Price
            value={profitLoss}
            type={profitLoss < 0 ? 'loss' : 'default'}
          />
        </PriceItem>
      )}

      <SummaryPriceItem>
        <Box display="flex" flex="1" flexDirection="column">
          <Box
            display="flex"
            flex="1"
            justifyContent="space-between"
            alignItems="center"
          >
            <SummaryPriceLabel>
              {t('molecules.PriceCalculation.finalPrice')}
            </SummaryPriceLabel>

            <PriceTextField
              inputComponent={TextFieldPriceFormatter}
              disabled={isFinalPriceDisabled}
              value={finalPrice}
              onChange={onFinalPriceChange}
              onFocus={onFinalPriceFocus}
              onBlur={onFinalPriceBlur}
              classes={{
                input: 'CancellationFeeTextField__input',
              }}
            />
          </Box>

          {calculatedPriceBySourceAmount && (
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              fontStyle="italic"
              pt="0.25rem"
            >
              <AdditionalInformation>
                {t('molecules.PriceCalculation.sourceCalculatedPrice', {
                  currency: calculatedPriceBySourceCurrency,
                  price: formatNumber(
                    DEFAULT_PRICE_FORMAT_WITHOUT_CURRENCY,
                    calculatedPriceBySourceAmount,
                  ),
                })}
              </AdditionalInformation>
            </Box>
          )}
        </Box>
      </SummaryPriceItem>

      {isCancellationFeeVisible && (
        <CancellationFeeContainer>
          <PriceLabel>
            {t('molecules.PriceCalculation.cancellationFeeLabel')}
          </PriceLabel>

          <PriceTextField
            inputComponent={TextFieldPriceFormatter}
            disabled={isCancellationFeeDisabled}
            value={cancellationFee}
            onChange={(event) => onCancellationFeeChange(event.target.value)}
            classes={{
              input: 'CancellationFeeTextField__input',
            }}
          />
        </CancellationFeeContainer>
      )}

      <PriceItem>
        <PriceLabel>
          {t('molecules.PriceCalculation.optimizationProfit')}
        </PriceLabel>

        <Price
          value={optimizationProfit}
          type={optimizationProfit < 0 ? 'loss' : 'profit'}
        />
      </PriceItem>

      <SummaryPriceItem>
        <SummaryPriceLabel>
          {t('molecules.PriceCalculation.totalProfit')}
        </SummaryPriceLabel>
        <SummaryProfit
          value={totalProfit}
          type={totalProfit < 0 ? 'loss' : 'profit'}
        />
      </SummaryPriceItem>
    </Box>
  )
}

const AdditionalInformation = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 0.8rem;
`

const PriceTextField = styled(TextField)`
  flex: 1;

  & .CancellationFeeTextField__input {
    text-align: right;
  }
`

const PriceItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CancellationFeeContainer = styled(PriceItem)`
  padding: 0.75rem 0;
`

const SummaryPriceItem = styled(PriceItem)`
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
`

const PriceLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
  max-width: 15rem;
`

const SummaryPriceLabel = styled(PriceLabel)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.grey[700]};
  width: 15rem;
`

const SummaryProfit = styled(Price)`
  font-weight: bold;
`

export default PriceCalculation
