import React, { MouseEventHandler } from 'react'

import TimerIcon from '@material-ui/icons/Timer'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive'
import ExplicitIcon from '@material-ui/icons/Explicit'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'

import { OfferFlags } from '@strafos/common'
import { assertUnreachable } from '@strafos/common'
import NewMessageIcon from '@app/components/atoms/NewMessageIcon/NewMessageIcon'
import { AccessTime } from '@material-ui/icons'

export type FlagIconProps = {
  flag: OfferFlags
  onClick?: MouseEventHandler<SVGSVGElement>
  isReserved?: boolean
}

/**
 * @returns Icon associated with a given offer flag
 */
const FlagIcon = ({ flag, onClick, isReserved, ...props }: FlagIconProps) => {
  if (isReserved) {
    return <AccessTime {...props} />
  }
  switch (flag) {
    case OfferFlags.BeingRecalculated:
      return <TimerIcon {...props} />

    case OfferFlags.ProfitOpportunity:
      return <AttachMoneyIcon {...props} />

    case OfferFlags.ProfitLost:
      return <MoneyOffIcon {...props} />

    case OfferFlags.ProfitReduced:
      return <TrendingDownIcon {...props} />

    case OfferFlags.Conflict:
    case OfferFlags.ToRequote:
      return <PriorityHighIcon {...props} />

    case OfferFlags.AircraftUnavailable:
      return <AirplanemodeInactiveIcon {...props} />

    case OfferFlags.EmptyLeg:
      return <ExplicitIcon {...props} />

    case OfferFlags.NewMessage:
      return <NewMessageIcon {...props} onClick={onClick} />

    default:
      assertUnreachable(flag)
  }
}
export default FlagIcon
