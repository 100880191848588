import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { DateTypes } from '@app/components/atoms/DateTypeSwitch/DateTypeSwitch'
import { CreateRequestFormVariant } from '@app/components/organisms/CreateRequestForm/CreateRequestForm'
import { getCombinedDate } from '@strafos/common'

dayjs.extend(utc)

interface UseCreateRequestFormValidationSchemaOptions {
  variant: CreateRequestFormVariant
}

const testArrivalSameAsDeparture: yup.TestFunction = (value, { parent }) =>
  parent.arrivalAirport.id !== parent.departureAirport.id

const testDateIsBeforeReturnDate: yup.TestFunction = (value, { parent }) => {
  const isInvalidDate = (date: Date) => Number.isNaN(date.getTime())

  if (
    !parent.date ||
    !parent.returnDate ||
    isInvalidDate(parent.time) ||
    isInvalidDate(parent.returnTime)
  ) {
    return true
  }

  const dateTime = getCombinedDate(new Date(parent.date), new Date(parent.time))

  const returnDateTime = getCombinedDate(
    new Date(parent.returnDate),
    new Date(parent.returnTime),
  )

  return dayjs.utc(dateTime).isBefore(dayjs.utc(returnDateTime))
}

const useCreateRequestFormValidationSchema = ({
  variant,
}: UseCreateRequestFormValidationSchemaOptions) => {
  const { t } = useTranslation()

  const oneWayTripLegSchema = yup
    .object()
    .shape({
      date: yup
        .date()
        .required(t('organisms.CreateRequestForm.dateRequired'))
        .typeError(t('organisms.CreateRequestForm.dateRequired')),
      time: yup
        .date()
        .required(t('organisms.CreateRequestForm.timeRequired'))
        .typeError(t('organisms.CreateRequestForm.timeRequired')),
      dateType: yup
        .string()
        .oneOf(
          Object.values(DateTypes),
          t('organisms.CreateRequestForm.dateTypeRequired'),
        )
        .required(t('organisms.CreateRequestForm.dateTypeRequired')),
      departureAirport: yup
        .object()
        .typeError(t('organisms.CreateRequestForm.departureAirportRequired'))
        .required(t('organisms.CreateRequestForm.departureAirportRequired')),
      arrivalAirport: yup
        .object()
        .typeError(t('organisms.CreateRequestForm.arrivalAirportRequired'))
        .required(t('organisms.CreateRequestForm.arrivalAirportRequired'))
        .test(
          'arrivalSameAsDeparture',
          t('organisms.CreateRequestForm.arrivalSameAsDeparture'),
          testArrivalSameAsDeparture,
        ),
      passengerCount: yup
        .number()
        .min(0, t('organisms.CreateRequestForm.passengerCountPositiveNumber'))
        .typeError(t('organisms.CreateRequestForm.passengerCountRequired')),
    })
    .required()

  if (variant === CreateRequestFormVariant.OneWayTrip) {
    return yup.object().shape({
      legs: yup.array().of(oneWayTripLegSchema).required(),
    })
  }

  return yup.object().shape({
    legs: yup
      .array()
      .of(
        oneWayTripLegSchema.shape({
          returnDate: yup
            .date()
            .required(t('organisms.CreateRequestForm.returnDateRequired'))
            .typeError(t('organisms.CreateRequestForm.returnDateRequired'))
            .test(
              'dateIsBeforeReturnDate',
              t('organisms.CreateRequestForm.dateBeforeReturnDate'),
              testDateIsBeforeReturnDate,
            ),
          returnTime: yup
            .date()
            .required(t('organisms.CreateRequestForm.returnTimeRequired'))
            .typeError(t('organisms.CreateRequestForm.returnTimeRequired'))
            .test(
              'dateIsBeforeReturnDate',
              t('organisms.CreateRequestForm.dateBeforeReturnDate'),
              testDateIsBeforeReturnDate,
            ),
        }),
      )
      .required(),
  })
}

export default useCreateRequestFormValidationSchema
