import { CellProps } from "react-table";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { OfferFlags, PartialRequestDto } from "@strafos/common";
import React, { useMemo } from "react";
import {
  StyledFlagIcon,
  StyledFlagIconWOColor,
  StyledTooltip,
} from "@app/components/molecules/RequestsGridView/Cells";
import { useDateAndTimeFormats } from "@app/hooks/useDateAndTimeFormats";
import dayjs from "dayjs";

export const FlagsCell = ({ cell }: CellProps<PartialRequestDto, unknown>) => {
  const { t } = useTranslation();
  const { dateTimeFormat } = useDateAndTimeFormats();

  const reservedTime = useMemo(() => {
    const reservedOffer = cell.row.original.offers.find((o) => !!o.reserved);
    return reservedOffer?.reserved;
  }, [cell]);

  return (
    <Box display="flex" pl={1} pt={1}>
      <StyledTooltip
        title={t("pages.requests.being_recalculated_flag_tooltip") as string}
        $isActive={cell.row.original.flags.includes(
          OfferFlags.BeingRecalculated,
        )}
      >
        <div>
          <StyledFlagIcon flag={OfferFlags.BeingRecalculated} />
        </div>
      </StyledTooltip>

      <StyledTooltip
        title={t("pages.requests.profit_opportunity_flag_tooltip") as string}
        $isActive={cell.row.original.flags.includes(
          OfferFlags.ProfitOpportunity,
        )}
      >
        <div>
          <StyledFlagIcon flag={OfferFlags.ProfitOpportunity} />
        </div>
      </StyledTooltip>

      <StyledTooltip
        title={t("pages.requests.profit_lost_flag_tooltip") as string}
        $isActive={cell.row.original.flags.includes(OfferFlags.ProfitLost)}
      >
        <div>
          <StyledFlagIcon flag={OfferFlags.ProfitLost} />
        </div>
      </StyledTooltip>

      <StyledTooltip
        title={t("pages.requests.profit_reduced_flag_tooltip") as string}
        $isActive={cell.row.original.flags.includes(OfferFlags.ProfitReduced)}
      >
        <div>
          <StyledFlagIcon flag={OfferFlags.ProfitReduced} />
        </div>
      </StyledTooltip>

      <StyledTooltip
        title={t("pages.requests.conflict_flag_tooltip") as string}
        $isActive={
          cell.row.original.flags.includes(OfferFlags.Conflict) ||
          cell.row.original.flags.includes(OfferFlags.ToRequote)
        }
      >
        <div>
          <StyledFlagIcon flag={OfferFlags.Conflict} />
        </div>
      </StyledTooltip>

      <StyledTooltip
        title={t("pages.requests.aircraft_unavailable_flag_tooltip") as string}
        $isActive={cell.row.original.flags.includes(
          OfferFlags.AircraftUnavailable,
        )}
      >
        <div>
          <StyledFlagIcon flag={OfferFlags.AircraftUnavailable} />
        </div>
      </StyledTooltip>

      <StyledTooltip
        title={t("pages.requests.empty_leg_flag_tooltip") as string}
        $isActive={cell.row.original.flags.includes(OfferFlags.EmptyLeg)}
      >
        <div>
          <StyledFlagIcon flag={OfferFlags.EmptyLeg} />
        </div>
      </StyledTooltip>

      <StyledTooltip
        title={t("enums.OfferFlags.newMessages") as string}
        $isActive={cell.row.original.unread_messages_count > 0}
      >
        <div>
          <StyledFlagIconWOColor flag={OfferFlags.NewMessage} />
        </div>
      </StyledTooltip>

      <StyledTooltip
        title={
          (t("pages.requests.reserved_flag_tooltip") as string) +
          (reservedTime ? dayjs(reservedTime).format(dateTimeFormat) : "")
        }
        $isActive={!!reservedTime}
      >
        <div>
          <StyledFlagIcon
            flag={OfferFlags.BeingRecalculated}
            isReserved={true}
          />
        </div>
      </StyledTooltip>
    </Box>
  );
};
