import { useTranslation } from 'react-i18next'

import { LegTypes } from '@strafos/common'
import { assertUnreachable } from '@strafos/common'

export const useGetLegTypeText = () => {
  const { t } = useTranslation()

  return (type: LegTypes) => {
    switch (type) {
      case LegTypes.Empty:
        return t('hooks.useGetLegTypeText.emptyLeg')

      case LegTypes.Occupied:
        return t('hooks.useGetLegTypeText.occupiedLeg')

      case LegTypes.Ferry:
        return t('hooks.useGetLegTypeText.ferryLeg')

      case LegTypes.Removed:
        return t('hooks.useGetLegTypeText.removedLeg')

      case LegTypes.Outage:
        return t('hooks.useGetLegTypeText.outageLeg')

      default:
        assertUnreachable(type)
    }
  }
}
