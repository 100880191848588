import { PartialRequestDto } from '@strafos/common'
import { useTranslation } from 'react-i18next'
import { CellProps } from 'react-table'
import Box from '@material-ui/core/Box'
import { StyledTooltip } from '@app/components/molecules/RequestsGridView/Cells'

import ErrorIcon from '@material-ui/icons/Error'
import React from 'react'

import { icons } from '@app/components/pages/Fleet/Warnings/types'

export const WarningCell = ({
  cell,
}: CellProps<PartialRequestDto, unknown>) => {
  const { t } = useTranslation()

  return (
    <Box display="flex" pl={1} pt={1}>
      <StyledTooltip
        title={
          <div style={{ fontSize: '16px' }}>
            {t('pages.Request.cabotage.tooltip') as string}
          </div>
        }
        $isActive={cell.row.original.hasCabotage}
        disablePortal={false}
      >
        <ErrorIcon />
      </StyledTooltip>

      {cell.row.original.warnings
        ? cell.row.original.warnings.map((curWarning) => (
            <StyledTooltip
              key={`warning-${curWarning.id}`}
              title={<div style={{ fontSize: '16px' }}>{curWarning.note}</div>}
              $isActive={true}
              disablePortal={false}
            >
              <div style={{ color: curWarning.color }}>
                {
                  icons.find((icon) => icon.value === curWarning.icon)
                    ?.component
                }
              </div>
            </StyledTooltip>
          ))
        : null}
    </Box>
  )
}
