import React from "react";
import { Control, UseFormTrigger } from "react-hook-form";

import { useControllerWithValidationTrigger } from "@app/hooks/useControllerWithValidationTrigger";

import LegFieldGroup, {
  LegFieldGroupProps,
} from "@app/components/organisms/LegEditorForm/LegFieldGroup/LegFieldGroup";

import {
  LegEditorFormData,
  LegFormData,
} from "@app/components/organisms/LegEditorForm/LegEditorForm";
import { OfferDto, CustomRouteDetailDto } from "@strafos/common";

interface ControlledLegFieldGroupProps
  extends Omit<LegFieldGroupProps, "fields"> {
  index: number;
  isDirty: boolean;
  control: Control<LegEditorFormData>;
  trigger: UseFormTrigger<LegEditorFormData>;
  leg: LegFormData;
  offer: OfferDto;
  onlyDateTimeChange: boolean;
  handleSplitLegByTechStop: (index: number, firstLeg: LegFormData) => void;
  offerRelatedCustomRoutes: CustomRouteDetailDto[] | null;
}

const ControlledLegFieldGroup = ({
  control,
  index,
  trigger,
  leg,
  offer,
  offerRelatedCustomRoutes,
  onlyDateTimeChange = false,
  ...rest
}: ControlledLegFieldGroupProps): JSX.Element => {
  const { field: type } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.type`,
    trigger: trigger,
    hasError: !!rest.errors?.type,
  });

  const { field: passengerCount } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.passengerCount`,
    trigger: trigger,
    hasError: !!rest.errors?.passengerCount,
  });

  const { field: departureDate } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.departureDate`,
    trigger: trigger,
    hasError: !!rest.errors?.departureDate,
  });

  const { field: departureTime } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.departureTime`,
    trigger: trigger,
    hasError: !!rest.errors?.departureTime,
  });

  const { field: departureAirport } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.departureAirport`,
    trigger: trigger,
    hasError: !!rest.errors?.departureAirport,
  });

  const { field: arrivalDate } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.arrivalDate`,
    trigger: trigger,
    hasError: !!rest.errors?.arrivalDate,
  });

  const { field: arrivalTime } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.arrivalTime`,
    trigger: trigger,
    hasError: !!rest.errors?.arrivalTime,
  });

  const { field: arrivalAirport } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.arrivalAirport`,
    trigger: trigger,
    hasError: !!rest.errors?.arrivalAirport,
  });

  const { field: aircraft } = useControllerWithValidationTrigger({
    control,
    name: `legs.${index}.aircraft`,
    trigger: trigger,
    hasError: !!rest.errors?.aircraft,
  });

  return (
    <LegFieldGroup
      groupType={"calculated"}
      leg={leg}
      index={index}
      offer={offer}
      aircraft={aircraft}
      onlyDateTimeChange={onlyDateTimeChange}
      control={control}
      offerRelatedCustomRoutes={offerRelatedCustomRoutes}
      fields={{
        type,
        departureDate,
        departureTime,
        departureAirport,
        arrivalDate,
        arrivalTime,
        arrivalAirport,
        passengerCount,
        aircraft,
      }}
      {...rest}
    />
  );
};

export default ControlledLegFieldGroup;
